import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {CommonModule} from '@angular/common';
import {SyncProductsLimitReachedModalComponent} from './sync-products-limit-reached-modal.component';
import {NbProgressBarModule} from '@nebular/theme';

@NgModule({
  declarations: [SyncProductsLimitReachedModalComponent],
  imports: [
    MatDialogModule,
    CommonModule,
    NbProgressBarModule,
  ],
})

export class SyncProductsLimitReachedModalModule {}

import {Component, Input, OnChanges} from '@angular/core';
import {ProductsService} from 'app/common/services/product.service';
import {SessionService} from 'app/common/services/session.service';

const greenColor = '#087f23';
const redColor = '#ff2000';
const materialArrowUp = "arrow_drop_up";
const materialDownArrow = "arrow_drop_down";
@Component({
  selector: 'pm-trend-arrow',
  templateUrl: './trend-arrow.component.html',
  styleUrls: ['./trend-arrow.component.scss'],
})
export class TrendArrowComponent implements OnChanges {
  @Input() value: number;
  @Input() hasCompetition: boolean = true;
  @Input() originalPrice = null;
  @Input() isRank: boolean = false;
  @Input() isNewDesign: boolean = false;

  arrowDrop: string;
  differenceAmount: number;
  currencySymbol: string;

  constructor(private productsService: ProductsService,
              private sessionService: SessionService,
  ) { }

  ngOnChanges(): void {
    if (this.isNewDesign) {
      this.currencySymbol = this.sessionService.currencySymbol;
      this.differenceAmount = Math.abs(Number(this.value.toFixed(1)));
      this.checkArrowDrop();
    }
  }

  get currentColor(): string {
    return this.checkArrowDrop();
  }

  get differenceValue(): string {
    if (isNaN(this.value))
      return '';

    return this.isRank ? Math.abs(Number(this.value.toFixed(1))).toString() : (this.hasCompetition ? (this.isProductsPriceView ? this.sessionService.currencySymbol : '') + Math.abs(Number(this.value.toFixed(1))) + (this.isProductsPriceView ? '' : '%') : 'No Competition Found');
  }

  get isProductsPriceView(): boolean {
    return this.productsService.isProductsPriceView;
  }

  private checkArrowDrop(): string {
    if (this.isRank) {
      if (this.originalPrice == null) {
        this.arrowDrop = materialArrowUp;
        return greenColor;
      }

      if (this.originalPrice > this.value) {
        this.arrowDrop = materialArrowUp;
        return greenColor;
      } else {
        this.arrowDrop = materialDownArrow;
        return redColor;
      }
    } else {
      if (this.isProductsPriceView && this.originalPrice > 0) {
        if (this.originalPrice > this.value) {
          this.arrowDrop = materialArrowUp;
          return redColor;
        } else {
          this.arrowDrop = materialDownArrow;
          return greenColor;
        }
      } else {
        if (this.value === 0) {
          this.arrowDrop = materialDownArrow;
          return greenColor;
        } else if (this.value > 0) {
          this.arrowDrop = materialArrowUp;
          return redColor;
        } else {
          this.arrowDrop = materialDownArrow;
          return greenColor;
        }
      }
    }
  }
}

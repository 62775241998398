import {NgModule} from '@angular/core';
import {ItemsSkeletonComponent} from './items-skeleton.component';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [ItemsSkeletonComponent],
  imports: [
    CommonModule,
  ],
  exports: [ItemsSkeletonComponent],
})

export class ItemsSkeletonModule {
}

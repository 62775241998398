import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from 'environments/environment';
import { MemoryStorage } from './MemoryStorage';

@Injectable({
  providedIn: 'root'
})
export class GwLocalStorageService {
  private _localStorage: any = window.localStorage;
  constructor() {
    if (!this._isSupported()) {
      this._localStorage = new MemoryStorage();
    }
  }

  get<TValue extends string>(name: StorageDict, defaultValue: TValue = null): TValue {
    if (!name) { return defaultValue; }
    let result = this._localStorage.getItem(name) as TValue;
    return result;
    // return (this._localStorage.getItem(name) as TValue) || defaultValue;
  }

  set<TValue extends string>(name: StorageDict, value: TValue): boolean {
    if (!name) { return false; }
    this._localStorage.setItem(name, value);
    return true;
  }

  setIfNone(name: StorageDict, value: string): boolean {
    if (this.get(name) !== null) { return; }
    this.set(name, value);
  }

  remove(name: StorageDict): string {
    if (!name) { return null; }
    const itemToRemove = this.get(name);
    this._localStorage.removeItem(name);
    return itemToRemove;
  }

  clear(): void {
    this._localStorage.clear();
  }

  // setToken(token, expirationDate = null) {
  //   if (token === null) {
  //     return false;
  //   }
  //   this.set(StorageDict.authToken, 'bearer ' + token);
  //   if (expirationDate) {
  //     this.set(StorageDict.authTokenExpires, expirationDate);
  //   }
  //   return true;
  // }
  /**
   * Check if local storage supported
   */

  private _isSupported(): boolean {
    try {
      // try to save in storage to check if it's working. (Safari private mode does not support local storage)
      this._localStorage.setItem('__12345nh&*%$%', 'true');
      this._localStorage.removeItem('__12345nh&*%$%');
      return true;
    } catch (e) {
      return false;
    }
  }
}



@Injectable({
  providedIn: 'root'
})
export class EncryptedLocalStorageService {
  private _localStorage: any = window.localStorage;
  
  private staticKey = environment.secretPhrase;  

  constructor() {
    if (!this._isSupported()) {
      this._localStorage = new MemoryStorage();
    }
  }

  // Set Access Token (Encrypt before storing)
  setAccessToken(token: string): boolean {
    if (!token) {
      return false;
    }
    const encryptedToken = this.simpleEncrypt(token, this.staticKey);
    this._localStorage.setItem(StorageDict.accessToken, encryptedToken);
    return true;
  }

  // Get Access Token (Decrypt when retrieving, ignore if not encrypted)
  getAccessToken(): string | null {
    const storedToken = this._localStorage.getItem(StorageDict.accessToken);
    if (!storedToken || !this.isBase64(storedToken)) {
      // If the token is not present or not Base64-encoded, consider it invalid or not encrypted
      console.warn('Invalid or unencrypted token detected, ignoring it.');
      return null;
    }
    return this.simpleDecrypt(storedToken, this.staticKey);
  }

  // Remove Access Token from storage
  removeAccessToken(): void {
    this._localStorage.removeItem(StorageDict.accessToken);
  }

  // Simple XOR encryption
  private simpleEncrypt(data: string, key: string): string {
    let result = '';
    for (let i = 0; i < data.length; i++) {
      result += String.fromCharCode(data.charCodeAt(i) ^ key.charCodeAt(i % key.length));
    }
    return btoa(result);  // Base64 encode the result to make it safe for storage
  }

  // Simple XOR decryption
  private simpleDecrypt(data: string, key: string): string {
    const decodedData = atob(data);  // Base64 decode the stored data
    let result = '';
    for (let i = 0; i < decodedData.length; i++) {
      result += String.fromCharCode(decodedData.charCodeAt(i) ^ key.charCodeAt(i % key.length));
    }
    return result;
  }

  // Check if the token is Base64-encoded (Basic validation for encryption)
  private isBase64(str: string): boolean {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }

  // Check if local storage is supported
  private _isSupported(): boolean {
    try {
      this._localStorage.setItem('__testKey', 'true');
      this._localStorage.removeItem('__testKey');
      return true;
    } catch (e) {
      return false;
    }
  }
}



export enum StorageDict {
  abnormalFilters = "abnormalFilters",
  shouldScheduleCall = "shouldScheduleCall",
  overrideTenant = "overrideTenant",
  accessToken = "accessToken",
  percentagePriceConverterValue = "percentagePriceConverterValue",
  productColumns = "productColumns",
  productInsightsColumns = "productInsightsColumns",
  priceSuggestionColumns = "priceSuggestionColumns",
  priceSuggestionInsightsColumns = "priceSuggestionInsightsColumns",  
  hasSeenAllProductsSyncedModal = 'hasSeenAllProductsSyncedModal',
}

import {Component, Input} from '@angular/core';
import {SessionService} from '../../../common/services/session.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout [ngClass]="shouldShowProductSyncingNavbar && isNavbarShown ? 'with-top-navbar' : 'no-top-navbar'">
      <nb-layout-header fixed>
        <ngx-header [isProductSyncingNavbar]="shouldShowProductSyncingNavbar"></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start
                  [class.with-navbar]="shouldShowProductSyncingNavbar && isNavbarShown">
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column [class.with-navbar]="shouldShowProductSyncingNavbar && isNavbarShown">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  @Input() shouldShowProductSyncingNavbar: boolean = false;

  isNavbarShown: boolean = false;

  constructor(private sessionService: SessionService,
  ) {
    this.sessionService.isProductsSyncNavbarShown$.subscribe((isNavbarShown: boolean) => {
      this.isNavbarShown = isNavbarShown;
    });
  }
}

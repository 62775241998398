import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByKey',
})
export class FilterByKeyPipe implements PipeTransform {
  transform(
    dataList: any[],
    keyToFilter: string,
    ordering: 'desc' | 'asc' = 'asc',
    itemsToShow?: number,
  ): any[] {
    if (!Array.isArray(dataList) || !keyToFilter) {
      return dataList;
    }

    let filteredArray = dataList.filter(item => item[keyToFilter] !== undefined);

    filteredArray.sort((a, b) => {
      const aValue = a[keyToFilter];
      const bValue = b[keyToFilter];

      if (aValue < bValue) return ordering === 'asc' ? -1 : 1;
      if (aValue > bValue) return ordering === 'asc' ? 1 : -1;
      return 0;
    });

    if (itemsToShow) {
      filteredArray = filteredArray.slice(0, itemsToShow);
    }

    return filteredArray;
  }
}

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LandscapeType } from 'app/common/enums/LandscapeType.enum';
import { PositionOperatorType } from 'app/common/enums/PositionOperatorType.enum';
import { PricingEntityTypes } from 'app/common/enums/pricing-entity-type.enum';
import { PricingMarginType, } from 'app/common/enums/pricing-margin-type.enum';
import { PricingPositionType } from 'app/common/enums/PricingPositionType.enum';
import { IPricingCondition } from 'app/common/interfaces/conditions/pricing/IPricing-Condition';
import { IPricingPositionComponent } from 'app/common/interfaces/conditions/pricing/IPricing-Position-Component';
import { IPricingTargetComponent } from 'app/common/interfaces/conditions/pricing/IPricing-Target-Component';
import { ICategory } from 'app/common/interfaces/ICategory';
import { ICollection } from 'app/common/interfaces/ICollection';
import { IOption } from 'app/common/interfaces/IOption';
import { ISearchableProduct } from 'app/common/interfaces/ISearchableProduct';
import { ProductsService } from 'app/common/services/product.service';
import { SessionService } from 'app/common/services/session.service';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, filter, map, takeUntil, tap } from 'rxjs/operators';
import * as $ from "jquery";
import { IRule } from 'app/common/interfaces/IRule';
import { CompetitorsService } from 'app/common/services/competitors.service';
import { ICompetitor } from 'app/common/interfaces/ICompetitor';
import { RulesService } from 'app/common/services/rules.service';
import { MatOptionSelectionChange } from '@angular/material/core';
import { PricingExclusionsComponent } from '../pricing-exclusions/pricing-exclusions.component';

const InterChangeSentencePlaceholder = " {{InterSentence}} ";
const SortTypeASC: string = "asc";
const SortTypeDESC: string = "desc";

@Component({
  selector: 'pricing-condition',
  templateUrl: './pricing-condition.component.html',
  styleUrls: ['./pricing-condition.component.scss']
})
export class PricingConditionComponent implements OnInit, OnDestroy {


  @Input() openingSentence;
  @Input() interchangeSentence;

  ThanThe = " than the ";
  ToThe = " to the "
  OfMy = " of all "

  conditionForm = new FormGroup({
  });

  @Input() ruleId: number;
  @Input() disabledProductPricingCondition: boolean = false;
  @Input() isSingleProductPricingStrategy: boolean;
  @Input() singleProducts: Array<string> = [];
  @Input() isPricingDiscount: boolean = false;

  singleProductEditRule: IRule = null;
  isProductsGroupEntityType: boolean = false;

  marginTypes: PricingMarginType[] = [PricingMarginType.Currency, PricingMarginType.Percentage];
  operatorTypes: PositionOperatorType[] = [PositionOperatorType.Higher, PositionOperatorType.Lower, PositionOperatorType.Equal];
  positionTypes: PricingPositionType[] = [PricingPositionType.Average, PricingPositionType.Cheapest, PricingPositionType.Highest];
  entityTypes: PricingEntityTypes[] = [];
  landscapes: LandscapeType[] = [LandscapeType.Competitors];
  products: ISearchableProduct[];
  categories: ICategory[];
  brands: string[];
  collections: ICollection[];
  competitors: ICompetitor[];
  entities: IOption[];
  competitorEntities: IOption[];
  productsSubscription: Subscription;
  categoriesSubscription: Subscription;
  brandsSubscription: Subscription;
  collectionsSubscription: Subscription;
  competitorSubscription: Subscription;
  entitiesFilterSubscription: Subscription;

  entityPlaceholderLabel: string;
  entityNoEntriesFoundLabel: string;

  competitorPlaceholderLabel: string;
  competitorNoEntriesFoundLabel: string;

  rulesRelatedProducts: ISearchableProduct[] = [];

  searchTermForProducts: string = "";
  selectedProducts: ISearchableProduct[] = [];

  currentCondition: IPricingCondition = null;

  @Input() isShowPricingExclusions: boolean = false;

  @ViewChild("entitySearchInput") entitySearchInput: ElementRef;
  @ViewChild(PricingExclusionsComponent) pricingExclusionsComponent: PricingExclusionsComponent;

  /** list of entities filtered by search keyword */
  public filteredEntitiesMulti: ReplaySubject<IOption[]> = new ReplaySubject<IOption[]>(1);

  /** list of competitors filtered by search keyword */
  public filteredCompetitorEntitiesMulti: ReplaySubject<IOption[]> = new ReplaySubject<IOption[]>(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  searching: boolean;
  currentSelectedCompetitors: string[];

  constructor(private sessionService: SessionService,
    private productService: ProductsService,
    private competitorsService: CompetitorsService,
    private rulesService: RulesService) {
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.entityTypes = (this.isSingleProductPricingStrategy ?
      [PricingEntityTypes.AllProducts, PricingEntityTypes.Products, PricingEntityTypes.Categories, PricingEntityTypes.Brands, PricingEntityTypes.Collections] :
      [PricingEntityTypes.AllProducts, PricingEntityTypes.Categories, PricingEntityTypes.Brands, PricingEntityTypes.Collections, PricingEntityTypes.NoCompetitionProducts]
    );
    this.initProducts();
    this.initCategories();
    this.initBrands();
    this.initCollections();
    this.initCompetitors();
    this.resetConditionForm();
  }

  get entityType(): PricingEntityTypes {
    return this.conditionForm?.controls?.entityType?.value;
  }
  get pricingCondition(): IPricingCondition {
    return this.extractCondition();
  }

  get isValidConditionForm(): boolean {
    return this.conditionForm?.valid;
  }

  get conditionType(): PricingEntityTypes {
    return this.conditionForm.controls?.entityType?.value;
  }

  get entityIds(): string[] {
    return this.conditionForm.controls?.entityIds?.value;
  }

  set entityIds(entityIds: string[]) {
    this.conditionForm.controls?.entityIds?.setValue(entityIds);
  }

  get competitorNames(): string[] {
    return this.conditionForm.controls?.competitorNames?.value;
  }

  set competitorNames(competitorNames: string[]) {
    this.conditionForm.controls?.competitorNames?.setValue(competitorNames);
  }

  get isFormChanges(): boolean {
    return !this.conditionForm.pristine
  }

  get isShowMarginView(): boolean {
    return !(this.conditionForm?.controls?.positionOperatorType.value === PositionOperatorType.Equal);
  }

  get isNoCompetitionProducts(): boolean {
    return this.entityType == PricingEntityTypes.NoCompetitionProducts;
  }

  getEntityName(entityId: string): string {
    if (entityId) {
      if (this.entityType == PricingEntityTypes.Products) {
        let product = this.rulesRelatedProducts.find(x => x.id == entityId);
        return product?.title;
      }
      else {
        return this.productService.getEntityIdName(this.entityType, entityId);
      }
    }
  }

  getCompetitorName(competitorName: string): string {
    if (competitorName === "My Competitors")
      return competitorName;
    else
      return this.competitorsService.competitors?.find((competitor: ICompetitor) => competitor.name === competitorName)?.name;
  }

  getMarginView(pricingMarginType: PricingMarginType): string {
    return this.sessionService.getMarginView(pricingMarginType)
  }

  getPositionOperator(positionOperator: PositionOperatorType): string {
    return PositionOperatorType[positionOperator];
  }

  get positionOperatorTypeEnum(): typeof PositionOperatorType {
    return PositionOperatorType;
  }

  getPositionTypeView(positionType: PricingPositionType): string {
    return PricingPositionType[positionType];
  }

  getLandscapesView(landscape: LandscapeType): string {
    return LandscapeType[landscape];
  }

  getEntityTypes(entityType: PricingEntityTypes): string {
    if (entityType == PricingEntityTypes.AllProducts) {
      return "All Products";
    }
    else if (entityType == PricingEntityTypes.NoCompetitionProducts) {
      return "Competition Free Products";
    }

    return PricingEntityTypes[entityType];
  }

  getToOrTheSentence(): string {
    return this.isShowMarginView ? this.ThanThe : this.ToThe
  }

  initializeConditionForm(condition: IPricingCondition, shouldResetEntities?: boolean) {
    this.currentCondition = condition;
    this.conditionForm = this.buildConditionForm(condition);
    this.updateEntityIds(shouldResetEntities = false);
    this.loadCompetitorNames(shouldResetEntities = false);
    this.pricingExclusionsComponent?.initializeExcludeProducts(condition);
    setTimeout(() => {
      this.OfMy = this.conditionForm.controls?.competitorNames?.value?.length == 1 && this.conditionForm.controls?.competitorNames?.value[0] === "My Competitors" ? " of all " : " of ";
    });
  }

  updateEntityIds(shouldResetIds?: boolean): void {

    if (!this.entitiesFilterSubscription?.closed)
      this.entitiesFilterSubscription?.unsubscribe();

    if (shouldResetIds) {
      this.entityIds = [];
      this.entities = [];
      this.conditionForm.controls?.entityIds?.reset();
    }

    switch (this.entityType) {
      case PricingEntityTypes.Products:
        let filteredProducts = this.products && this.products.length > 0 ? this.products.slice() : this.products;
        if (filteredProducts && filteredProducts.length > 0 && this.isSingleProductPricingStrategy && this.singleProducts && this.singleProducts.length > 0)
          filteredProducts = this.filterSingleProducts(filteredProducts);
        this.entities = filteredProducts?.map((product: ISearchableProduct) => {
          return <IOption>{ value: product.id, title: product.title }
        });
        this.rulesRelatedProducts = this.products?.slice();
        this.entityPlaceholderLabel = "Find product...";
        this.entityNoEntriesFoundLabel = "Enter search term";
        this.isProductsGroupEntityType = false;
        this.addEntitiesRequiredValidation();
        break;
      case PricingEntityTypes.Categories:
        this.entities = this.categories?.map((category: ICategory) => {
          return <IOption>{ value: category.id, title: category.name }
        });
        this.entityPlaceholderLabel = "Find category...";
        this.entityNoEntriesFoundLabel = "No matching category found!";
        this.isProductsGroupEntityType = false;
        this.addEntitiesRequiredValidation();
        break;
      case PricingEntityTypes.Brands:
        this.entities = this.brands?.map((brand: string) => {
          return <IOption>{ value: brand, title: brand }
        });
        this.entityPlaceholderLabel = "Find brand...";
        this.entityNoEntriesFoundLabel = "No matching brand found!";
        this.isProductsGroupEntityType = false;
        this.addEntitiesRequiredValidation();
        break;
      case PricingEntityTypes.Collections:
        this.entities = this.collections?.map((collection: ICollection) => {
          return <IOption>{ value: collection.id, title: collection.name }
        });
        this.entityPlaceholderLabel = "Find collection...";
        this.entityNoEntriesFoundLabel = "No matching collection found!";
        this.isProductsGroupEntityType = false;
        this.addEntitiesRequiredValidation();
        break;
      case PricingEntityTypes.AllProducts:
        this.entities = [];
        this.entityPlaceholderLabel = "";
        this.entityNoEntriesFoundLabel = "";
        this.isProductsGroupEntityType = true;
        this.removeEntitiesRequiredValidation();
        break;
      case PricingEntityTypes.NoCompetitionProducts:
        this.entities = [];
        this.entityPlaceholderLabel = "";
        this.entityNoEntriesFoundLabel = "";
        this.isProductsGroupEntityType = true;
        this.removeEntitiesRequiredValidation();
        break;
      default:
        this.entities = [];
        this.entityPlaceholderLabel = "";
        this.entityNoEntriesFoundLabel = "";
        this.isProductsGroupEntityType = false;
        this.addEntitiesRequiredValidation();
        break;
    }

    // load the initial entity list
    if (this.entityType == PricingEntityTypes.Products && this.entities && this.entities.length > 0) {
      this.entities = this.productService.sort(SortTypeASC, "title", "string", [...this.entities]);
      let relateProductsEntities = this.rulesRelatedProducts?.slice();
      let selectedEntities = this.entityIds?.slice();
      if (selectedEntities && selectedEntities.length > 0) {
        for (let item of selectedEntities) {
          let entity = relateProductsEntities?.filter(x => x.id == item)[0];
          if (entity)
            this.selectedProducts.push(entity);
        }
      }
    }
    this.filteredEntitiesMulti.next(this.entities?.slice());

    // listen for search field value changes
    if (this.entityType == PricingEntityTypes.Products) {
      this.entitiesFilterSubscription = this.conditionForm.controls?.entityIdsMultiFilterCtrl?.valueChanges
        .pipe(
          filter(search => !!search),
          tap(() => this.searching = true),
          takeUntil(this._onDestroy),
          debounceTime(500),
          map(async search => {
            if (this.searchTermForProducts == search)
              return { isMoveForward: false, filteredEntities: [], searchTerm: search };
            this.searchTermForProducts = search;
            let productsResponse = await this.productService.filterProductsFromApi({
              filters: {
                searchTerm: search
              }
            });
            // simulate server fetching and filtering data
            return { isMoveForward: true, filteredEntities: productsResponse.products, searchTerm: search };
          }),
          delay(500),
          takeUntil(this._onDestroy)
        )
        .subscribe(async response => {
          this.searching = false;

          let results = await response;
          if (!results.isMoveForward)
            return;

          this.filteredEntitiesMulti.next([]);
          let data = results.filteredEntities;
          if (data && data.length > 0)
            this.rulesRelatedProducts = data?.slice();

          let selectedEntities = this.entityIds?.slice();
          let filteredEntitiesDataSource: IOption[] = [];
          if (selectedEntities && selectedEntities.length > 0) {
            for (let item of selectedEntities) {
              let entity = this.selectedProducts.filter(x => x.id == item)[0];
              if (entity) {
                let duplicateItem = this.rulesRelatedProducts.filter(x => x.id == item)[0];
                if (!duplicateItem)
                  this.rulesRelatedProducts.unshift(entity);
                filteredEntitiesDataSource.push({ title: entity.title, value: entity.id });
              }
            }
          }
          let searchResults = data.map(x => { return { title: x.title, value: x.id } });
          if (searchResults && searchResults.length > 0) {
            for (let item of searchResults) {
              let duplicateItem = filteredEntitiesDataSource.filter(x => x.value == item.value)[0];
              if (item && !duplicateItem)
                filteredEntitiesDataSource.push(item);
            }
          }
          if ((!filteredEntitiesDataSource || filteredEntitiesDataSource.length <= 0) && results.searchTerm)
            this.entityNoEntriesFoundLabel = "Not found, change search term";
          else
            this.entityNoEntriesFoundLabel = "Enter search term";
          if (filteredEntitiesDataSource && filteredEntitiesDataSource.length > 0) {
            filteredEntitiesDataSource = this.productService.sort(SortTypeASC, "title", "string", [...filteredEntitiesDataSource]);
            if (filteredEntitiesDataSource && filteredEntitiesDataSource.length > 0 && this.isSingleProductPricingStrategy && this.singleProducts && this.singleProducts.length > 0)
              filteredEntitiesDataSource = this.filterSingleProductsForFilter(filteredEntitiesDataSource);
          }
          this.filteredEntitiesMulti.next(filteredEntitiesDataSource?.slice());
        },
          error => {
            // no errors in our simulated example
            this.searching = false;
            // handle error...
          });
    } else {
      this.entitiesFilterSubscription = this.conditionForm.controls?.entityIdsMultiFilterCtrl?.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterEntitiesMulti();
        });
    }
  }

  loadCompetitorNames(shouldResetEntities?: boolean): void {
    if (shouldResetEntities) {
      this.competitorNames = [];
      this.competitorEntities = [];
      this.conditionForm.controls?.competitorNames?.reset();
    }

    this.competitorEntities = this.competitors?.map((competitor: ICompetitor) => {
      return <IOption>{ value: competitor.name, title: competitor.name }
    });
    this.competitorEntities.splice(0, 0, <IOption>{ value: "My Competitors", title: "My Competitors" });
    this.competitorPlaceholderLabel = "Find competitor...";
    this.competitorNoEntriesFoundLabel = "No matching competitor found!";

    // load the initial competitor list
    this.filteredCompetitorEntitiesMulti.next(this.competitorEntities?.slice());

    // listen for search field value changes
    this.conditionForm.controls?.competitorNamesMultiFilterCtrl?.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCompetitorEntitiesMulti();
      });
  }

  resetConditionForm(): void {
    this.initializeConditionForm(null, true);
  }

  private buildConditionForm(condition: IPricingCondition): FormGroup {
    return new FormGroup({
      margin: new FormControl(condition ? condition.positionComponent?.margin : 5, [Validators.required]),
      marginType: new FormControl(condition ? condition.positionComponent?.marginType : PricingMarginType.Percentage, [Validators.required]),
      positionOperatorType: new FormControl(condition ? condition.positionComponent?.positionOperatorType : PositionOperatorType.Higher, [Validators.required]),
      pricingPositionType: new FormControl(condition ? condition.positionComponent?.positionType : PricingPositionType.Average, [Validators.required]),
      landscapeType: new FormControl(condition ? condition.targetComponent?.landscapeType : LandscapeType.Competitors, [Validators.required]),
      entityType: new FormControl({ value: condition ? condition.targetComponent?.entityType : (this.isSingleProductPricingStrategy ? PricingEntityTypes.Products : PricingEntityTypes.AllProducts), disabled: this.disabledProductPricingCondition }, [Validators.required]),
      entityIds: new FormControl({ value: condition?.targetComponent?.entityIds ?? condition?.targetComponent?.entityNames ?? [], disabled: this.disabledProductPricingCondition }, [Validators.required]),
      entityIdsMultiFilterCtrl: new FormControl({ value: condition ? condition.targetComponent?.entityIds : [], disabled: this.disabledProductPricingCondition }),
      competitorNames: new FormControl(condition && condition.targetComponent?.competitorNames && condition.targetComponent.competitorNames.length > 0 ? condition.targetComponent.competitorNames : ["My Competitors"], [Validators.required]),
      competitorNamesMultiFilterCtrl: new FormControl(condition ? condition.targetComponent?.competitorNames : [])
    });
  }

  private extractCondition(): IPricingCondition {
    let formControllers = this.conditionForm.controls;
    let condition: IPricingCondition = {
      positionComponent: <IPricingPositionComponent>{
        margin: formControllers.margin.value,
        marginType: formControllers.marginType.value,
        positionType: formControllers.pricingPositionType.value,
        positionOperatorType: formControllers.positionOperatorType.value
      },
      targetComponent: <IPricingTargetComponent>{
        entityType: this.entityType,
        landscapeType: formControllers.landscapeType.value,
      },
      description: ""
    }

    if (condition.targetComponent.entityType == PricingEntityTypes.Brands) {
      //entityIds: formControllers.entityIds?.value
      condition.targetComponent.entityNames = formControllers.entityIds.value;
    }
    else {
      condition.targetComponent.entityIds = formControllers.entityIds.value;
    }

    condition.description = this.getPricingConditionDescription(condition);
    condition.targetComponent.competitorNames = formControllers?.competitorNames?.value ? formControllers?.competitorNames?.value?.filter(x => x !== "My Competitors") : [];
    condition.targetComponent.excludedProductIds = this.pricingExclusionsComponent?.selectedExcludedProductsToSave ?? [];
    return condition;
  }

  private getPricingConditionDescription(condition: IPricingCondition): string {
    let conditionEntityType: PricingEntityTypes = condition.targetComponent.entityType;
    let pricingPositionType: PricingPositionType = condition.positionComponent.positionType;
    let positionOperatorType: PositionOperatorType = condition.positionComponent.positionOperatorType;
    return (this.isProductsGroupEntityType ? "all products" : (this.isSingleProductPricingStrategy ? this.getProductTitle(this.conditionForm.controls.entityIds?.value[0]) : PricingEntityTypes[conditionEntityType].toLocaleLowerCase())) + InterChangeSentencePlaceholder + " "
      + this.getMarginDescription(positionOperatorType, condition.positionComponent?.marginType, condition.positionComponent?.margin) + " "
      + PositionOperatorType[positionOperatorType].toLocaleLowerCase()
      + (this.isPricingDiscount ? "" : this.getThanOrTheSentence(positionOperatorType)
        + PricingPositionType[pricingPositionType].toLocaleLowerCase()
        + this.OfMy.toLocaleLowerCase() + (this.conditionForm.controls?.competitorNames?.value?.length == 1 && this.conditionForm.controls?.competitorNames?.value[0] === "My Competitors" ? "" : (this.conditionForm.controls?.competitorNames?.value?.length == 1 && this.conditionForm.controls?.competitorNames?.value[0] !== "My Competitors" ? "" : " my specific ")) + (this.conditionForm.controls.competitorNames?.value?.length == 1 ? this.conditionForm.controls.competitorNames?.value[0]?.toLocaleLowerCase() : LandscapeType[0].toLocaleLowerCase()));
  }

  private getMarginDescription(positionOperatorType: PositionOperatorType, pricingMarginType?: PricingMarginType, margin?: number): string {
    let marginDescription: string = "";

    if (positionOperatorType !== PositionOperatorType.Equal) {
      marginDescription = margin.toString() + this.getMarginView(pricingMarginType)
    }

    return marginDescription;
  }

  private getThanOrTheSentence(positionOperatorType: PositionOperatorType): string {
    return (positionOperatorType === PositionOperatorType.Equal ? this.ToThe : this.ThanThe).toLocaleLowerCase();
  }

  private loadProducts(): void {
    this.products = this.rulesService.rulesRelatedProducts;
    this.updateEntityIds(false);
  }

  private initProducts(): void {
    this.loadProducts();
    this.productsSubscription = this.rulesService.ruleListChangedEmitter.subscribe(() => {
      this.loadProducts();
    });
  }

  private loadCategories(): void {
    this.categories = this.productService.categories;
  }

  private initCategories(): void {
    this.loadCategories();
    this.categoriesSubscription = this.productService.categoriesListChangedEmitter.subscribe(() => {
      this.loadCategories();
    });
  }

  private loadBrands(): void {
    this.brands = this.productService.brands;
  }

  private initBrands(): void {
    this.loadBrands();
    this.brandsSubscription = this.productService.brandsListChangedEmitter.subscribe(() => {
      this.loadBrands();
    });
  }

  private loadCollections(): void {
    this.collections = this.productService.collections;
  }

  private initCollections(): void {
    this.loadCollections();
    this.collectionsSubscription = this.productService.collectionsChangedEmitter.subscribe(() => {
      this.loadCollections();
    });
  }

  private loadCompetitors(): void {
    this.competitors = [...this.competitorsService.activeCompetitors];
  }

  private initCompetitors(): void {
    this.loadCompetitors();
    this.competitorSubscription = this.competitorsService.competitorsListChangedEmitter.subscribe(() => {
      this.loadCompetitors();
    });
  }

  private unsubscribe() {
    if (!this.entitiesFilterSubscription?.closed)
      this.entitiesFilterSubscription?.unsubscribe();
    if (!this.productsSubscription?.closed)
      this.productsSubscription?.unsubscribe();
    if (!this.categoriesSubscription?.closed)
      this.categoriesSubscription?.unsubscribe();
    if (!this.brandsSubscription?.closed)
      this.brandsSubscription?.unsubscribe();
    if (!this.collectionsSubscription?.closed)
      this.collectionsSubscription?.unsubscribe();
    if (!this.competitorSubscription?.closed)
      this.competitorSubscription?.unsubscribe();
  }

  protected filterEntitiesMulti() {
    if (!this.entities) {
      return;
    }
    // get the search keyword
    let search = this.conditionForm.controls?.entityIdsMultiFilterCtrl?.value;
    if (!search) {
      this.filteredEntitiesMulti.next(this.entities.slice());
      return;
    } else {
      if (typeof search === "string") {
        $("#entity-search-input").children(".mat-select-search-inner").children(".mat-select-search-clear").show();
        search = search.toLowerCase();
      }
    }
    // filter the entities
    this.filteredEntitiesMulti.next(
      this.entities.filter(entity => entity.title.toLowerCase().indexOf(search) > -1)
    );
  }

  clearSearch() {
    $("#entity-search-input").children(".mat-select-search-inner").children(".mat-select-search-input").val("");
    $("#entity-search-input").children(".mat-select-search-inner").children(".mat-select-search-clear").hide();
    this.searchTermForProducts = "";
  }

  protected filterCompetitorEntitiesMulti() {
    if (!this.competitorEntities) {
      return;
    }
    this.currentSelectedCompetitors = this.competitorNames;

    // get the search keyword
    let search = this.conditionForm.controls?.competitorNamesMultiFilterCtrl?.value;
    if (!search || search == "") {
      this.filteredCompetitorEntitiesMulti.next(this.competitorEntities);
      return;
    } else {
      if (typeof search === "string") {
        $("#competitor-search-input").children(".mat-select-search-inner").children(".mat-select-search-clear").show();
        search = search.toLowerCase();
      }
    }

    let selectedCompetitorsOptions = this.currentSelectedCompetitors.map((name: string) => {
      return <IOption>{ value: name, title: name }
    });

    let filteredCompetitorEntities = this.competitorEntities.filter(competitorEntity => competitorEntity.title.toLowerCase().indexOf(search) > -1)
    let allSelectedCompetitorsOptions = filteredCompetitorEntities.concat(selectedCompetitorsOptions);

    // filter the competitors
    this.filteredCompetitorEntitiesMulti.next(allSelectedCompetitorsOptions);
  }

  clearCompetitorSearch() {
    $("#competitor-search-input").children(".mat-select-search-inner").children(".mat-select-search-input").val("");
  }

  setProductOnPricingCondition(productId: string) {
    let positionComponent: IPricingPositionComponent = { margin: 5, marginType: PricingMarginType.Percentage, positionOperatorType: PositionOperatorType.Higher, positionType: PricingPositionType.Average };
    let targetComponent: IPricingTargetComponent = { entityType: PricingEntityTypes.Products, landscapeType: LandscapeType.Competitors, entityIds: [productId] };
    let condition: IPricingCondition = { positionComponent: positionComponent, targetComponent: targetComponent, description: null };
    this.initializeConditionForm(condition, false);
  }

  private getProductTitle(productId: string) {
    return this.rulesRelatedProducts.filter(x => x.id == productId)[0]?.title;
  }

  private filterSingleProducts(filteredProducts: Array<ISearchableProduct>) {
    let singleProductPricingStrategyProducts = new Array<ISearchableProduct>();
    let editRuleProduct = null;
    if (this.singleProductEditRule) {
      if (this.isPricingDiscount)
        editRuleProduct = this.singleProductEditRule?.priceChange?.targetComponent?.entityIds[0];
      else
        editRuleProduct = this.singleProductEditRule?.pricingCondition?.targetComponent?.entityIds[0];
    }
    for (let singleProduct of this.singleProducts) {
      let product = this.products.filter(x => x.id == singleProduct)[0];
      if (product) {
        if (editRuleProduct) {
          if (product.id != editRuleProduct)
            singleProductPricingStrategyProducts.push(product);
        } else
          singleProductPricingStrategyProducts.push(product);
      }
    }
    return filteredProducts.filter(product => !singleProductPricingStrategyProducts.includes(product));
  }

  private filterSingleProductsForFilter(filteredProducts: Array<IOption>) {
    let singleProductPricingStrategyProducts = new Array<IOption>();
    let editRuleProduct = null;
    if (this.singleProductEditRule)
      editRuleProduct = this.singleProductEditRule?.pricingCondition?.targetComponent?.entityIds[0];
    for (let singleProduct of this.singleProducts) {
      let product = this.products.filter(x => x.id == singleProduct)[0];
      if (product) {
        if (editRuleProduct) {
          if (product.id != editRuleProduct)
            singleProductPricingStrategyProducts.push({ title: product.title, value: product.id });
        } else
          singleProductPricingStrategyProducts.push({ title: product.title, value: product.id });
      }
    }
    if (singleProductPricingStrategyProducts.length > 0) {
      for (let removableProduct of singleProductPricingStrategyProducts) {
        filteredProducts = filteredProducts.filter(x => x.value != removableProduct.value);
      }
    }
    return filteredProducts;
  }

  onEntityChange(event: MatOptionSelectionChange): void {
    if (event && event.isUserInput && this.entityType == PricingEntityTypes.Products) {
      let products = this.rulesRelatedProducts?.slice();
      if (products && products.length > 0) {
        let product = products.filter(x => x.id == event.source.value)[0];
        if (!product) {
          let entities = this.products?.slice();
          if (entities && entities.length > 0)
            product = entities.filter(x => x.id == event.source.value)[0];
        }
        if (product) {
          if (event.source.selected)
            this.selectedProducts.push(product);
          else
            this.selectedProducts = this.selectedProducts.filter(x => x.id != product.id).slice();
        }
      }
      if (this.isSingleProductPricingStrategy)
        this.conditionForm.controls?.entityIds?.setValue([]);
    }
  }

  addEntitiesRequiredValidation() {
    this.conditionForm?.controls?.entityIds?.setValidators([Validators.required]);
    this.conditionForm?.controls?.entityIds?.updateValueAndValidity();
  }

  removeEntitiesRequiredValidation() {
    this.conditionForm?.controls?.entityIds?.setValidators(null);
    this.conditionForm?.controls?.entityIds?.updateValueAndValidity();
  }

  onCompetitorChange(event: any) {
    if (event.isUserInput) {
      if (event.source.value === "My Competitors" && event.source.selected)
        this.conditionForm.controls?.competitorNames?.setValue(["My Competitors"]);
      else if (event.source.value !== "My Competitors" && event.source.selected) {
        this.competitorNames = this.competitorNames?.filter(x => x !== "My Competitors")
      }
      setTimeout(() => {
        this.OfMy = this.conditionForm.controls?.competitorNames?.value?.length == 1 && this.conditionForm.controls?.competitorNames?.value[0] === "My Competitors" ? " of all " : " of ";
      });
    }
  }
}

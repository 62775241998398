<div *ngIf="data && data.length" #containerRef>
  <ngx-charts-line-chart [roundDomains]="true" [scheme]="colorScheme" [legendPosition]="legendPosition"
                         [legendTitle]="legendTitle" [legend]="legend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                         [xAxis]="xAxis" [showGridLines]="false" [yAxis]="yAxis" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                         [timeline]="timeline" [curve]="curve" [results]="data" (window:resize)="onResize($event)"
                         (select)="onSelect($event)" [view]="[elementRefWidth, height]">
  </ngx-charts-line-chart>
</div>

<div *ngIf="!isLoading && !data?.length"
     class="no-data-block">
  <p>No Data found</p>
</div>

<div *ngIf="isLoading" class="no-data-block">
  <ng-lottie [options]="lottieOptions" width="30px" height="30px"></ng-lottie>
</div>

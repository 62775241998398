<div *ngIf="!isLoading && data?.length" #containerRef>
  <ngx-charts-area-chart-stacked [view]="[elementRefWidth, height]" [scheme]="colorScheme" [schemeType]="schemeType" [results]="data"
                                 [animations]="animations" [legend]="showLegend" [legendTitle]="legendTitle"
                                 [legendPosition]="legendPosition"
                                 [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
                                 [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                                 [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [timeline]="timeline"
                                 [xScaleMin]="xScaleMin" [xScaleMax]="xScaleMax"
                                 [yScaleMin]="yScaleMin" [yScaleMax]="yScaleMax" [showGridLines]="showGridLines"
                                 [roundDomains]="roundDomains" [tooltipDisabled]="tooltipDisabled"
                                 [curve]="curve" [trimXAxisTicks]="trimXAxisTicks" [trimYAxisTicks]="trimYAxisTicks"
                                 [rotateXAxisTicks]="rotateXAxisTicks"
                                 [maxXAxisTickLength]="maxXAxisTickLength" [maxYAxisTickLength]="maxYAxisTickLength">
  </ngx-charts-area-chart-stacked>
</div>

<div *ngIf="!isLoading && !data?.length"
     class="no-data-block">
  <p>No Data found</p>
</div>

<div *ngIf="isLoading" class="no-data-block">
  <ng-lottie [options]="lottieOptions" width="30px" height="30px"></ng-lottie>
</div>

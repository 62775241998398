import { ICompetitor } from "app/common/interfaces/ICompetitor";

export const SalesChannels: ICompetitor[] = [
    {
        id: "602ffc73d4501c622cf51274",
        name: "Ebay",
        imageUrl: "../../../assets/images/sale-channels/ebay.svg",
        isSelectedOnBoard: false,
        isHiddenOnBoard: false,
        sequenceOnBoard: 3,
        isSvg : true

    },
    {
        id: "602ffc73d4501c622cf5127f",
        name: "NewEgg",
        imageUrl: "../../../assets/images/sale-channels/newegg.png",
        isSelectedOnBoard: false,
        isHiddenOnBoard: false,
        sequenceOnBoard: 8
    },
    {
        id: "602ffc73d4501c622cf5127b",
        name: "IHerb",
        imageUrl: "../../../assets/images/sale-channels/iherb.png",
        isSelectedOnBoard: false,
        isHiddenOnBoard: false,
        sequenceOnBoard: 7
    },
    {
        id: "602ffc73d4501c622cf51279",
        name: "Geekbuying",
        imageUrl: "../../../assets/images/sale-channels/geekbuying.png",
        isSelectedOnBoard: false,
        isHiddenOnBoard: false,
        sequenceOnBoard: 9
    },
    {
        id: "602ffc73d4501c622cf5127e",
        name: "BestBuy",
        imageUrl: "../../../assets/images/sale-channels/bestbuy.png",
        isSelectedOnBoard: false,
        isHiddenOnBoard: false,
        sequenceOnBoard: 5,
        
    },
    {
        id: "602ffc73d4501c622cf51276",
        name: "Amazon",
        imageUrl: "../../../assets/images/sale-channels/amazon.svg",
        isSelectedOnBoard: false,
        isHiddenOnBoard: false,
        sequenceOnBoard: 2,
        isSvg : true

    },
    {
        id: "6096d6e97ccfc76cdc71d671",
        name: "Google Shopping",
        imageUrl: "../../../assets/images/sale-channels/Google-shopping-text.svg",
        isSelectedOnBoard: false,
        isHiddenOnBoard: false,
        sequenceOnBoard: 1,
        isSvg : true

    },
    {
        id: "602ffc73d4501c622cf51282",
        name: "Stockx",
        imageUrl: "../../../assets/images/sale-channels/Stocks.svg",
        isSelectedOnBoard: false,
        isHiddenOnBoard: false,
        sequenceOnBoard: 11,
        isSvg : true

    },
    {
        id: "602ffc73d4501c622cf51277",
        name: "Gearbest",
        imageUrl: "../../../assets/images/sale-channels/gearbest.png",
        isSelectedOnBoard: false,
        isHiddenOnBoard: false,
        sequenceOnBoard: 12
    },
    {
        id: "602ffc73d4501c622cf51280",
        name: "Walmart",
        imageUrl: "../../../assets/images/sale-channels/Walmart.svg",
        isSelectedOnBoard: false,
        isHiddenOnBoard: false,
        sequenceOnBoard: 10,
        isSvg : true

    },
    {
        id: "602ffc73d4501c622cf51281",
        name: "Target",
        imageUrl: "../../../assets/images/sale-channels/target.png",
        isSelectedOnBoard: false,
        isHiddenOnBoard: false,
        sequenceOnBoard: 4
    },
    {
        id: "602ffc73d4501c622cf5127c",
        name: "Cdkeys",
        imageUrl: "../../../assets/images/sale-channels/cdkeys.png",
        isSelectedOnBoard: false,
        isHiddenOnBoard: false,
        sequenceOnBoard: 13
    },
    {
        id: "602ffc73d4501c622cf5127d",
        name: "Asos",
        imageUrl: "../../../assets/images/sale-channels/asos.svg",
        isSelectedOnBoard: false,
        isHiddenOnBoard: false,
        sequenceOnBoard: 14,
        isSvg : true

    },
    {
        id: "602ffc73d4501c622cf51278",
        name: "Banggood",
        imageUrl: "../../../assets/images/sale-channels/banggood.png",
        isSelectedOnBoard: false,
        isHiddenOnBoard: false,
        sequenceOnBoard: 15
    },
    {
        id: "602ffc73d4501c622cf5127a",
        name: "Joybuy",
        imageUrl: "../../../assets/images/sale-channels/joybuy.png",
        isSelectedOnBoard: false,
        isHiddenOnBoard: false,
        sequenceOnBoard: 16
    },
    {
        id: "6113e97fd0e4bf290fc616cc",
        name: "Lyst",
        imageUrl: "../../../assets/images/sale-channels/lyst.svg",
        isSelectedOnBoard: false,
        isHiddenOnBoard: false,
        sequenceOnBoard: 17,
        isSvg : true
    },
    {
        id: "615444a44c6ded20b06b6866",
        name: "Home Depot",
        imageUrl: "../../../assets/images/sale-channels/Home_depot.svg",
        isSelectedOnBoard: false,
        isHiddenOnBoard: false,
        sequenceOnBoard: 6,
        isSvg : true

    }
];
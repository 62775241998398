import { NgModule } from '@angular/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StarComponent } from './star.component';
import { VerticalBarChartComponent } from './vertical-bar-chart/vertical-bar-chart.component';
import { IconModule } from '@visurel/iconify-angular';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import {
  NbSidebarModule,
  NbSidebarService,
  NbCardModule,
  NbProgressBarModule,
  NbTabsetModule,
  NbListModule,
} from '@nebular/theme';
import { MaterialDesignModule } from '../common/material-design.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { NbIconModule } from '@nebular/theme';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EpDialogComponent } from '../common/dialogs/ep-dialog/ep-dialog.component';
import { EpFormDialogComponent } from '../common/dialogs/ep-form-dialog/ep-form-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EpYesNoDialogComponent } from '../common/dialogs/ep-yes-no-dialog/ep-yes-no-dialog.component';
import { BiLineChartComponent } from './bi-line-chart/bi-line-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { NumberCardChartComponent } from './number-card-chart/number-card-chart.component';
import { TrendArrowComponent } from './trend-arrow/trend-arrow.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProductsTableComponent } from '../components/products-table/products-table.component';
import { ProductDialogComponent } from 'app/components/product-dialog/product-dialog.component';

import { EpFileUploadInputComponent } from './ep-file-upload-input/ep-file-upload-input.component';

import { CurrencySymbolizerPipe } from 'app/common/pipes/currency-symbolizer.pipe';
import { AdvancedPieChartComponent } from './advanced-pie-chart/advanced-pie-chart.component';
import { EpSelectFilterComponent } from './ep-select-filter/ep-select-filter.component';

import { NbSpinnerModule } from '@nebular/theme';
import { SearchHeaderComponent } from './search-header/search-header.component';
import { MultiSelectChipsComponent } from './multi-select-chips/multi-select-chips.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AddCompetitorUrlComponent } from 'app/components/add-competitor-url/add-competitor-url.component';
import { ActionItemsComponent } from './action-items/action-items.component';
import { SwiperModule } from 'swiper/angular';
import { OnBoardingDialogComponent } from './onboarding-dialog/onboarding-dialog.component';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { DisableElementDirective } from 'app/common/directives/disable-element.directive';
import { PricingConditionComponent } from 'app/components/conditions/pricing-condition/pricing-condition.component';
import { DeleteCompetitorUrlComponent } from 'app/components/delete-competitor-url-dialog/delete-competitor-url-dialog.component';
import { ComboChartComponent } from './combo-chart/combo-chart.component';
import { ComboSeriesVerticalComponent } from './combo-chart/combo-series-vertical.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { PieGridComponent } from './pie-grid/pie-grid.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ProductVariantsComponent } from 'app/components/products-table/product-variants/product-variants.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { StackedAreaChartComponent } from './stacked-area-chart/stacked-area-chart.component';
import { PricingPlanUpgradeDialogComponent } from './pricing-plan-upgrade-dialog/pricing-plan-upgrade-dialog.component';
import { SyncFormComponent } from 'app/components/sync-form/sync-form.component';
import { PricingExclusionsComponent } from 'app/components/conditions/pricing-exclusions/pricing-exclusions.component';
import { CompetitorDialogComponent } from 'app/components/competitors/competitor-dialog/competitor-dialog.component';
import { SalesChannelDialogComponent } from 'app/components/competitors/sales-channel-dialog/sales-channel-dialog.component';
import {LottieModule} from 'ngx-lottie';
import {PipesModule} from '../common/pipes/pipes.module';
import {ItemsSkeletonModule} from '../components/items-skeleton/items-skeleton.module';
import {ConfirmModalModule} from '../modals/confirm-modal/confirm-modal.module';
import {PriceSliderModule} from '../components/price-slider/price-slider.module';

@NgModule({
  declarations: [
    StarComponent,
    AdvancedPieChartComponent,
    VerticalBarChartComponent,
    EpYesNoDialogComponent,
    EpDialogComponent,
    EpFormDialogComponent,
    BiLineChartComponent,
    PieChartComponent,
    RangeSliderComponent,
    NumberCardChartComponent,
    TrendArrowComponent,
    EpFileUploadInputComponent,
    TrendArrowComponent,
    ProductsTableComponent,
    AddCompetitorUrlComponent,
    DeleteCompetitorUrlComponent,
    ProductDialogComponent,
    CurrencySymbolizerPipe,
    EpSelectFilterComponent,
    SearchHeaderComponent,
    MultiSelectChipsComponent,
    ActionItemsComponent,
    OnBoardingDialogComponent,
    DisableElementDirective,
    PricingConditionComponent,
    PricingExclusionsComponent,
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    PieGridComponent,
    ProductVariantsComponent,
    StackedAreaChartComponent,
    PricingPlanUpgradeDialogComponent,
    SyncFormComponent,
    CompetitorDialogComponent,
    SalesChannelDialogComponent,
  ],
  imports: [
    MatCardModule,
    ReactiveFormsModule,
    CommonModule,
    NgxChartsModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    FormsModule,
    MatFormFieldModule,
    MaterialDesignModule,
    MatTooltipModule,
    MatSortModule,
    MatSliderModule,
    MatTableModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatChipsModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    IconModule,
    MatMenuModule,
    MatSelectModule,
    FlexLayoutModule,
    NbSpinnerModule,
    NbIconModule,
    MatAutocompleteModule,
    NbCardModule,
    SwiperModule,
    NbProgressBarModule,
    ClipboardModule,
    PinchZoomModule,
    MatTableExporterModule,
    NgxMatSelectSearchModule,
    NbTabsetModule,
    MatButtonToggleModule,
    NbListModule,
    LottieModule,
    PipesModule,
    ItemsSkeletonModule,
    ConfirmModalModule,
    PriceSliderModule,
  ],
  exports: [
    MatCardModule,
    EpSelectFilterComponent,
    VerticalBarChartComponent,
    PieChartComponent,
    RangeSliderComponent,
    NumberCardChartComponent,
    BiLineChartComponent,
    SearchHeaderComponent,
    StarComponent,
    CommonModule,
    FormsModule,
    MaterialDesignModule,
    EpDialogComponent,
    EpFormDialogComponent,
    EpYesNoDialogComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    TrendArrowComponent,
    MatIconModule,
    MatTooltipModule,
    MatSortModule,
    MatSliderModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    NbSidebarModule,
    MatChipsModule,
    EpFileUploadInputComponent,
    MatCheckboxModule,
    IconModule,
    MatMenuModule,
    MatSelectModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    ProductsTableComponent,
    AddCompetitorUrlComponent,
    DeleteCompetitorUrlComponent,
    ProductDialogComponent,
    CurrencySymbolizerPipe,
    NgxChartsModule,
    AdvancedPieChartComponent,
    NbSpinnerModule,
    MultiSelectChipsComponent,
    MatAutocompleteModule,
    ActionItemsComponent,
    NbCardModule,
    OnBoardingDialogComponent,
    NbProgressBarModule,
    DisableElementDirective,
    PricingConditionComponent,
    PricingExclusionsComponent,
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    PinchZoomModule,
    MatTableExporterModule,
    PieGridComponent,
    NgxMatSelectSearchModule,
    NbTabsetModule,
    ProductVariantsComponent,
    MatButtonToggleModule,
    StackedAreaChartComponent,
    PricingPlanUpgradeDialogComponent,
    SyncFormComponent,
    CompetitorDialogComponent,
    SalesChannelDialogComponent,
    PipesModule,
    LottieModule,
    ConfirmModalModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    NbSidebarService,
  ],
})
export class SharedModule {}

import {Component, OnInit, ViewChild} from '@angular/core';
import {IOnboardingData} from '../../common/interfaces/IOnboarding-data';
import {ITenantSettings} from '../../common/interfaces/tenant/ITenantSettings';
import {SearchKeywordTypesEnum} from '../../common/enums/SearchKeywordTypes.enum';
import {VariantsSetupTypes} from '../../common/enums/VariantsSetupTypes.enum';
import {CategoryTypesEnum} from '../../common/enums/CategoryTypes.enum';
import {ICompetitor} from '../../common/interfaces/ICompetitor';
import {SalesChannels} from '../../../static-data/sales-channels-data';
import {GoogleShoppingId} from '../../common/constants';
import {SessionService} from '../../common/services/session.service';
import {Country} from '@angular-material-extensions/select-country';
import {ICurrency} from '../../common/interfaces/ICurrency';
import {LocalizationService} from '../../common/services/localization.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SyncFormComponent} from '../../components/sync-form/sync-form.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {NbGlobalPhysicalPosition} from '@nebular/theme';
import {ToastrService} from '../../common/services/toastr.service';
import {ITenant} from '../../common/interfaces/tenant/ITenant';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  templateUrl: './user-onboarding-modal.component.html',
  styleUrls: ['./user-onboarding-modal.component.scss'],
})
export class UserOnboardingModalComponent implements OnInit {
  @ViewChild('pmSyncForm') syncFormComponent: SyncFormComponent;

  allCompetitors: ICompetitor[] = [];
  countries: Country[] = [];
  currencies: ICurrency[] = [];
  form: FormGroup;
  syncFormGroup: FormGroup;
  currentTenant: ITenant;
  isValidSyncForm: boolean = false;
  isLoading: boolean = false;
  selectedSearchKeyword: any;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  readonly numberOfSalesChannelsDisplayByDefault: number = 4;

  constructor(public matDialogRef: MatDialogRef<UserOnboardingModalComponent>,
              private sessionService: SessionService,
              private localizationService: LocalizationService,
              private toastrService: ToastrService,
  ) {
    this.matDialogRef = matDialogRef;
  }

  ngOnInit(): void {
    this.countries = this.localizationService.getCountries();
    this.currencies = this.localizationService.getCurrencies();
    this.currentTenant = this.sessionService?.currentTenant;
    this.syncFormGroup = new FormGroup({
      syncEntitiesSelect: new FormControl(['', Validators.required]),
      targetSyncEntityName: new FormControl(),
      collectionsSelectFilterCtrl : new FormControl(),
      addToActiveProducts: new FormControl(false),
      merchantAccountSelect: new FormControl(),
    });

    this.initializeFormsData();
    this.initFormGroup();
  }

  addEmailChip(event: MatChipInputEvent): void {
    const emailRegex = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$';
    if (event.value && !event.value.match(emailRegex)) {
      this.toastrService.showToast('Please, enter a valid email', 'Oops...', 'warning', NbGlobalPhysicalPosition.TOP_RIGHT);
      return;
    }

    const allSubscribers = this.form?.controls?.emailSubscribers?.value;

    if (event.value.trim().length > 50) {
      this.toastrService.showToast('Email should be less 50 characters', 'Oops...', 'warning', NbGlobalPhysicalPosition.TOP_RIGHT);
      return;
    }
    if (event.value.trim()) {
      allSubscribers.push(event.value.trim());
      this.form?.controls?.emailSubscribers?.setValue(allSubscribers);
    }

    if (event.input) {
      event.input.value = '';
    }
  }

  removeEmailChip(subscriber: string): void {
    const allSubscribers = this.form?.controls?.emailSubscribers?.value;
    const index = allSubscribers.indexOf(subscriber);

    if (index >= 0) {
      allSubscribers.splice(index, 1);
      this.form?.controls?.emailSubscribers?.setValue(allSubscribers);
    }
  }

  sendOnBoardingData() {
    this.isLoading = true;
    const onBoardingData: IOnboardingData = this.buildOnBoardingData();

    this.sessionService.finishOnBoarding(onBoardingData).subscribe(() => {
      this.sessionService.loadUserData();
      this.sessionService.isFinishedOnBoarding = true;
      this.isLoading = false;
      this.matDialogRef.close();
    });
  }

  setCountry(alpha2Code: string): void {
    if (alpha2Code) {
      let country = this.countries.filter(x => x.alpha2Code == alpha2Code)[0];
      if (country) {
        this.form.patchValue({
          country: country
        });
      }
    }
  }

  setCurrency(code: string): void {
    if (code) {
      let currency = this.currencies.filter(x => x.code == code)[0];
      if (currency) {
        this.form.patchValue({
          currency: currency
        });
      }
    }
  }

  private initializeFormsData() {
    this.allCompetitors = SalesChannels.sort(function (competitor1: ICompetitor, competitors2: ICompetitor) {
      if (competitor1.sequenceOnBoard < competitors2.sequenceOnBoard) {
        return -1;
      }
      if (competitor1.sequenceOnBoard > competitors2.sequenceOnBoard) {
        return 1;
      }
      return 0;
    });
    const defaultSelectedSaleChannel: ICompetitor = this.allCompetitors.filter(x => x.id === GoogleShoppingId)[0];

    if (defaultSelectedSaleChannel) defaultSelectedSaleChannel.isSelectedOnBoard = true;

    this.hideMoreSalesChannels();
  }

  private initFormGroup(): void {
    const integrationCurrency = this.sessionService.currentIntegration?.shopCurrency;
    const integrationCountry = this.sessionService.currentIntegration?.shopCountry;
    let preselectedCurrency = null;
    let preselectedCountry = null;

    if (integrationCurrency) {
      preselectedCurrency = this.currencies.find((currency) => currency.code === integrationCurrency);
    }
    if (integrationCountry) {
      preselectedCountry = this.countries.find((country) => country.alpha2Code === integrationCountry);
    }

    this.form = new FormGroup({
      emailSubscribers: new FormControl(
        this.sessionService?.currentUser?.alternativeEmails || [this.sessionService?.currentUser?.email] || [],
        [Validators.required, Validators.min(1)],
      ),
      currency: new FormControl(preselectedCurrency, [Validators.required]),
      country: new FormControl(preselectedCountry, [Validators.required]),
    });
  }

  private buildOnBoardingData(): IOnboardingData {
    return {
      tenantSettings: this.buildTenantSettings(),
      competitorIds: this.allCompetitors.filter(x => x.isSelectedOnBoard).map(x => x.id),
    };
  }

  private hideMoreSalesChannels(): void {
    for (let i = 0; i < this.allCompetitors.length; i++) {
      if (i > this.numberOfSalesChannelsDisplayByDefault - 1)
        this.allCompetitors[i].isHiddenOnBoard = true;
    }
  }

  private buildTenantSettings(): ITenantSettings {
    const syncTargetInfo = this.syncFormComponent.syncTargetInfo;
    let variantsSetupType = VariantsSetupTypes.VariantsAsProductChild;
    let selectedSearchType = SearchKeywordTypesEnum.Title;

    if (this.selectedSearchKeyword?.value != null) {
      selectedSearchType = this.selectedSearchKeyword.value;
    }

    if (selectedSearchType === SearchKeywordTypesEnum.Barcode || selectedSearchType === SearchKeywordTypesEnum.Sku) {
      variantsSetupType = VariantsSetupTypes.ProductPerVariant;
    }

    return {
      countrySymbol: this.form.controls['country'].value?.alpha2Code.toLowerCase(),
      currency: this.form.controls['currency'].value,
      enrichmentOptions: null,
      filteringOptions: null,
      viewOptions: null,
      emails: this.form?.controls?.emailSubscribers?.value,
      onBoardingOptions: {
        isDone: true,
        syncEntityType : syncTargetInfo.syncEntityType,
        targetSyncEntityName : syncTargetInfo.targetSyncEntityName,
        merchantId : syncTargetInfo.merchantId,
      },
      storeCategoryType: CategoryTypesEnum.Other,
      searchOptions : {
        searchType : selectedSearchType,
      },
      provisioningOptions : {
        variantsSetupType,
      },
    };
  }
}

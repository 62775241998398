export const API = 'https://ecopricebiapi.azurewebsites.net/api/v1/';
export const AmazonLogo = 'https://ecoprice.shop/resources/images/amazon.png';
export const EbayLogo = 'https://ecoprice.shop/resources/images/ebay.png';
export const AliexpressLogo = 'https://ecoprice.shop/resources/images/aliexpress.png';
export const CdkeysLogo = 'https://ecoprice.shop/resources/images/cdkeys.png';
export const BanggoodLogo = 'https://ecoprice.shop/resources/images/banggood.png';
export const GeekbuyingLogo = 'https://ecoprice.shop/resources/images/geekbuying.png';
export const GearbestLogo = 'https://ecoprice.shop/resources/images/gearbest.png';
export const IHerbLogo = 'https://ecoprice.shop/resources/images/iherb.png';
export const JoybuyLogo = 'https://ecoprice.shop/resources/images/joybuy.png';
export const StockXLogo = 'https://s29643.pcdn.co/wp-content/uploads/StockX-blog-thumbnail-4.png';
export const BestBuyLogo = 'https://ecoprice.shop/resources/images/bestbuy.png';
export const WalmartLogo = 'https://ecoprice.shop/resources/images/walmart.png';
export const TargetLogo = 'https://ecoprice.shop/resources/images/target.png';

export const LOGOUT = 'Log Out';
export const BILLING = 'Billing';
export const GoogleShoppingId = '6096d6e97ccfc76cdc71d671';
export const DemoPlatformId = '613a106165249597a0387a80';

export const ColorScheme = {
  domain: ['#7A4AFF', '#3A56FC', '#AB4AFF', '#33CFFF', '#00F98E', '#DE4AFF', '#4588F4', '#46F7EE', '#00F98E', '#FF4ABD']
};

export const GreenColorScheme = {
  domain: ['#FF2468', '#1EC1AA', '#5F6DF0', '#C8CAD6']
};

export const GoogleShoppingVisibilityColorScheme = {
  domain: ['#FF2468', '#1EC1AA', '#5F6DF0', '#00F98E']
};

export const PricingGapChartColorScheme = {
  domain: ['#7FDF9A', '#FF7070', '#3430F4'],
};


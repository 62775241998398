<form [formGroup]="salesChannelForm" (ngSubmit)="onFormSubmit()" novalidate>
  <pm-ep-form-dialog [dialogMaxWidth]="450" class="dialog" [sendButtonDisabled]="isSubmitFormDisabled()"
    [title]="dialogTitle" [submitBtnTitle]="dialogSubmitBtnTitle" (dialogClose)="cancelSettingsDialog()"
    [shouldShow]="shouldShowSalesChannelDialog" (shouldShowChange)="onShouldShowDialogChange($event)">
    <ng-container *ngIf="isAdmin">
        <div class="gw-padding-top-10">
            <mat-checkbox class="margin-right--m"
              type="checkbox"
              style="transform: scale(1.15);"
              formControlName="shouldAddNewCompetitorUrls">
            </mat-checkbox>
            <b class="margin-right--m">Should add new competitor urls?</b>
        </div>
        <div class="gw-padding-top-10">
            <mat-checkbox class="margin-right--m"
              type="checkbox"
              style="transform: scale(1.15);"
              formControlName="shouldEnrichResults">
            </mat-checkbox>
            <b class="margin-right--m">Should enrich results?</b>
        </div>
        <div class="gw-padding-top-10">
            <mat-checkbox class="margin-right--m"
              type="checkbox"
              style="transform: scale(1.15);"
              formControlName="allowGlobalStores">
            </mat-checkbox>
            <b class="margin-right--m">Allow global stores?</b>
        </div>
        <div class="gw-padding-top-10">
            <mat-checkbox class="margin-right--m"
              type="checkbox"
              style="transform: scale(1.15);"
              formControlName="activateForNoCompetitionOnly">
            </mat-checkbox>
            <b class="margin-right--m">Activate for no competition only?</b>
        </div>
        <div class="gw-padding-top-10">
          <mat-checkbox class="margin-right--m"
            type="checkbox"
            style="transform: scale(1.15);"
            formControlName="isEnableTaxInclusion"
            (change)="onEnableTaxInclusion($event)">
          </mat-checkbox>
          <b class="margin-right--m">Adjust Tax by</b>
          <mat-form-field class="margin-right--m mat-select-small">
            <mat-select matInput formControlName="taxInclusionActionMarginType">
              <mat-option *ngFor="let actionMargin of taxInclusionActionMarginTypes" [value]="actionMargin">
                {{getActionMarginView(actionMargin)}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="cost-size input-field margin-right--m">
            <input matInput type="number" min="0" max="100" formControlName="taxInclusionMargin">
          </mat-form-field>
          <mat-form-field class="mat-select-small">
            <mat-select matInput formControlName="taxInclusionMarginType">
              <mat-option *ngFor="let margin of marginTypes" [value]="margin">
                {{getMarginView(margin)}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
    </ng-container>
  </pm-ep-form-dialog>
</form>

<pm-ep-yes-no-dialog [title]="ConfirmDiscardChanges" (dialogConfirm)="onCancelYesNoDialog()"
  (dialogCancel)="onConfirmYesNoDialog()" [(shouldShow)]="shouldShowSubmitDialogText">
  {{ConfirmDiscardChangesMessage}}</pm-ep-yes-no-dialog>
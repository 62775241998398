<div *ngIf="!isNewDesign" class="gw-flex-container">
  <div *ngIf="hasCompetition" class="gw-flex-item change-color" [ngStyle]="{'color': currentColor}">
    <i class="material-icons">{{arrowDrop}}</i>
  </div>
  <div class="gw-flex-item change-color" [ngStyle]="{'color': currentColor}">
    {{differenceValue}}
  </div>
</div>

<ng-container *ngIf="isNewDesign">
  <div class="box-wrapper">
    <ng-container *ngIf="hasCompetition && arrowDrop && differenceAmount">
      <img *ngIf="arrowDrop === 'arrow_drop_up'" src="/assets/images/icons/arrow-up_red.svg" alt="Arrow icon">
      <img *ngIf="arrowDrop === 'arrow_drop_down'" class="rotate-180" src="/assets/images/icons/arrow-up_green.svg" alt="Arrow icon">
    </ng-container>
    <img *ngIf="hasCompetition && !differenceAmount" src="/assets/images/icons/equal-sign_blue.svg" alt="Equal icon">

    <div class="right-side" [class.text-center]="!hasCompetition || !arrowDrop">
      <ng-container *ngIf="hasCompetition">
        <span *ngIf="isProductsPriceView" class="top-text">
          {{differenceAmount | currency:currencySymbol:'symbol':'2.1-2'}}
        </span>

        <span *ngIf="!isProductsPriceView" class="top-text">{{differenceAmount}}%</span>
      </ng-container>

      <span *ngIf="!hasCompetition" class="top-text">No Competition Found</span>
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>

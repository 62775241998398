<div *ngIf="modalData" class="modal-wrapper">
  <mat-dialog-content>
    <img *ngIf="modalData.imageURL" src="{{modalData?.imageURL}}" alt="Confirm image">
    <h4>{{modalData?.title}}</h4>
    <p *ngIf="modalData?.message" class="text-secondary text-center mb-16">{{modalData?.message}}</p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button class="highlighted" (click)="matDialogRef.close(true)">{{modalData?.confirmButtonText || 'Yes'}}</button>
    <button (click)="matDialogRef.close(false)">{{modalData?.declineButtonText || 'No'}}</button>
  </mat-dialog-actions>
</div>

import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ColorScheme} from 'app/common/constants';
import {PricingEntityTypes} from 'app/common/enums/pricing-entity-type.enum';
import {ProductsService} from 'app/common/services/product.service';
import {AbnormalProducts} from '../../common/enums/abnormal-products.enum';

@Component({
  selector: 'pm-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements AfterViewInit, AfterViewChecked {
  @ViewChild('containerRef') containerRef: ElementRef;

  @Input() height = 300;
  @Input() xAxisLabel;
  @Input() yAxisLabel;
  @Input() data;
  @Input() legendTitle;
  @Input() showLegend = true;
  @Input() itemsData: {};
  @Input() pricingEntityType: PricingEntityTypes;
  @Input() pricingEntityId: string;
  @Input() isBrandsOrCategoriesChart: boolean = false;

  multi: any[];
  view: any[] = null;
  showXAxisLabel = true;
  showYAxisLabel = true;
  gradient = true;
  showLabels = true;
  isDoughnut = false;
  legendPosition = 'below';
  colorScheme = {...ColorScheme};

  constructor(private cdf: ChangeDetectorRef,
              private productService: ProductsService,
              private router: Router,
  ) { }

  ngAfterViewChecked(): void {
    this.cdf.detectChanges();
  }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  onResize(): void {
    if (this.elementRefWidth) {
      this.view = [this.elementRefWidth, this.height];
    }
  }

  onSelect(positioning: string): void {
    if (this.isBrandsOrCategoriesChart) {
      this.productService.addProductsFilters(this.pricingEntityType, positioning);
      this.router.navigate(['/pages/products']);
      return;
    }

    const positioningProduct: AbnormalProducts = this.productService.getSelectedAbnormalProduct(positioning);
    this.router.navigate(['/pages/products'], {queryParams: {abnormal: positioningProduct}});
  }

  get elementRefWidth(): number {
    return this.containerRef?.nativeElement?.offsetWidth;
  }
}

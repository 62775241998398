import {PricingPlanTypes} from 'app/common/enums/billing/PricingPlanTypes.enum';
import {IPricingPlan} from 'app/common/interfaces/billing/pricingPlan';

export const pricingPlans: IPricingPlan[] = [
  {
    name: 'Basic',
    actionMessage: 'Change Plan',
    priceInformation: 'per {0}, up to 100 products',
    pricingPlanType: PricingPlanTypes.Basic,
    isCurrent: false,
    productsLimit: 100,
    price: 79,
    annualPrice: 948,
  },
  {
    name: 'Premium',
    actionMessage: 'Change Plan',
    priceInformation: 'per {0}, up to 1000 products',
    pricingPlanType: PricingPlanTypes.Premium,
    isCurrent: false,
    productsLimit: 1000,
    price: 249,
    annualPrice: 2988,
  },
  {
    name: 'Business',
    actionMessage: 'Change Plan',
    priceInformation: 'per {0}, up to 5000 products',
    pricingPlanType: PricingPlanTypes.Business,
    isCurrent: false,
    productsLimit: 5000,
    price: 499,
    annualPrice: 5988,
  },
  {
    name: 'Enterprise',
    actionMessage: 'Change Plan',
    priceInformation: 'per {0}, up to 10K products',
    pricingPlanType: PricingPlanTypes.Enterprise,
    isCurrent: false,
    productsLimit: 10000,
    price: 849,
    annualPrice: 10188,
  },
  {
    name: 'Custom',
    actionMessage: 'Contact Us',
    priceInformation: 'Have more than 10K SKUs?',
    pricingPlanType: PricingPlanTypes.Custom,
    isCurrent: false,
    productsLimit: 100000,
  },
];

export enum VariantsSetupTypes {
  ProductPerVariant = 0,
  VariantsAsProductChild = 1,
}

export interface IVariantSetupOption {
  title: string;
  value: VariantsSetupTypes;
}

export const variantsSetupOptionsArray: IVariantSetupOption[] = [
  {
    title: 'Find competitors for parent products only',
    value: VariantsSetupTypes.VariantsAsProductChild,
  },
  {
    title: 'Find competitors for all of the variants',
    value: VariantsSetupTypes.ProductPerVariant,
  },
];

<pm-ep-dialog [size]="dialogMaxWidth"
              [(shouldShow)]="shouldShow"
              [title]="title"
              [isPricingStrategy]="isPricingStrategy"
              [isPricingDiscount]="isPricingDiscount"
              [footerTemplate]="formDialogFooterTemplate"
              [isBackDropStatic]="isBackDropStatic"
              (dialogClose)="onDialogClose()">
  <ng-content></ng-content>
</pm-ep-dialog>

<ng-template #formDialogFooterTemplate>
  <div class="flex-buttons-wrapper">
    <button mat-button
            type="submit"
            class="highlighted"
            [disabled]="sendButtonDisabled"
            [hidden]="sendButtonHidden">
      {{submitBtnTitle}}
    </button>

    <button mat-button
            type="button"
            (click)="onDialogCancel()">
      {{cancelBtnTitle}}
    </button>
  </div>
</ng-template>

<form [formGroup]="competitorForm" (ngSubmit)="onFormSubmit()" novalidate>
  <pm-ep-form-dialog [dialogMaxWidth]="450" class="dialog" [sendButtonDisabled]="isSubmitFormDisabled()"
    [title]="dialogTitle" [submitBtnTitle]="dialogSubmitBtnTitle" (dialogClose)="cancelSettingsDialog()"
    [shouldShow]="shouldShowDialog" (shouldShowChange)="onShouldShowDialogChange($event)">
    <ng-container *ngIf="isAdmin">
      <!-- <mat-form-field>
        <mat-label>Url</mat-label>
        <input type="text" formControlName="competitorUrl" placeholder="Add competitor" aria-label="Text" matInput
          [matAutocomplete]="auto">
        <mat-error *ngIf="hasAnyErrors('competitorUrl')">
          {{getErrorMessage('competitorUrl ', 'Competitor Url')}}
        </mat-error>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let competitor of filteredCompetitors | async" [value]="competitor.id">
            {{competitor.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field> -->
      <br />
      <mat-label>
        Price
      </mat-label>
      <div>
        <mat-form-field>
          <mat-label>Price Query Selector</mat-label>
          <input type="text" formControlName="priceQuerySelector" placeholder="Add Price Query Selector" aria-label="Text"
            matInput>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Price Data Property</mat-label>
          <input type="text" formControlName="priceDataProperty" placeholder="Add Price Data Property" aria-label="Text"
            matInput>
        </mat-form-field>
      </div>
      <br />

      <mat-label>
        Currency
      </mat-label>
      <div>
        <mat-form-field>
          <mat-label>Default Currency Code</mat-label>
          <input type="text" formControlName="defaultCurrencyCode" placeholder="Set Default Currency Code"
            aria-label="Text" matInput>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Price Currency Selector</mat-label>
          <input type="text" formControlName="priceCurrencyQuerySelector" placeholder="Add Price Currency Query Selector"
            aria-label="Text" matInput>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Price Currency Data Property</mat-label>
          <input type="text" formControlName="priceCurrencyDataProperty" placeholder="Add Price Currency Data Property"
            aria-label="Text" matInput>
        </mat-form-field>
      </div>
      <!-- Add this section below the "Price" section -->
      <mat-label>
        Image Url
      </mat-label>
      <div>
        <mat-form-field>
          <mat-label>Image Url Query Selector</mat-label>
          <input type="text" formControlName="imageUrlQuerySelector" placeholder="Add Image Url Query Selector"
            aria-label="Text" matInput>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Image Url Data Property</mat-label>
          <input type="text" formControlName="imageUrlDataProperty" placeholder="Add Image Url Data Property"
            aria-label="Text" matInput>
        </mat-form-field>
      </div>

      <mat-label>
        Title
      </mat-label>
      <div>
        <mat-form-field>
          <mat-label>Title Query Selector</mat-label>
          <input type="text" formControlName="titleQuerySelector" placeholder="Add Title Query Selector" aria-label="Text"
            matInput>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Title Data Property</mat-label>
          <input type="text" formControlName="titleDataProperty" placeholder="Add Title Data Property" aria-label="Text"
            matInput>
        </mat-form-field>
      </div>

      <mat-label>
        Unit
      </mat-label>
      <div>
        <mat-form-field>
          <mat-label>Unit Query Selector</mat-label>
          <input type="text" formControlName="unitQuerySelector" placeholder="Add Unit Query Selector" aria-label="Text"
            matInput>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Unit Data Property</mat-label>
          <input type="text" formControlName="unitDataProperty" placeholder="Add Unit Data Property" aria-label="Text"
            matInput>
        </mat-form-field>
      </div>

      <mat-label>
        Unit Price
      </mat-label>
      <div>
        <mat-form-field>
          <mat-label>Unit Price Query Selector</mat-label>
          <input type="text" formControlName="unitPriceQuerySelector" placeholder="Add Unit Price Query Selector"
            aria-label="Text" matInput>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Unit Price Data Property</mat-label>
          <input type="text" formControlName="unitPriceDataProperty" placeholder="Add Unit Price Data Property"
            aria-label="Text" matInput>
        </mat-form-field>
      </div>


      <mat-label>
        Out of Stock - Check for visibility
      </mat-label>
      <div>
        <mat-form-field>
          <mat-label> Search for OOS Div - if visible it's <b>Out Of Stock</b></mat-label>
          <input type="text" formControlName="ooStockSelector"
            placeholder="Search for OOS Div - if visible it's  Out Of Stock" aria-label="Text" matInput>
        </mat-form-field>
      </div>

      <mat-label>
        In Stock - Check for visibility
      </mat-label>
      <div>
        <mat-form-field>
          <mat-label> Search for Stock Div - if visible it's <b>In Stock</b></mat-label>
          <input type="text" formControlName="inStockSelector"
            placeholder="Search for Stock Div - if visible it's In Stock" aria-label="Text" matInput>
        </mat-form-field>
      </div>

      <mat-label>
        Shipping
      </mat-label>
      <div>
        <mat-form-field>
          <mat-label>Shipping Query Selector</mat-label>
          <input type="text" formControlName="shippingQuerySelector" placeholder="Add Shipping Query Selector"
            aria-label="Text" matInput>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Shipping Data Property</mat-label> 
          <input type="text" formControlName="shippingDataProperty" placeholder="Add Shipping Data Property"
            aria-label="Text" matInput>
        </mat-form-field>
      </div>

      <!-- Default Shipping Price -->
      <mat-form-field>
        <mat-label>Default Shipping Price</mat-label>
        <input matInput type="number" formControlName="defaultShippingPrice" />
        <mat-error *ngIf="hasAnyErrors('defaultShippingPrice')">
          {{getErrorMessage('defaultShippingPrice', 'Default Shipping Price')}}
        </mat-error>
      </mat-form-field>

      <!-- Free Shipping Above -->
      <mat-form-field>
        <mat-label>Free Shipping Above</mat-label>
        <input matInput type="number" formControlName="freeShippingAbove" />
        <mat-error *ngIf="hasAnyErrors('freeShippingAbove')">
          {{getErrorMessage('freeShippingAbove', 'Free Shipping Above')}}
        </mat-error>
      </mat-form-field>

      <div>
        <mat-label>
          Data Collector Options
        </mat-label>

        <mat-form-field appearance="outline">
          <mat-select id="proxyType" disableOptionCentering (selectionChange)="onSelectedProxyValueChange($event)"
            [value]="selectedProxy">
            <mat-option *ngFor="let proxy of proxies" [value]="proxy">{{proxy.displayName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- Add the new form fields after the existing fields -->
      </div>

      <br />
      <div>
        <mat-label>Should use data collector</mat-label>
        <mat-checkbox class="margin-right--m" id="shouldUseDataCollector" checked="shouldUseDataCollector.checked"
          formControlName="shouldUseDataCollector" color="primary">
        </mat-checkbox>
        <br />
        <br />
        <div>
          <mat-label>Disable Interception</mat-label>
          <mat-checkbox formControlName="disableInterception" color="primary">
          </mat-checkbox>
        </div>
        <br />
        <div>
          <mat-form-field>
            <mat-label>Wait Until</mat-label>
            <input type="text" formControlName="waitUntil" placeholder="Add Wait Until" aria-label="Text" matInput>
          </mat-form-field>
        </div>
        <br />
        <div>
          <mat-form-field>
            <mat-label>Wait For Selector</mat-label>
            <input type="text" formControlName="waitForSelector" placeholder="Add Wait For Selector" aria-label="Text"
              matInput>
          </mat-form-field>
        </div>
      </div>

      <!-- Add this code in the appropriate place within your form -->
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>Number of Retries</mat-label>
        <input matInput type="number" formControlName="numberOfRetries" />
      </mat-form-field>


      <br />
    </ng-container>

    <div class="gw-padding-top-10">
      <mat-checkbox class="margin-right--m"
        type="checkbox"
        style="transform: scale(1.15);"
        formControlName="isEnableTaxInclusion"
        (change)="onEnableTaxInclusion($event)">
      </mat-checkbox>
      <b class="margin-right--m">Adjust Tax by</b>
      <mat-form-field class="margin-right--m mat-select-small">
        <mat-select matInput formControlName="taxInclusionActionMarginType">
          <mat-option *ngFor="let actionMargin of taxInclusionActionMarginTypes" [value]="actionMargin">
            {{getActionMarginView(actionMargin)}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="cost-size input-field margin-right--m">
        <input matInput type="number" min="0" max="100" formControlName="taxInclusionMargin">
      </mat-form-field>
      <mat-form-field class="mat-select-small">
        <mat-select matInput formControlName="taxInclusionMarginType">
          <mat-option *ngFor="let margin of marginTypes" [value]="margin">
            {{getMarginView(margin)}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </pm-ep-form-dialog>
</form>

<pm-ep-yes-no-dialog [title]="ConfirmDiscardChanges" (dialogConfirm)="onCancelYesNoDialog()"
  (dialogCancel)="onConfirmYesNoDialog()" [(shouldShow)]="shouldShowSubmitDialogText">
  {{ConfirmDiscardChangesMessage}}</pm-ep-yes-no-dialog>
import {Component, OnDestroy, OnInit, Inject, Input} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BILLING, LOGOUT } from '../../../common/constants';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SessionService } from 'app/common/services/session.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/common/services/authentication.service';

@Component({
  selector: 'ngx-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isProductSyncingNavbar = false;

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  currentTheme = 'default';

  userMenu = [
    {
      title: BILLING,
      data: BILLING,
      icon :  'credit-card-outline',
    },
    {
       title: LOGOUT,
       data: LOGOUT,
       icon : 'log-out-outline',
    },
  ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private breakpointService: NbMediaBreakpointsService,
              private router: Router,
              public auth: AuthenticationService,
              public sessionService: SessionService,
              @Inject(DOCUMENT) private doc: Document) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({name}) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);


    this.sessionService.userDataLoaderEventEmitter.subscribe(onChange => {
      const currentTenantName = this.sessionService.currentTenant?.name;

      if (currentTenantName) {
        this.userMenu.unshift({
          title: currentTenantName,
          data: currentTenantName,
          icon: null,
        });
      }

      this.user = this.sessionService.currentUser;
    });

    this.menuService.onItemClick().subscribe((event) => {
      if (event.item.data === LOGOUT) {
        this.auth.logout();
      } else if (event.item.data === BILLING) {
        this.router.navigateByUrl('pages/billing');
      }
    });

    this.iniSidebarCloseEventSubscription();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): void {
    this.sidebarService.toggle(true, 'menu-sidebar');
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  private iniSidebarCloseEventSubscription(): void {
    this.sessionService.menuSidebarCompactEvent$.subscribe(() => {
      this.sidebarService.compact('menu-sidebar');
    });
  }
}

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'sync-products-limit-reached-modal',
  templateUrl: './sync-products-limit-reached-modal.component.html',
  styleUrls: ['./sync-products-limit-reached-modal.component.scss'],
})
export class SyncProductsLimitReachedModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public modalData: { syncedProductsAmount: number, allProductsAmount: number },
              public matDialogRef: MatDialogRef<SyncProductsLimitReachedModalComponent>,
              private router: Router,
  ) { }

  navigateToBilling(): void {
    this.router.navigateByUrl('/pages/billing').then(() => {
      this.matDialogRef.close();
    });
  }

}

<div>
  <form [formGroup]="syncFormGroup">
    <div class="gw-padding-top-5">
      <div *ngIf="shouldShowMerchantList">
        <mat-form-field [class.w-100]="isFullWidth" appearance="outline">
          <mat-label>Select Google Merchant Account</mat-label>
          <mat-select
            required
            formControlName="merchantAccountSelect"
            [(ngModel)]="selectedMerchantAccount"
            (ngModelChange)="onSelectedMerchantAccountChange($event)">
            <mat-option *ngFor="let item of merchantAccounts" [value]="item">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field [class.w-100]="isFullWidth" appearance="outline">
          <mat-label>Select the target sync group</mat-label>
          <mat-select required formControlName="syncEntitiesSelect" [(ngModel)]="selectedSyncEntity" (ngModelChange)="onSelectedSyncEntityChange()">
            <mat-option *ngFor="let item of syncEntities" [value]="item">
              {{item.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="shouldShowEntityNameInput">
        <mat-form-field appearance="outline">
          <mat-label>{{syncEntityLabel}}</mat-label>
          <input id="targetSyncEntityName" matInput formControlName="targetSyncEntityName" type="text"/>
        </mat-form-field>
      </div>

      <div *ngIf="shouldShowCollectionList">
        <mat-form-field [class.w-100]="isFullWidth" appearance="outline">
          <mat-select required disableOptionCentering [(ngModel)]="selectedCollection" [ngModelOptions]="{standalone: true}"
                      (selectionChange)="onSelectedCollectionChange($event)">
            <mat-option>
              <ngx-mat-select-search formControlName="collectionsSelectFilterCtrl" placeholderLabel="Find collection..."
                                     noEntriesFoundLabel="No collection was found!">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let collection of filteredCollections | async" [value]="collection" >{{collection.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div [hidden]="!isAdmin">
          <mat-checkbox formControlName="addToActiveProducts">Should Add to Active Products by Collection List</mat-checkbox>
        </div>
      </div>

      <div *ngIf="shouldShowCategoryList">
        <mat-form-field [class.w-100]="isFullWidth" appearance="outline">
          <mat-select required disableOptionCentering [(ngModel)]="selectedCategory" [ngModelOptions]="{standalone: true}"
                      (selectionChange)="onSelectedCategoryChange($event)">
            <mat-option>
              <ngx-mat-select-search formControlName="categoriesSelectFilterCtrl" placeholderLabel="Find category..."
                                     noEntriesFoundLabel="No category was found!">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let category of filteredCategories | async" [value]="category" >{{category.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div [hidden]="!isAdmin">
          <mat-checkbox formControlName="addToActiveProducts">Should Add to Active Products by Category List</mat-checkbox>
        </div>
      </div>

      <div class="submit-button" [hidden]="hideSyncButton">
        <button *ngIf="!isWooIntegrationType" mat-raised-button type="submit" color="primary" (click)="syncCollectionSubmit()" [disabled]="hideSyncButton">3. Sync Collection
        </button>
        <button *ngIf="isWooIntegrationType" mat-raised-button type="submit" color="primary" (click)="syncCategorySubmit()" [disabled]="hideSyncButton">3. Sync Category
        </button>
      </div>

    </div>
  </form>
</div>

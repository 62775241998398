<form [formGroup]="addCompetitorForm" (ngSubmit)="onFormSubmit()" novalidate>
  <div class="filters-new-design">
    <mat-form-field class="filter-field url-field">
      <mat-label>Add Competitor Link</mat-label>
      <input matInput type="text" formControlName="competitorUrl"
             class="elipsis"
             placeholder="https://competitor.com/product/">
      <button mat-button matSuffix
              [disabled]="addCompetitorForm.invalid || isDemoPlatform">
        Add
      </button>
    </mat-form-field>
  </div>

  <div *ngIf="shouldShowTable" class="urs-table-wrapper">
    <table mat-table [dataSource]="_dataSource" matSort>

      <!-- Name Column -->
      <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="60%">Links</th>
        <td mat-cell *matCellDef="let element" width="60%">
          <div class="link-td-wrapper">
            <div class="image-wrapper"
                 (click)="copyCompetitorURL(element.url)">
              <img src="/assets/images/icons/link_gray.svg" alt="copy link">
            </div>

            <a href="{{element.url}}" target="_blank">{{element.url | truncate: 50}}</a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="30%"> Status </th>
        <td mat-cell *matCellDef="let element" width="30%">
          <div *ngIf="element.status != null;else show_status_loading" class="with-icon"
              [ngClass]="{'failed': element.status.toLowerCase() == 'failed', 'success': element.status.toLowerCase() == 'success', 'in-review': element.status.toLowerCase() == 'in review'}">
            <ng-container *ngIf="element.status.toLowerCase() == 'failed'">
              <mat-icon style="margin-right: 5px;">cancel</mat-icon> Failed
            </ng-container>
            <ng-container *ngIf="element.status.toLowerCase() == 'success'">
              <mat-icon style="margin-right: 5px;">check_circle</mat-icon> Success
            </ng-container>
            <ng-container *ngIf="element.status.toLowerCase() == 'in review'">
              <mat-icon style="margin-right: 5px;">warning</mat-icon> In Review
            </ng-container>
          </div>
          <ng-template #show_status_loading>
            <div>
              <mat-spinner [diameter]="30"></mat-spinner>
            </div>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th class="delete-th" mat-header-cell *matHeaderCellDef mat-sort-header width="10%"></th>
        <td mat-cell *matCellDef="let element" (click)="onDeleteRow(element)" width="10%">
          <img class="clickable delete-icon" src="assets/images/icons/delete-trash_gray.svg" alt="Delete icon">
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns" class="table-head-row"></tr>
      <tr mat-row *matRowDef="let row; columns:columns;" class="table-body-row"></tr>
    </table>

    <table>
      <tr *ngIf="isLoading">
        <td style="text-align: center;padding: 10px;" [attr.colspan]="columns.length">
          Loading, please wait...
        </td>
      </tr>
      <tr *ngIf="(_dataSource && _dataSource.data.length <= 0) && !isLoading">
        <td style="text-align: center;padding: 10px;" [attr.colspan]="columns.length">
          No urls found.
        </td>
      </tr>
    </table>

  </div>

</form>

<delete-competitor-url-dialog [shouldShowDialog]="shouldShowDeleteDialog"
  [targetCompetitorUrl]="competitorUrlForDeletion" (onDialogClose)="deleteCompetitorUrlDialogClose()"
  (onFinishEvent)="onDeleteSuccess()">
</delete-competitor-url-dialog>

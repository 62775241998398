import { Injectable } from '@angular/core';
import { GwLocalStorageService, StorageDict } from './gw-local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TableColumnLocalStorageService {
  constructor(private localStorageService: GwLocalStorageService) {}

  verifyAndCleanColumns(storageKey: StorageDict, supportedColumns: string[]): void {
    const storedColumnsJson = this.localStorageService.get(storageKey);
    if (!storedColumnsJson) {
      return;
    }

    const storedColumns: string[] = JSON.parse(storedColumnsJson);
    const unsupportedColumns = storedColumns.filter(
      (col) => !supportedColumns.includes(col)
    );

    if (unsupportedColumns.length > 0) {
      // Clean the storage by removing unsupported columns or resetting
      this.localStorageService.remove(storageKey);
    }
  }

  getColumns(storageKey: StorageDict): any {
    const columnsJson = this.localStorageService.get(storageKey);
    return columnsJson ? JSON.parse(columnsJson) : [];
  }

  setColumns(storageKey: StorageDict, columns: string[]): void {
    this.localStorageService.set(storageKey, JSON.stringify(columns));
  }
}
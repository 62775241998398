import {Component, Input, OnChanges} from '@angular/core';

@Component({
  selector: 'items-skeleton',
  templateUrl: './items-skeleton.component.html',
  styleUrls: ['./items-skeleton.component.scss'],
})
export class ItemsSkeletonComponent implements OnChanges {
  @Input() skeletonRowsAmount: number = 1;

  productsSkeletonEmptyItemsList: number[];

  constructor() { }

  ngOnChanges(): void {
    this.productsSkeletonEmptyItemsList = new Array(this.skeletonRowsAmount);
  }

}

import { StorageDict } from "./gw-local-storage.service";

export class MemoryStorage {
    private data: {}
    get<TValue extends string>(name: StorageDict, defaultValue: TValue = null): TValue {
      if (!name) { return defaultValue; }
      return this.data[name] || defaultValue;
    }
    set<TValue extends string>(name: StorageDict, value: TValue): boolean {
      if (!name) { return false; }
      this.data[name] = value;
      return true;
    }
    remove(name: StorageDict): string {
      if (!name) { return null; }
      const itemToRemove = this.get(name);
      delete this.data[name];
      return itemToRemove;
    }
    clear(): void {
      this.data = {};
    }
  }
  
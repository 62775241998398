<div class="modal-wrapper">
  <mat-dialog-content>
    <img class="logo" src="/assets/images/intelis-triangle.svg" alt="Intelis logo">
    <h4>🎉Great Start!</h4>
    <p class="text-secondary text-center mb-16">Your first {{modalData?.syncedProductsAmount}} products are now connected and ready for pricing insights!</p>

    <div class="progress-wrapper">
      <nb-progress-bar class="product-sync-progress-bar" status="warning"
                       [value]="20"
                       [displayValue]="false"
                       [size]="'tiny'">
      </nb-progress-bar>

      <p class="sync-amount" (click)="navigateToBilling()">
        {{modalData?.syncedProductsAmount}}/{{modalData?.allProductsAmount}} of your products have been synced
      </p>
    </div>

    <img class="circle" src="/assets/images/circle-checkmark.png" alt="Circle icon">
    <p class="description">
      You’re currently at {{modalData?.syncedProductsAmount}}/{{modalData?.allProductsAmount}}
      – upgrade now to add the rest and make the
      most of our pricing strategies for your entire inventory.
    </p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button class="highlighted" (click)="navigateToBilling()">Sync All</button>
    <button (click)="matDialogRef.close(false)">Not now</button>
  </mat-dialog-actions>
</div>

export enum CategoryTypesEnum {
  Electronics,
  Fashion,
  PackagedGoods,
  HomeDecor,
  Beauty,
  Pets,
  Accessories,
  Sports,
  Gifts,
  Jewelry,
  Food,
  Other,
  Books,
}

export interface ICategory {
  title: string;
  value: CategoryTypesEnum;
}

export const categoriesArray: ICategory[] = [
  {title: 'Consumer Electronics', value: CategoryTypesEnum.Electronics},
  {title: 'Fashion / Apparel', value: CategoryTypesEnum.Fashion},
  {title: 'Packaged Goods', value: CategoryTypesEnum.PackagedGoods},
  {title: 'Home Decor', value: CategoryTypesEnum.HomeDecor},
  {title: 'Beauty / Care', value: CategoryTypesEnum.Beauty},
  {title: 'Pets', value: CategoryTypesEnum.Pets},
  {title: 'Accessories', value: CategoryTypesEnum.Accessories},
  {title: 'Sports', value: CategoryTypesEnum.Sports},
  {title: 'Gifts', value: CategoryTypesEnum.Gifts},
  {title: 'Luxury Goods & Jewlry', value: CategoryTypesEnum.Jewelry},
  {title: 'Food & Beverages', value: CategoryTypesEnum.Food},
  {title: 'Books', value: CategoryTypesEnum.Books},
  {title: 'Other', value: CategoryTypesEnum.Other},
]

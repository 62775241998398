<div class="header-wrapper">
  <products-limit-navbar *ngIf="isProductSyncingNavbar"></products-limit-navbar>

  <header>
    <div class="header-container">
      <div class="logo-container">
        <div (click)="toggleSidebar()" class="sidebar-toggle">
          <nb-icon icon="menu-2-outline"></nb-icon>
        </div>
        <div class="logo" (click)="navigateHome()">
          <img class="app-logo" src="/assets/images/intelis_logo.svg" alt="Logo"/>
        </div>
      </div>
    </div>
    <div class="header-container">
      <nb-actions size="small">
        <nb-action class="user-action" *nbIsGranted="['view', 'user']">
          <div *ngIf="user != null">
            <nb-user [nbContextMenu]="userMenu" nbContextMenuTrigger="hover" [onlyPicture]="userPictureOnly"
                     [name]="user?.name" [picture]="user?.picture">
            </nb-user>
          </div>
        </nb-action>
      </nb-actions>
    </div>
  </header>
</div>

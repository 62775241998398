import {Component, Input} from '@angular/core';

@Component({
  selector: 'price-slider',
  templateUrl: './price-slider.component.html',
  styleUrls: ['./price-slider.component.scss'],
})
export class PriceSliderComponent {
  @Input() size: {height: string, width: string};
  @Input() valueNumber: number = 0;

  constructor() { }
}

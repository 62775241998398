import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MarginActionType } from 'app/common/enums/action-margin-type.enum';
import { PricingMarginType } from 'app/common/enums/pricing-margin-type.enum';
import { ICompetitor } from 'app/common/interfaces/ICompetitor';
import { SessionService } from 'app/common/services/session.service';

const AddNewSalesChannelTitle = "Create new sales channel";
const EditSalesChannelTitle = "Edit Sales Channel:";
const AddNewSalesChannel = "Add Sales Channel";
const SaveChanges = "Save changes";

@Component({
  selector: 'sales-channel-dialog',
  templateUrl: './sales-channel-dialog.component.html',
  styleUrls: ['./sales-channel-dialog.component.scss']
})
export class SalesChannelDialogComponent implements OnInit {
  @Output() shouldShowSalesChannelDialogChange: EventEmitter<boolean>;
  @Output() notifyUpdateSalesChannel: EventEmitter<ICompetitor>;
  @Input() salesChannelForEdit: ICompetitor;
  @Input() shouldShowSalesChannelDialog: boolean;
  dialogTitle: string;
  dialogSubmitBtnTitle: string;
  ConfirmDiscardChanges = "Discard Changes";
  ConfirmDiscardChangesMessage =
    "Your changes will be lost, do you want to proceed?";
  dialogSubmitCancelEvent = false;
  currentSalesChannel: ICompetitor;
  salesChannelForm = new FormGroup({
    competitorId: new FormControl("0"),
    shouldAddNewCompetitorUrls: new FormControl(false),
    shouldEnrichResults: new FormControl(false),
    allowGlobalStores: new FormControl(false),
    activateForNoCompetitionOnly: new FormControl(false),
    isEnableTaxInclusion: new FormControl(null),
    taxInclusionActionMarginType: new FormControl(null),
    taxInclusionMargin: new FormControl(null),
    taxInclusionMarginType: new FormControl(null)
  });
  shouldShowSubmitDialogText = false;
  taxInclusionActionMarginTypes: MarginActionType[] = [MarginActionType.Increase, MarginActionType.Decrease];
  marginTypes: PricingMarginType[] = [PricingMarginType.Percentage];
  isTaxInclusionDisabled: boolean;

  constructor(private sessionService: SessionService) {
    this.shouldShowSalesChannelDialog = false;
    this.shouldShowSalesChannelDialogChange = new EventEmitter<boolean>();
    this.notifyUpdateSalesChannel = new EventEmitter<ICompetitor>();
    this.dialogTitle = AddNewSalesChannelTitle;
    this.dialogSubmitBtnTitle = AddNewSalesChannel;
  }

  ngOnInit(): void {
  }

  public setSalesChannelForEdit(salesChannel: ICompetitor): void {
    this.setEditMetaData();
    this.currentSalesChannel = salesChannel;

    this.salesChannelForm.patchValue({
      competitorId: salesChannel.id,
      shouldAddNewCompetitorUrls: salesChannel?.shouldAddNewCompetitorUrls ?? false,
      shouldEnrichResults: salesChannel?.shouldEnrichResults ?? false,
      allowGlobalStores: salesChannel?.allowGlobalStores ?? false,
      activateForNoCompetitionOnly: salesChannel?.activateForNoCompetitionOnly ?? false
    });
    this.onEnableTaxInclusion(salesChannel?.taxInclusion?.margin != null);
  }

  setEditMetaData(): void {
    this.dialogTitle = EditSalesChannelTitle;
    this.dialogSubmitBtnTitle = SaveChanges;
  }

  onShouldShowDialogChange(value: boolean): void {
    this.shouldShowSalesChannelDialog = value;
    this.shouldShowSalesChannelDialogChange.emit(value);
  }

  cancelSettingsDialog(): void {
    if (!this.isChanges()) {
      this.cleanForm();
    } else {
      this.shouldShowSubmitDialogText = true;
    }
  }

  isChanges(): boolean {
    return !this.salesChannelForm.pristine;
  }

  cleanForm(): void {
    this.salesChannelForm.reset();
    this.dialogTitle = AddNewSalesChannelTitle;
    this.dialogSubmitBtnTitle = AddNewSalesChannel;
  }

  onCancelYesNoDialog(): void {
    this.cleanForm();
  }

  onConfirmYesNoDialog(): void {
    this.onShouldShowDialogChange(true);
  }

  onFormSubmit(): void {
    let salesChannelToUpdate = this.getSalesChannelUpdate();
    this.notifyUpdateSalesChannel.emit(salesChannelToUpdate);
    this.cleanForm();
    this.markFormAsPristine();
    this.onShouldShowDialogChange(false);
  }

  getSalesChannelUpdate(): ICompetitor {
    let salesChannel: ICompetitor = {
      id: this.currentCompetitorId,
      shouldAddNewCompetitorUrls: this.currentShouldAddNewCompetitorUrls,
      shouldEnrichResults: this.currentShouldEnrichResults,
      allowGlobalStores: this.currentAllowGlobalStores,
      activateForNoCompetitionOnly: this.currentActivateForNoCompetitionOnly,
      taxInclusion: this.isTaxInclusionDisabled ? null : {
        actionType: this.currentTaxInclusionActionMarginType,
        margin: this.currentTaxInclusionMargin,
        marginType: this.currentTaxInclusionMarginType
      }
    };
    return salesChannel;
  }

  isSubmitFormDisabled(): boolean {
    return !this.salesChannelForm.valid;
  }

  get currentCompetitorId(): string {
    return this.salesChannelForm.controls.competitorId.value;
  }

  get currentShouldAddNewCompetitorUrls(): boolean {
    return this.salesChannelForm.controls?.shouldAddNewCompetitorUrls?.value ?? false;
  }

  get currentShouldEnrichResults(): boolean {
    return this.salesChannelForm.controls?.shouldEnrichResults?.value ?? false;
  }

  get currentAllowGlobalStores(): boolean {
    return this.salesChannelForm.controls?.allowGlobalStores?.value ?? false;
  }

  get currentActivateForNoCompetitionOnly(): boolean {
    return this.salesChannelForm.controls?.activateForNoCompetitionOnly?.value ?? false;
  }

  get currentTaxInclusionActionMarginType() {
    return this.salesChannelForm.controls?.taxInclusionActionMarginType?.value ?? null;
  }

  get currentTaxInclusionMargin() {
    return this.salesChannelForm.controls?.taxInclusionMargin?.value ?? null;
  }

  get currentTaxInclusionMarginType() {
    return this.salesChannelForm.controls?.taxInclusionMarginType?.value ?? null;
  }

  markFormAsPristine(): void {
    this.salesChannelForm.markAsPristine();
  }

  get isAdmin(): boolean {
    return this.sessionService.isAdmin;
  }

  getActionMarginView(actionMarginMarginType: MarginActionType): string {
    return this.sessionService.getActionMarginView(actionMarginMarginType);
  }

  getMarginView(pricingMarginType: PricingMarginType): string {
    return this.sessionService.getMarginView(pricingMarginType);
  }

  onEnableTaxInclusion(event: any) {
    let eventValue = event.checked != null ? event.checked : event;
    if (eventValue == true) {
      this.isTaxInclusionDisabled = false;
      this.salesChannelForm.patchValue({
        taxInclusionActionMarginType: this.currentSalesChannel?.taxInclusion?.actionType ?? MarginActionType.Increase,
        taxInclusionMargin: this.currentSalesChannel?.taxInclusion?.margin ?? 15,
        taxInclusionMarginType: this.currentSalesChannel?.taxInclusion?.marginType ?? PricingMarginType.Percentage
      });
      this.salesChannelForm.controls?.taxInclusionActionMarginType?.enable();
      this.salesChannelForm.controls?.taxInclusionMargin?.enable();
      this.salesChannelForm.controls?.taxInclusionMarginType?.enable();
    } else {
      this.isTaxInclusionDisabled = true;
      this.salesChannelForm.patchValue({
        taxInclusionActionMarginType: null,
        taxInclusionMargin: null,
        taxInclusionMarginType: null
      });
      this.salesChannelForm.controls?.taxInclusionActionMarginType?.disable();
      this.salesChannelForm.controls?.taxInclusionMargin?.disable();
      this.salesChannelForm.controls?.taxInclusionMarginType?.disable();
    }
    this.salesChannelForm.patchValue({
      isEnableTaxInclusion: !this.isTaxInclusionDisabled
    });
  }
}

import { NgModule } from '@angular/core';
import { TruncatePipe } from './truncate.pipe';
import { FilterByKeyPipe } from './filterByKey.pipe';

@NgModule({
  declarations: [
    TruncatePipe,
    FilterByKeyPipe,
  ],
  imports: [],
  exports: [
    TruncatePipe,
    FilterByKeyPipe,
  ],
})
export class PipesModule {
}

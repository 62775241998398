import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {ConfirmModalComponent} from './confirm-modal.component';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';

@NgModule({
  declarations: [ConfirmModalComponent],
  imports: [
    MatDialogModule,
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
  providers: [],
})

export class ConfirmModalModule {}

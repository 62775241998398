import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { MarginActionType } from "app/common/enums/action-margin-type.enum";
import { PricingMarginType } from "app/common/enums/pricing-margin-type.enum";
import { GwValidators } from "app/common/helpers/gw-validators.helper";
import { ICategory } from "app/common/interfaces/ICategory";
import { ICompetitor } from "app/common/interfaces/ICompetitor";
import { SessionService } from "app/common/services/session.service";
import { Observable, Subscription } from "rxjs";

const UrlReg = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
const MustBeLessThenCharacters = "Must be last then #MaxLength#";
const AddNewCompetitorTitle = "Create new competitor";
const EditProductTitle = "Edit Competitor:";
const AddNewCompetitor = "Add Competitor";
const SaveChanges = "Save changes";

const ooStockAttribute = "isAvailable";
const inStockAttribute = "inStock";

@Component({
  selector: "competitor-dialog",
  templateUrl: "./competitor-dialog.component.html",
  styleUrls: ["./competitor-dialog.component.scss"],
})
export class CompetitorDialogComponent implements OnInit {
  @Output() shouldShowDialogChange: EventEmitter<boolean>;
  @Output() notifyUpdateProduct: EventEmitter<ICompetitor>;
  @Output() getDistributionList: EventEmitter<void>;
  @Input() competitors: ICompetitor[];
  @Input() competitorForEdit: ICompetitor;
  @Input() listsNames: Set<string>;
  @Input() editMode: boolean;
  @Input() shouldShowDialog: boolean;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  dialogTitle: string;
  dialogSubmitBtnTitle: string;
  ConfirmDiscardChanges = "Discard Changes";
  ConfirmDiscardChangesMessage =
    "Your changes will be lost, do you want to proceed?";
  dialogSubmitCancelEvent = false;
  currentCompetitor: ICompetitor;
  categories: ICategory[] = [];
  filteredCategories: Observable<string[]>;
  filteredCompetitors: Observable<ICompetitor[]>;

  competitorForm = new FormGroup({
    competitorId: new FormControl("0"),
    //competitorUrl: new FormControl('', Validators.pattern(UrlReg)),
    priceQuerySelector: new FormControl(""),
    priceDataProperty: new FormControl(""),
    priceCurrencyQuerySelector: new FormControl(""),
    priceCurrencyDataProperty: new FormControl(""),
    titleQuerySelector: new FormControl(""),
    titleDataProperty: new FormControl(""),
    unitQuerySelector: new FormControl(""),
    unitDataProperty: new FormControl(""),
    unitPriceQuerySelector: new FormControl(""),
    unitPriceDataProperty: new FormControl(""),
    defaultCurrencyCode: new FormControl(""),
    shouldUseDataCollector: new FormControl(""),
    proxyType: new FormControl(""),
    inStockSelector: new FormControl(""),
    ooStockSelector: new FormControl(""), //out of stock
    disableInterception: new FormControl(false),
    waitUntil: new FormControl(""),
    waitForSelector: new FormControl(""),
    imageUrlQuerySelector: new FormControl(""),
    imageUrlDataProperty: new FormControl(""),
    defaultShippingPrice: new FormControl(""),
    freeShippingAbove: new FormControl(""),
    shippingQuerySelector: new FormControl(""),
    shippingDataProperty: new FormControl(""),
    numberOfRetries: new FormControl(""),
    isEnableTaxInclusion: new FormControl(null),
    taxInclusionActionMarginType: new FormControl(null),
    taxInclusionMargin: new FormControl(null),
    taxInclusionMarginType: new FormControl(null)
  });

  shouldShowSubmitDialogText = false;
  distributionListId: number;
  recipientsDefaultValue: number[];
  originListName: string;
  zeroMembers = false;
  getAppErrorSubscription: Subscription;

  proxies: any = [
    {
      displayName: "Static",
      value: 0,
    },
    {
      displayName: "Residential",
      value: 1,
    },
    {
      displayName: "Unblocker",
      value: 2,
    },
  ];

  selectedProxy = this.proxies[0];
  taxInclusionActionMarginTypes: MarginActionType[] = [MarginActionType.Increase, MarginActionType.Decrease];
  marginTypes: PricingMarginType[] = [PricingMarginType.Percentage];
  isTaxInclusionDisabled: boolean;

  constructor(private sessionService: SessionService) {
    this.shouldShowDialog = false;
    this.shouldShowDialogChange = new EventEmitter<boolean>();
    this.notifyUpdateProduct = new EventEmitter<ICompetitor>();
    this.getDistributionList = new EventEmitter<void>();
    this.dialogTitle = AddNewCompetitorTitle;
    this.dialogSubmitBtnTitle = AddNewCompetitor;
  }

  ngOnInit(): void {}

  public setCompetitorForEdit(competitor: ICompetitor): void {
    this.setEditMetaData();
    this.currentCompetitor = competitor;

    let priceAttributeElement =
      competitor?.competitorUrlDataScheme?.attributeElements?.find(
        (x) => x.attributeName == "price"
      );
    let priceCurrencyAttributeElement =
      competitor?.competitorUrlDataScheme?.attributeElements?.find(
        (x) => x.attributeName == "priceCurrency"
      );
    this.selectedProxy = this.proxies.find(
      (item) => item.value == competitor.stealthModeType
    );

    let ooStockAttributeElement =
      competitor?.competitorUrlDataScheme?.attributeElements?.find(
        (x) => x.attributeName == ooStockAttribute
      );
    let inStockAttributeElement =
      competitor?.competitorUrlDataScheme?.attributeElements?.find(
        (x) => x.attributeName == inStockAttribute
      );

    let imageUrlAttributeElement =
      competitor?.competitorUrlDataScheme?.attributeElements?.find(
        (x) => x.attributeName == "imageUrl"
      );

    let shippingAttributeElement =
      competitor?.competitorUrlDataScheme?.attributeElements?.find(
        (x) => x.attributeName == "shippingPrice"
      );
    let titleAttributeElement =
      competitor?.competitorUrlDataScheme?.attributeElements?.find(
        (x) => x.attributeName == "title"
      );
    let unitAttributeElement =
      competitor?.competitorUrlDataScheme?.attributeElements?.find(
        (x) => x.attributeName == "unit"
      );
    let unitPriceAttributeElement =
      competitor?.competitorUrlDataScheme?.attributeElements?.find(
        (x) => x.attributeName == "unitPrice"
      );

    this.competitorForm.patchValue({
      competitorId: competitor.id,
      priceQuerySelector: priceAttributeElement?.querySelector ?? "",
      priceDataProperty: priceAttributeElement?.dataProperty ?? "",
      priceCurrencyQuerySelector:
        priceCurrencyAttributeElement?.querySelector ?? "",
      priceCurrencyDataProperty:
        priceCurrencyAttributeElement?.dataProperty ?? "",
      titleQuerySelector: titleAttributeElement?.querySelector ?? "",
      titleDataProperty: titleAttributeElement?.dataProperty ?? "",
      unitQuerySelector: unitAttributeElement?.querySelector ?? "",
      unitDataProperty: unitAttributeElement?.dataProperty ?? "",
      unitPriceQuerySelector: unitPriceAttributeElement?.querySelector ?? "",
      unitPriceDataProperty: unitPriceAttributeElement?.dataProperty ?? "",
      defaultCurrencyCode:
        competitor?.competitorUrlDataScheme?.defaultCurrencyCode ?? "",
      shouldUseDataCollector:
        competitor?.competitorUrlDataScheme?.shouldUseDataCollector ?? false, //check this or ad data collector
      proxyType: this.selectedProxy.value,
      ooStockSelector: ooStockAttributeElement?.querySelector ?? "",
      inStockSelector: inStockAttributeElement?.querySelector ?? "",
      disableInterception:
        competitor.competitorUrlDataScheme?.dataCollectorOptions
          ?.disableInterception ?? false,
      waitUntil:
        competitor.competitorUrlDataScheme?.dataCollectorOptions?.waitUntil ??
        "",
      waitForSelector:
        competitor.competitorUrlDataScheme?.dataCollectorOptions
          ?.waitForSelector ?? "",
      imageUrlQuerySelector: imageUrlAttributeElement?.querySelector ?? "",
      imageUrlDataProperty: imageUrlAttributeElement?.dataProperty ?? "",
      defaultShippingPrice:
        competitor?.competitorUrlDataScheme?.defaultShippingPrice ?? "",
      freeShippingAbove:
        competitor?.competitorUrlDataScheme?.freeShippingAbove ?? "",
      shippingQuerySelector: shippingAttributeElement?.querySelector ?? "",
      shippingDataProperty: shippingAttributeElement?.dataProperty ?? "",
      numberOfRetries:
        competitor?.competitorUrlDataScheme?.numberOfRetries ?? 3
    });
    this.onEnableTaxInclusion(competitor?.taxInclusion?.margin != null);
  }

  setEditMetaData(): void {
    this.dialogTitle = EditProductTitle;
    this.dialogSubmitBtnTitle = SaveChanges;
  }

  onShouldShowDialogChange(value: boolean): void {
    this.shouldShowDialog = value;
    this.shouldShowDialogChange.emit(value);
  }

  cancelSettingsDialog(): void {
    if (!this.isChanges()) {
      this.cleanForm();
    } else {
      this.shouldShowSubmitDialogText = true;
    }
  }

  isChanges(): boolean {
    return !this.competitorForm.pristine;
  }

  cleanForm(): void {
    this.competitorForm.reset();
    this.dialogTitle = AddNewCompetitorTitle;
    this.dialogSubmitBtnTitle = AddNewCompetitor;
  }

  onCancelYesNoDialog(): void {
    this.cleanForm();
  }

  onConfirmYesNoDialog(): void {
    this.onShouldShowDialogChange(true);
  }

  onFormSubmit(): void {
    let competitorToUpdate = this.getCompetitorUpdate();
    this.notifyUpdateProduct.emit(competitorToUpdate);
    this.cleanForm();
    this.markFormAsPristine();
    this.onShouldShowDialogChange(false);
  }

  getCompetitorUpdate(): ICompetitor {
    let attributeElements = [];
    if (
      this.currentPriceQuerySelector != null &&
      this.currentPriceQuerySelector != ""
    ) {
      attributeElements.push({
        querySelector: this.currentPriceQuerySelector,
        attributeName: "price",
        dataProperty: this.currentPriceDataProperty,
      });
    }

    if (
      this.currentTitleQuerySelector != null &&
      this.currentTitleQuerySelector != ""
    ) {
      attributeElements.push({
        querySelector: this.currentTitleQuerySelector,
        attributeName: "title",
        dataProperty: this.currentTitleDataProperty,
      });
    }

    if (
      this.currentUnitQuerySelector != null &&
      this.currentUnitQuerySelector != ""
    ) {
      attributeElements.push({
        querySelector: this.currentUnitQuerySelector,
        attributeName: "unit",
        dataProperty: this.currentUnitDataProperty,
      });
    }

    if (
      this.currentUnitPriceQuerySelector != null &&
      this.currentUnitPriceQuerySelector != ""
    ) {
      attributeElements.push({
        querySelector: this.currentUnitPriceQuerySelector,
        attributeName: "unitPrice",
        dataProperty: this.currentUnitPriceDataProperty,
      });
    }

    if (
      this.currentPriceCurrencyQuerySelector != null &&
      this.currentPriceCurrencyQuerySelector != ""
    ) {
      attributeElements.push({
        querySelector: this.currentPriceCurrencyQuerySelector,
        attributeName: "priceCurrency",
        dataProperty: this.currentPriceCurrencyDataProperty,
      });
    }

    if (
      this.currentImageUrlQuerySelector != null &&
      this.currentImageUrlQuerySelector != ""
    ) {
      attributeElements.push({
        querySelector: this.currentImageUrlQuerySelector,
        attributeName: "imageUrl",
        dataProperty: this.currentImageUrlDataProperty,
      });
    }

    if (
      this.currentOoStockQuerySelector != null &&
      this.currentOoStockQuerySelector != ""
    ) {
      attributeElements.push({
        querySelector: this.currentOoStockQuerySelector,
        attributeName: ooStockAttribute,
        dataProperty: "textContent",
      });
    }

    if (
      this.currentInStockQuerySelector != null &&
      this.currentInStockQuerySelector != ""
    ) {
      attributeElements.push({
        querySelector: this.currentInStockQuerySelector,
        attributeName: inStockAttribute,
        dataProperty: "textContent",
      });
    }

    if (
      this.currentShippingQuerySelector != null &&
      this.currentShippingQuerySelector != ""
    ) {
      attributeElements.push({
        querySelector: this.currentShippingQuerySelector,
        attributeName: "shippingPrice",
        dataProperty: this.currentShippingDataProperty,
      });
    }

    let competitor: ICompetitor = {
      id: this.currentCompetitorId,
      competitorUrlDataScheme: {
        attributeElements,
        defaultCurrencyCode: this.currentDefaultCurrencyCode,
        shouldUseDataCollector: this.shouldUseDataCollector,
        dataCollectorOptions: {
          disableInterception: this.disableInterception,
          waitUntil: this.waitUntil,
          waitForSelector: this.waitForSelector,
        },
        numberOfRetries: this.numberOfRetries,
      },
      stealthModeType: this.selectedProxy.value,
      taxInclusion: this.isTaxInclusionDisabled ? null : {
        actionType: this.currentTaxInclusionActionMarginType,
        margin: this.currentTaxInclusionMargin,
        marginType: this.currentTaxInclusionMarginType
      }
    };

    if (this.defaultShippingPrice > 0)
      competitor.competitorUrlDataScheme.defaultShippingPrice =
        this.defaultShippingPrice;

    if (this.freeShippingAbove > 0)
      competitor.competitorUrlDataScheme.freeShippingAbove =
        this.freeShippingAbove;

    return competitor;
  }

  isSubmitFormDisabled(): boolean {
    return !this.competitorForm.valid;
  }

  get currentCompetitorId(): string {
    return this.competitorForm.controls.competitorId.value;
  }

  get currentPriceQuerySelector(): string {
    return this.competitorForm.controls?.priceQuerySelector?.value ?? "";
  }

  get currentInStockQuerySelector(): string {
    return this.competitorForm.controls?.inStockSelector?.value ?? "";
  }

  get currentOoStockQuerySelector(): string {
    return this.competitorForm.controls?.ooStockSelector?.value ?? "";
  }

  get currentPriceDataProperty(): string {
    return this.competitorForm.controls?.priceDataProperty?.value ?? "";
  }

  get currentPriceCurrencyQuerySelector(): string {
    return (
      this.competitorForm.controls?.priceCurrencyQuerySelector?.value ?? ""
    );
  }

  get currentPriceCurrencyDataProperty(): string {
    return this.competitorForm.controls?.priceCurrencyDataProperty?.value ?? "";
  }

  get currentDefaultCurrencyCode(): string {
    return this.competitorForm.controls?.defaultCurrencyCode?.value ?? "";
  }

  get shouldUseDataCollector(): boolean {
    return this.competitorForm.controls?.shouldUseDataCollector?.value ?? false;
  }

  get disableInterception(): boolean {
    return this.competitorForm.controls?.disableInterception?.value ?? false;
  }

  get waitUntil(): string {
    return this.competitorForm.controls?.waitUntil?.value ?? "";
  }
  get waitForSelector(): string {
    return this.competitorForm.controls?.waitForSelector?.value ?? "";
  }

  get currentImageUrlQuerySelector(): string {
    return this.competitorForm.controls?.imageUrlQuerySelector?.value ?? "";
  }

  get currentImageUrlDataProperty(): string {
    return this.competitorForm.controls?.imageUrlDataProperty?.value ?? "";
  }

  get defaultShippingPrice(): number {
    return this.competitorForm.controls.defaultShippingPrice.value;
  }

  get freeShippingAbove(): number {
    return this.competitorForm.controls.freeShippingAbove.value;
  }

  get currentShippingQuerySelector(): string {
    return this.competitorForm.controls?.shippingQuerySelector?.value ?? "";
  }

  get currentShippingDataProperty(): string {
    return this.competitorForm.controls?.shippingDataProperty?.value ?? "";
  }

  get numberOfRetries(): number {
    return this.competitorForm.controls.numberOfRetries.value;
  }

  get currentTitleQuerySelector(): string {
    return this.competitorForm.controls?.titleQuerySelector?.value ?? "";
  }

  get currentTitleDataProperty(): string {
    return this.competitorForm.controls?.titleDataProperty?.value ?? "";
  }

  get currentUnitQuerySelector(): string {
    return this.competitorForm.controls?.unitQuerySelector?.value ?? "";
  }

  get currentUnitDataProperty(): string {
    return this.competitorForm.controls?.unitDataProperty?.value ?? "";
  }

  get currentUnitPriceQuerySelector(): string {
    return this.competitorForm.controls?.unitPriceQuerySelector?.value ?? "";
  }

  get currentUnitPriceDataProperty(): string {
    return this.competitorForm.controls?.unitPriceDataProperty?.value ?? "";
  }

  get currentTaxInclusionActionMarginType() {
    return this.competitorForm.controls?.taxInclusionActionMarginType?.value ?? null;
  }

  get currentTaxInclusionMargin() {
    return this.competitorForm.controls?.taxInclusionMargin?.value ?? null;
  }

  get currentTaxInclusionMarginType() {
    return this.competitorForm.controls?.taxInclusionMarginType?.value ?? null;
  }

  hasAnyErrors(formControlName: string): boolean {
    const control = this.competitorForm.get(formControlName);
    return !control ? false : !!control.errors;
  }

  markFormAsPristine(): void {
    this.competitorForm.markAsPristine();
  }

  getErrorMessage(formControlName: string, fieldName: string): string {
    const control = this.competitorForm.get(formControlName);
    if (!control) {
      return "";
    }
    if (control.hasError(GwValidators.DOM_MAX_LENGTH_ERROR)) {
      const maxLength = control.errors.maxlength.requiredLength.toString();
      return `${fieldName} ${MustBeLessThenCharacters.replace(
        "#MaxLength#",
        maxLength
      )}`;
    }
  }

  onSelectedProxyValueChange(event: MatSelectChange) {
    this.selectedProxy = event.value;
  }

  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   return this.filter(option => option.toLowerCase().includes(filterValue));
  // }

  // private initCategories() {
  //   this.filteredCategories = this.productForm.controls.competitorUrl.valueChanges
  //     .pipe(
  //       startWith(''),
  //       map(value => this._filter(value))
  //     );

  //   this.categories = this.productService.categories;
  // }

  get isAdmin(): boolean {
    return this.sessionService.isAdmin;
  }

  getActionMarginView(actionMarginMarginType: MarginActionType): string {
    return this.sessionService.getActionMarginView(actionMarginMarginType);
  }

  getMarginView(pricingMarginType: PricingMarginType): string {
    return this.sessionService.getMarginView(pricingMarginType);
  }

  onEnableTaxInclusion(event: any) {
    let eventValue = event.checked != null ? event.checked : event;
    if (eventValue == true) {
      this.isTaxInclusionDisabled = false;
      this.competitorForm.patchValue({
        taxInclusionActionMarginType: this.currentCompetitor?.taxInclusion?.actionType ?? MarginActionType.Increase,
        taxInclusionMargin: this.currentCompetitor?.taxInclusion?.margin ?? 15,
        taxInclusionMarginType: this.currentCompetitor?.taxInclusion?.marginType ?? PricingMarginType.Percentage
      });
      this.competitorForm.controls?.taxInclusionActionMarginType?.enable();
      this.competitorForm.controls?.taxInclusionMargin?.enable();
      this.competitorForm.controls?.taxInclusionMarginType?.enable();
    } else {
      this.isTaxInclusionDisabled = true;
      this.competitorForm.patchValue({
        taxInclusionActionMarginType: null,
        taxInclusionMargin: null,
        taxInclusionMarginType: null
      });
      this.competitorForm.controls?.taxInclusionActionMarginType?.disable();
      this.competitorForm.controls?.taxInclusionMargin?.disable();
      this.competitorForm.controls?.taxInclusionMarginType?.disable();
    }
    this.competitorForm.patchValue({
      isEnableTaxInclusion: !this.isTaxInclusionDisabled
    });
  }
}

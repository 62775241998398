import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import {SharedModule} from '../../shared/shared.module';
import {UserOnboardingModalComponent} from './user-onboarding-modal.component';

@NgModule({
  declarations: [UserOnboardingModalComponent],
  imports: [
    MatDialogModule,
    SharedModule,
    MatSelectCountryModule.forRoot('en'),
  ],
  providers: [],
})

export class UserOnboardingModalModule {}

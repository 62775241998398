<section *ngIf="isShowProductsLimit()" class="limit-wrapper">
  <div class="progress-wrapper">
    <nb-progress-bar class="product-sync-progress-bar color-white" status="warning"
                     [value]="progress"
                     [displayValue]="false"
                     [size]="'tiny'">
    </nb-progress-bar>

    <p class="sync-amount" (click)="navigateToBilling()">
      Only {{syncedProductsCount}}/{{allProductsCount}} of your products have been synced
    </p>
  </div>

  <button type="button" class="rounded-button" (click)="navigateToBilling()">
    Upgrade Now
  </button>
</section>

<div class="modal-wrapper">
  <mat-dialog-content>
    <img class="logo mb-16" src="../../../assets/images/intelis_logo.svg" alt="logo">
    <h2>Welcome to Intelis AI</h2>
    <p class="text-secondary text-center mb-16">We have started syncing your products. Please confirm your details.</p>

    <form [formGroup]="form">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Emails</mat-label>
        <mat-chip-list formControlName="emailSubscribers" #chipList>
          <mat-chip *ngFor="let subscriber of form?.controls?.emailSubscribers?.value"
                    [selectable]="true" [removable]="true"
                    (removed)="removeEmailChip(subscriber)">
            {{subscriber}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input placeholder="New Emails..."
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="true"
                 (matChipInputTokenEnd)="addEmailChip($event)">
        </mat-chip-list>
      </mat-form-field>

      <div class="currency-country-wrapper">
        <mat-form-field class="currency-field" appearance="outline">
          <mat-label>Select currency</mat-label>
          <mat-select formControlName="currency">
            <mat-option *ngFor="let currency of currencies"
                        [value]="currency">
              {{currency.displayName}}
            </mat-option>
          </mat-select>
        </mat-form-field>


        <mat-select-country appearance="outline" label="Select country"
                            formControlName="country"
                            [countries]="countries">
        </mat-select-country>
      </div>

      <span class="break-line"></span>
      <p class="fs-sm mb-16">What products would you like to sync first?</p>

      <pm-sync-form #pmSyncForm
                    [isFullWidth]="true"
                    [hideSyncButton]="true"
                    [tenantId]="currentTenant?.id"
                    [syncFormGroup]="syncFormGroup"
                    (formValidityChanged)="isValidSyncForm = $event"
                    (merchantAccountCountryEmit)="setCountry($event)"
                    (merchantAccountCurrencyEmit)="setCurrency($event)">
      </pm-sync-form>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button class="highlighted" nbSpinnerSize="tiny"
            [nbSpinner]="isLoading"
            [disabled]="form.invalid || !isValidSyncForm || !allCompetitors.length"
            (click)="sendOnBoardingData()">
      Continue
    </button>
  </mat-dialog-actions>
</div>

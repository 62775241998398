import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GwValidators } from '../../common/helpers/gw-validators.helper';
import { MatChipInputEvent } from '@angular/material/chips';
import { CompetitorsService } from 'app/common/services/competitors.service';
import { ICompetitorUrl } from 'app/common/interfaces/competitors/ICompetitorUrl';
import { MatTableDataSource } from '@angular/material/table';
import { SessionService } from 'app/common/services/session.service';
import { IProduct } from 'app/common/interfaces/IProduct';
import { DeleteCompetitorUrlComponent } from '../delete-competitor-url-dialog/delete-competitor-url-dialog.component';
import { GlobalEventsHolderService } from 'app/common/services/globalEventsHolder.service';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import {Clipboard} from '@angular/cdk/clipboard';
import {NbGlobalPhysicalPosition} from '@nebular/theme';
import {ToastrService} from '../../common/services/toastr.service';

const MustBeLessThenCharacters = 'Must be last then #MaxLength#';

@Component({
  selector: 'pm-add-competitor-url',
  templateUrl: './add-competitor-url.component.html',
  styleUrls: ['./add-competitor-url.component.scss'],
})
export class AddCompetitorUrlComponent implements OnInit, OnDestroy {
  @ViewChild(DeleteCompetitorUrlComponent) deleteCompetitorUrlComponent: DeleteCompetitorUrlComponent;

  @Input() targetProduct: IProduct;

  competitorUrlChangedSubscription: Subscription;
  shouldShowDeleteDialog: boolean = false;
  competitorUrls: ICompetitorUrl[];
  _dataSource: MatTableDataSource<ICompetitorUrl> = new MatTableDataSource<ICompetitorUrl>([]);
  competitorUrlForDeletion: ICompetitorUrl = null;
  isLoading: boolean;
  columns = this.isMobile ? ['url', 'status'] : ['url', 'status', 'delete'];
  addCompetitorForm = new FormGroup({
    competitorUrl: new FormControl('', [Validators.required, Validators.pattern(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/)]),
  });

  constructor(
    private competitorService: CompetitorsService,
    private sessionService: SessionService,
    private globalEventsHolderService: GlobalEventsHolderService,
    private deviceService: DeviceDetectorService,
    private clipboard: Clipboard,
    private toastrService: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.initTableData();

    this.competitorUrlChangedSubscription = this.globalEventsHolderService.competitorUrlChangedEmitter.subscribe((updatedCompetitorUrlEntity: ICompetitorUrl) => {
      if (this.targetProduct.id === updatedCompetitorUrlEntity.searchableProductId) {
        const competitorUrlEntityToUpdate = this.competitorUrls.find(x => x.id === updatedCompetitorUrlEntity.id);

        if (competitorUrlEntityToUpdate) {
          competitorUrlEntityToUpdate.searchMetadata = updatedCompetitorUrlEntity.searchMetadata;
          competitorUrlEntityToUpdate.status = updatedCompetitorUrlEntity.status;
        }
      }
    });
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  get shouldShowTable() {
    return (this.sessionService.currentTenant?.id != '61ce63a6563a293835c4c1e6' && this.sessionService.currentTenant?.id != '61e6e444a8307ecb426c3279') || this.sessionService.isAdmin;
  }

  get isDemoPlatform(): boolean {
    return this.sessionService.isDemoPlatform;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;

    if (input) {
      input.value = '';
    }
  }

  copyCompetitorURL(url: string): void {
    const wasURLCopied: boolean = this.clipboard.copy(url);

    if (wasURLCopied) {
      this.toastrService.showToast('Competitor URL copied', 'Success', 'success', NbGlobalPhysicalPosition.TOP_RIGHT);
    }
  }

  onFormSubmit(): void {
    this.markFormAsPristine();
    this.addCompetitorUrl();
  }

  hasAnyErrors(formControlName: string): boolean {
    const control = this.addCompetitorForm.get(formControlName);
    return !control ? false : !!control.errors;
  }

  markFormAsPristine(): void {
    this.addCompetitorForm.markAsPristine();
  }

  getErrorMessage(formControlName: string, fieldName: string): string {
    const control = this.addCompetitorForm.get(formControlName);
    if (!control) {
      return '';
    }
    if (control.hasError(GwValidators.DOM_MAX_LENGTH_ERROR)) {
      const maxLength = control.errors.maxlength.requiredLength.toString();
      return `${fieldName} ${MustBeLessThenCharacters.replace('#MaxLength#', maxLength)}`;
    }
  }

  addCompetitorUrl() {
    const competitorUrl = this.addCompetitorForm.controls.competitorUrl.value;

    this.competitorService.addCompetitorUrlAsync(this.targetProduct.id, competitorUrl).subscribe(() => {
      this.addCompetitorForm.reset();
      this.initTableData();
    });
  }

  initCompetitorsTable(searchableProductId: string) {
    this.isLoading = true;
    this.competitorService.getCompetitorUrlsForManualAsync(searchableProductId).subscribe(competitorUrls => {
      this.competitorUrls = competitorUrls;
      this._dataSource = new MatTableDataSource<ICompetitorUrl>(competitorUrls);
      this.isLoading = false;
    });
  }

  onDeleteRow(competitorUrlEntity: ICompetitorUrl) {
    this.competitorUrlForDeletion = competitorUrlEntity;
    if (this.deleteCompetitorUrlComponent && this.deleteCompetitorUrlComponent.epFormDialogComponent)
      this.deleteCompetitorUrlComponent.epFormDialogComponent.shouldShow = true;
  }

  onDeleteSuccess() {
    this.addCompetitorForm.reset();
    this.ngOnInit();
    if (this.deleteCompetitorUrlComponent && this.deleteCompetitorUrlComponent.epFormDialogComponent)
      this.deleteCompetitorUrlComponent.epFormDialogComponent.shouldShow = false;
  }

  deleteCompetitorUrlDialogClose() {
    this.addCompetitorForm.reset();
    this.shouldShowDeleteDialog = false;
  }

  private initTableData() {
    if (this.shouldShowTable)
      this.initCompetitorsTable(this.targetProduct.id);
  }

  ngOnDestroy(): void {
    this.competitorUrlChangedSubscription.unsubscribe();
    this._dataSource = new MatTableDataSource<ICompetitorUrl>([]);
  }
}

export enum SearchKeywordTypesEnum {
  Title = 0,
  Sku = 1,
  Barcode = 2,
  BarcodeWithBrand = 3,
  Model = 4,
  ModelWithBrand = 5,
  BarcodeOnly = 6,
}

export interface ISearchKeyword {
  title: string;
  value: SearchKeywordTypesEnum;
}

export const searchKeywordsArray: ISearchKeyword[] = [
  {title: 'Title', value: SearchKeywordTypesEnum.Title},
  {title: 'SKU', value: SearchKeywordTypesEnum.Sku},
  {title: 'Barcode', value: SearchKeywordTypesEnum.Barcode},
  {title: 'Not Sure', value: SearchKeywordTypesEnum.Title},
];

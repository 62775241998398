<ng-container  *ngIf="products?.length > 0">
  <div *ngIf="showFilter" class="above-table-filters filters-new-design">
    <mat-form-field class="filter-field">
      <mat-label>Search</mat-label>
      <input matInput type="text" (keyup)="filterTable($event.target.value)" placeholder="Name, SKU, Brand, competitors..">
    </mat-form-field>

    <ng-container *ngIf="(showExportOptions && shouldShowExportOptions || showColumnFilterOptions) && !isMobile">
      <button *ngIf="showColumnFilterOptions" mat-button color="primary"
              (click)="insightsProductTableSelect.open()">
        <img src="/assets/images/icons/keyboard-arrow-down_gray.svg" alt="Filter icon">
        <span>View</span>

        <mat-select #insightsProductTableSelect class="columnSelect" multiple
                    [value]="columnSelect"
                    (selectionChange)="onChangeFilterColumns($event)">
          <ng-container *ngFor="let column of columnsForSelect">
            <mat-option [value]="column.property"
              [ngStyle]="{'display': column.property === 'checkbox' ? 'none' : 'flex' }">
              {{ column.label }}
            </mat-option>
          </ng-container>
        </mat-select>
      </button>

      <button *ngIf="showExportOptions && shouldShowExportOptions" mat-button color="primary"
              class="margin-left--xs"
              (click)="exporter.exportTable(exportType.CSV, {fileName:'competitor products'})">
        <img src="/assets/images/icons/download-gray.svg" alt="Download icon">
        <span>Export</span>
      </button>
    </ng-container>
  </div>

  <div *ngIf="!showFilter && !isMobile && (showExportOptions && shouldShowExportOptions || showColumnFilterOptions || showPercentagePriceConverter || showCreateReport)"
       class="table-toolbar">
    <div class="top-toolbar-wrapper" [class.justify-right]="!provisioningStatus || (provisioningStatus && provisioningStatus?.processedProducts / provisioningStatus?.provisionedProducts >= 1)">
      <div *ngIf="provisioningStatus && provisioningStatus?.processedProducts / provisioningStatus?.provisionedProducts < 1" class="left-side">
        <ng-lottie [options]="lottieOptions" width="15px" height="15px"></ng-lottie>

        <span class="text-secondary-color font-size-m">
          Synced {{provisioningStatus.processedProducts}} out of {{provisioningStatus.provisionedProducts}} products
        </span>
      </div>

      <div class="right-side" [class.justify-right]="provisioningStatus && provisioningStatus.processedProducts / provisioningStatus.provisionedProducts < 1">
        <mat-button-toggle-group *ngIf="showPercentagePriceConverter"
                                 [ngClass]="(showExportOptions && shouldShowExportOptions || showColumnFilterOptions) ? '':'margin-right--m'"
                                 [value]="percentagePriceConverterValue" (change)="onChangePercentagePriceConverter($event)">
          <mat-button-toggle value="percentage">%</mat-button-toggle>
          <mat-button-toggle value="price">{{ currencySymbol }}</mat-button-toggle>
        </mat-button-toggle-group>

        <button *ngIf="showColumnFilterOptions" (click)="mainProductTableSelect.open()" mat-button color="primary"
                [ngClass]="(showExportOptions && shouldShowExportOptions) ? '':'margin-right--m'">
          <img src="/assets/images/icons/keyboard-arrow-down_gray.svg" alt="Filter icon">
          <span>View</span>
          <mat-select #mainProductTableSelect class="columnSelect" multiple [value]="columnSelect"
                      (selectionChange)="onChangeFilterColumns($event)">
            <ng-container *ngFor="let column of columns">
              <mat-option [value]="column.property"
                          [ngStyle]="{'display': column.property === 'checkbox' || column.property === 'product-variants' || column.property === 'actions' ? 'none' : 'flex' }">
                {{ column.label }}
              </mat-option>
            </ng-container>
          </mat-select>
        </button>

        <button *ngIf="showCreateReport" mat-button color="primary" class="pull-right gw-padding-top-10"
                (click)="createReport()">
          <img src="/assets/images/icons/chart-gray.svg" alt="Report icon">
          <span>Create Report</span>
        </button>

        <button *ngIf="showExportOptions && shouldShowExportOptions" mat-button color="primary"
                class="pull-right gw-padding-top-10" (click)="exportProducts()">
          <img src="/assets/images/icons/download-gray.svg" alt="Download icon">
          <span>Export</span>
        </button>
      </div>
    </div>

    <nb-progress-bar *ngIf="provisioningStatus && provisioningStatus.processedProducts / provisioningStatus.provisionedProducts < 1"
                     class="progress-container" status="warning"
                     [value]="provisioningStatus.processedProducts / provisioningStatus.provisionedProducts * 100"
                     [displayValue]="false" [size]="'tiny'">
    </nb-progress-bar>
  </div>

  <div *ngIf="!showFilter && numberOfSelectedProducts > 0" class="gw-padding-top-10 gw-padding-left-10">
    <span class="selected-products-count">
      {{ numberOfSelectedProducts }} {{ numberOfSelectedProducts == 1 ? "Product" : 'Products' }} selected
      <nb-icon class="delete-products" icon="trash-2-outline" [matTooltip]="'Delete'"
        (click)="deleteBulkProducts()"></nb-icon>
      <nb-icon *ngIf="isAdmin" class="update-products" icon="refresh" [matTooltip]="'Update Competitors Bulk'"
        (click)="updateCompetitorsBulk()"></nb-icon>
    </span>
  </div>

  <div class="products-table-wrapper">
    <table mat-table matTableExporter matSort [dataSource]="_dataSource" #exporter="matTableExporter"
      [hiddenColumns]="[0, 5, 6, 7]" multiTemplateDataRows (matSortChange)="sortData($event)">
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-head-row"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;"
          class="table-body-row gw-cursor-pointer">
      </tr>

      <tr mat-row *matRowDef="let element; columns: ['expandedVariantDetail']"
          class="example-detail-row table-body-row"
          [hidden]="!shouldShowVariantExpansion(element)">
      </tr>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="checkbox">
        <th *matHeaderCellDef mat-header-cell class="checkbox-th">
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" color="primary">
          </mat-checkbox>
        </th>
        <td *matCellDef="let element" mat-cell class="checkbox-cell">
          <mat-checkbox (change)="$event ? selection.toggle(element) : null" (click)="$event.stopPropagation()"
                        [checked]="selection.isSelected(element)" color="primary">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedVariantDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="product-variants">
          <div class="example-element-detail"
            [@detailExpand]="element != null && variantExpandedElement != null && element == variantExpandedElement ? 'expanded' : 'collapsed'">
            <div class="padding-left--l padding-top-30"
              *ngIf="element != null && variantExpandedElement != null && element == variantExpandedElement">
              <product-variants [targetProduct]="element"></product-variants>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Product Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="product-th">
          <div class="bulk-actions-wrapper">
            <img *ngIf="selection.hasValue()" class="clickable"
                 src="assets/images/icons/delete-trash_gray.svg" alt="Bulk Delete icon"
                 (click)="onDeleteRow(selection?.selected)">
            Product
          </div>
        </th>
        <td mat-cell *matCellDef="let element" class="product-cell" (click)="emitItemForCompetitors.emit(element)">
          <div class="flex-wrapper">
            <img class="img-product" loading="lazy" [src]="element.imageUrl || '/assets/images/empty-product.jpg'"/>

            <div class="right-side">
              <ng-container>
                <!-- For 'sidebar' view -->
                <a *ngIf="viewType === 'sidebar'" [href]="element.url" target="_blank" class="title-text"
                   [matTooltip]="element.title || element.url">
                  {{ (element.title || element.url) | truncate: 25 }}
                </a>

                <!-- For 'page' view -->
                <span *ngIf="viewType === 'page'" class="title-text" [matTooltip]="element.title || element.url">
                  {{ (element.title || element.url) | truncate: 35 }}
                </span>
              </ng-container>

              <!-- Brand Text -->
              <span *ngIf="element.brand" class="brand-text">{{ element.brand }}</span>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- ExternalID Column -->
      <ng-container matColumnDef="externalId">
        <th mat-header-cell *matHeaderCellDef> External Id </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)">
          <div>
            {{element.externalId}}
          </div>
        </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Id </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)">
          <div>
            {{element.id}}
          </div>
        </td>
      </ng-container>

      <!-- SKU Column -->
      <ng-container matColumnDef="sku">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> SKU </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)">
          <div>{{element.sku}}</div>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="model">
        <th mat-header-cell *matHeaderCellDef> Model </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)"> {{element.model}} </td>
      </ng-container>

      <!-- SKU Column -->
      <ng-container matColumnDef="barcode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Barcode </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)">
          <div>
            {{element.barcode}}
          </div>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
        <td mat-cell *matCellDef="let element" class="price-cell" (click)="emitItemForCompetitors.emit(element)">
          <span *ngIf="viewType === 'sidebar' || !element.priceSuggestionInfo">
            {{element.price | currency:currencySymbol:'symbol':'1.1-2'}}
          </span>

          <div *ngIf="viewType === 'page' && element.priceSuggestionInfo?.targetPrice.toFixed(2)"
               class="price-wrapper"
               [matTooltip]="'Suggested Price ' + element.priceSuggestionInfo?.targetPrice.toFixed(2)">
            {{element.price | currency:currencySymbol:'symbol':'1.1-2'}}
            <img src="assets/images/icons/lamp_violet.svg" alt="Smart price">
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="averagePrice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Avg. Price</th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)">
          <span *ngIf="element?.lastSearch?.total?.averagePrice">{{element?.lastSearch?.total?.averagePrice | currency:currencySymbol:'symbol':'1.1-2'}}</span>
          <span *ngIf="!element?.lastSearch?.total?.averagePrice">No data</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="currentSearchRank">
        <th mat-header-cell *matHeaderCellDef class="padding-th"> Search Rank </th>
        <td mat-cell *matCellDef="let element" class="padding-th" (click)="emitItemForCompetitors.emit(element)">
          <span>{{element.currentSearchRank || 'NA'}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="currentPageRank">
        <th mat-header-cell *matHeaderCellDef > Google Shopping Rank </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)">
          <span>{{element.currentPageRank || 'NA'}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="currentPaidRank">
        <th mat-header-cell *matHeaderCellDef > Paid Rank </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)">
          <span>{{element.currentPaidRank || 'NA'}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="searchRank">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Search Rank </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)"> {{ element.searchRank ?? 'NA' }} </td>
      </ng-container>

      <ng-container matColumnDef="pageRank">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Google Shopping Rank </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)"> {{ element.pageRank ?? 'NA' }} </td>
      </ng-container>

      <ng-container matColumnDef="paidRank">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Paid Rank </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)"> {{ element.paidRank ?? 'NA' }} </td>
      </ng-container>

      <ng-container matColumnDef="discountedPrice">
        <th mat-header-cell *matHeaderCellDef> Discounted Price </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)"> {{element.discountedPrice | currency:currencySymbol:'symbol':'1.1-2'}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="unitPrice">
        <th mat-header-cell *matHeaderCellDef> Unit Price </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)"> {{element.unitPrice | currency:currencySymbol:'symbol':'1.1-2'}}
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="cost">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cost </th>
        <td mat-cell *matCellDef="let element" class="cost" (click)="emitItemForCompetitors.emit(element)"> {{element.cost | currency:currencySymbol:'symbol':'1.1-2'}} </td>
      </ng-container>

      <!-- comapreatprice Column -->
      <ng-container matColumnDef="compareAtPrice">
        <th mat-header-cell *matHeaderCellDef> CompareAt Price </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)">
          {{element.compareAtPrice | currency:currencySymbol:'symbol':'1.1-2'}}
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="unit">
        <th mat-header-cell *matHeaderCellDef> Unit </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)"> {{element.unit}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="volume">
        <th mat-header-cell *matHeaderCellDef> Volume </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)"> {{element.volume}} </td>
      </ng-container>

      <!-- Cost Column -->
      <ng-container matColumnDef="competitorsUrlNumber">
        <th mat-header-cell *matHeaderCellDef>Num Of Comp Urls</th>
        <td mat-cell *matCellDef="let element" class="cost" (click)="emitItemForCompetitors.emit(element)">
          <div class="center-div">
            {{ element.numberOfCompetitorUrls ?? 0 }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Create Date </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)"> {{element.createDate | date : "yyyy-MM-dd"}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="product-variants">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button [matTooltip]="'Variants Details'" class="gw-hover" mat-icon-button
            (click)="handleVariantExpansion(element)" *ngIf="element.variantsCount && element.variantsCount > 0">
            <span [ngClass]="shouldShowVariantExpansion(element) ? 'arrow-upward-icon-span' : 'arrow-downward-icon-span'">
              <i class="material-icons"
                [ngClass]="shouldShowVariantExpansion(element) ? 'arrow-upward-icon' : 'arrow-downward-icon'">{{
                shouldShowVariantExpansion(element) ? 'arrow_upward' : 'arrow_downward' }}</i>
            </span>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="imageUrlForInsights">
        <th mat-header-cell *matHeaderCellDef> Image</th>
        <td class="image-td" mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)">
          <img class="img-product" loading="lazy" [src]="element.imageUrl || '/assets/images/empty-product.jpg'"/>
        </td>
      </ng-container>

      <ng-container matColumnDef="vendor">
        <th mat-header-cell *matHeaderCellDef> Competitor </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)">
          <div class="margin-right-50">
            <a (click)="emitItemForDetails(element)" class="competitors-text">
              {{ this.getLogoUrl(element.vendor) == 'none' ? element.vendor : ''}}
            </a>
          </div>
      </ng-container>

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef class="position-th">Position</th>
        <td mat-cell *matCellDef="let element" class="position-cell">
          <price-slider [size]="{height: '12px', width: '76px'}"
                        [valueNumber]="-1 * (myProduct.price - element.price) / myProduct.price * 100">
          </price-slider>
        </td>
      </ng-container>

      <!-- Source Column -->
      <ng-container matColumnDef="source">
        <th mat-header-cell *matHeaderCellDef> Source </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)">
          <a  class="competitors-text" (click)="emitItemForDetails(element)" >
            {{ this.getLogoUrl(element.source) == 'none' ? element.source : ''}}
          </a>
      </ng-container>

      <ng-container matColumnDef="shippingPrice">
        <th mat-header-cell *matHeaderCellDef> Shipping Price </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)">
          <span style="margin-left: 2px">
            {{element.shippingPrice | currency:currencySymbol:'symbol':'1.1-2'}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="tax">
        <th mat-header-cell *matHeaderCellDef> Tax </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)">
          <span style="margin-left: 2px">
            {{ getFormattedTax(element.tax) }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="match">
        <th mat-header-cell *matHeaderCellDef> Match </th>
        <td mat-cell *matCellDef="let element"> {{normalizePercent(element.score)}} % </td>
      </ng-container>

      <ng-container matColumnDef="verified">
        <th mat-header-cell *matHeaderCellDef> Verified </th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle [disabled]="isDemoPlatform" [checked]="element.isVerified"
            (change)="onVerifiedSliderChange(element, $event)">
          </mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="isMatchedByAlgo">
        <th mat-header-cell *matHeaderCellDef> AlgoMatched </th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle [disabled]="isDemoPlatform" [checked]="element.isMatchedByAlgo">
          </mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="isAvailable">
        <th mat-header-cell *matHeaderCellDef class="in-stock-th">In Stock</th>
        <td mat-cell *matCellDef="let element" class="in-stock-cell">
          <img *ngIf="element.isAvailable" src="assets/images/icons/check-circle_green.svg" alt="In Stock">
          <img *ngIf="!element.isAvailable" src="assets/images/icons/cancel-cross_red.svg" alt="Not in Stock">
        </td>
      </ng-container>

      <ng-container matColumnDef="extraInformation">
        <th mat-header-cell *matHeaderCellDef> Extra Information </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)">
          <span>{{ element.extraInformation }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="competitors">
        <th mat-header-cell *matHeaderCellDef>Top Competitors </th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)" class="competitors-cell">
          <ng-container *ngIf="!isSyncing(element) && element.averageDifferenceByVendors?.length">
            <ng-container *ngFor="let vendorData of element.averageDifferenceByVendors; let i = index; let isLastItem = last">
              <ng-container *ngIf="i <= 2">
                <pm-trend-arrow [originalPrice]="element.price"
                                [value]="isProductsPriceView ? vendorData.price : vendorData.averagePercentageDifference"
                                [isNewDesign]="true">
                <span class="trend-inner-text gw-cursor-pointer"
                      [matTooltip]="getLogoUrl(vendorData.vendor) == 'none' ? vendorData.vendor : null">
                  {{ getLogoUrl(vendorData.vendor) == 'none' ? (vendorData.vendor | truncate: 10) : ''}}
                </span>
                </pm-trend-arrow>
              </ng-container>

              <ng-container *ngIf="i > 2 && isLastItem">
                <div class="other-competitors">
                  <span class="top-text">+{{element.averageDifferenceByVendors.length - 3}}</span>
                  <span class="bottom-text" (click)="emitItemForCompetitorsDetails(element)">Competitors</span>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="isSyncing(element)">
            <div class="competitor-skeleton">
              <span class="left-square"></span>
              <div class="right-side">
                <span class="top"></span>
                <span class="bottom"></span>
              </div>
            </div>
          </ng-container>
          <div *ngIf="!isSyncing(element) && !element.averageDifferenceByVendors?.length" class="no-competitor">
            <span class="top-text text-center">No Competitor</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="margin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Avg. Market Diff</th>
        <td mat-cell *matCellDef="let element" (click)="emitItemForCompetitors.emit(element)">
          <div class="cell-box">
            <pm-trend-arrow *ngIf="!isSyncing(element) && element.isActive"
                            [hasCompetition]="element.lastSearch?.isCompetitionFound"
                            [value]="isProductsPriceView ? element.averagePriceDifference : element.averagePercentageDifference"
                            [isNewDesign]="true">
              <p class="inactive" *ngIf="!element.isActive">Inactive Product</p>
            </pm-trend-arrow>
            <div *ngIf="isSyncing(element)" class="one-line-skeleton">
              <span class="left-square"></span>
              <span class="right-line"></span>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th class="delete-th" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" (click)="onDeleteRow(element)">
          <img class="clickable delete-icon" src="assets/images/icons/delete-trash_gray.svg" alt="Delete icon">
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="!isSyncing(element)" [ngTemplateOutlet]="actionTemplate"
                        [ngTemplateOutletContext]="{element:element}">
          </ng-container>
        </td>
      </ng-container>
    </table>

    <mat-paginator [length]="totalRows" [pageIndex]="pageIndex" [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" showFirstLastButtons>
    </mat-paginator>
    <div class="gw-float-right" *ngIf="isAdmin">
      <mat-label>Change Page Number (only admin)</mat-label>
      <input matInput placeholder="Page Number" (ngModelChange)="setPageChanged($event)" [(ngModel)]="adminPageIndex">
    </div>
  </div>
</ng-container>

<items-skeleton *ngIf="shouldShowSkeleton; else noProductsFound" class="padding-top-60"
                [skeletonRowsAmount]="5">
</items-skeleton>

<ng-template #noProductsFound>
  <div *ngIf="shouldShowNoProductsMessage" id="no-products-found-message" class="padding-top-60">
    <div class="first-list-text gw-align-center gw-justify-center gw-flex">
      <div class="center-div text-center">
        <div class="add-new-product">
          {{ noProductsMessage }}
        </div>
      </div>
    </div>
  </div>
</ng-template>


<pm-ep-yes-no-dialog [title]="deleteBulkProductsDialogTitle" (dialogConfirm)="onConfirmDeleteBulkProductsDialog()"
  (dialogCancel)="onCancelDeleteBulkProductsDialog()" [(shouldShow)]="shouldShowDeleteBulkProductsDialog">
  {{deleteBulkProductsDialogMessage}}</pm-ep-yes-no-dialog>

<div class="slider-wrapper"
     [style.height]="size.height"
     [style.width]="size.width">
  <div class="slider-bar left">
    <span *ngIf="valueNumber < 0" class="filled-bar"
          [style.width]="-1 * valueNumber + '%'">
    </span>
  </div>

  <div class="divider"></div>

  <div class="slider-bar right">
    <span *ngIf="valueNumber > 0" class="filled-bar"
          [style.width]="valueNumber + '%'">
    </span>
  </div>
</div>

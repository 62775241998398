import {NgModule} from '@angular/core';
import {PriceSliderComponent} from './price-slider.component';
import {CommonModule} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [PriceSliderComponent],
  imports: [
    CommonModule,
    MatTooltipModule,
  ],
  exports: [
    PriceSliderComponent,
  ],
})

export class PriceSliderModule {}

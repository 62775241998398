<div *ngIf="!isLoading && data?.length" #containerRef>
  <ngx-charts-advanced-pie-chart [ngClass]="{'no-total-legend': !shouldShowTotalLegend}"
                                 [view]="[elementRefWidth, height]"
                                 [results]="data"
                                 [scheme]="colorScheme"
                                 [gradient]="false"
                                 (select)="onSelect($event)"
                                 (window:resize)="onResize($event)">
  </ngx-charts-advanced-pie-chart>
</div>

<div *ngIf="!isLoading && !data?.length"
     class="no-data-block">
  <p>No Data found</p>
</div>

<div *ngIf="isLoading" class="no-data-block">
  <ng-lottie [options]="lottieOptions" width="30px" height="30px"></ng-lottie>
</div>

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SearchableProductSourceTypes } from 'app/common/enums/SearchableProductSourceTypes';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from 'app/common/services/session.service';
import { VariantsSetupTypes } from '../../common/enums/VariantsSetupTypes.enum';
import { ISearchableProduct } from 'app/common/interfaces/ISearchableProduct';
import { DeviceDetectorService } from 'ngx-device-detector';
import { IntegrationTypes } from 'app/common/enums/IntegrationTypes';
import {ConfirmModalComponent} from '../../modals/confirm-modal/confirm-modal.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'pm-action-items',
  templateUrl: './action-items.component.html',
  styleUrls: ['./action-items.component.scss'],
})


export class ActionItemsComponent {

  @Input() isShowDownloadButton: boolean = true;
  @Input() isShowDetailsButton: boolean = true;
  @Input() isShowNotesButton: boolean = true;
  @Input() isShowEditButton: boolean = null;
  @Input() shouldShowDeleteButton: boolean = true;
  @Input() isShowAdminButton: boolean = true;
  @Input() shouldShowCopyButtons : boolean = true;
  @Input() shouldShowUpdateCompetitors: boolean = true;
  @Input() shouldShowActivateButton: boolean = true;
  @Input() shouldShowSeperateVariants : boolean = true;
  @Input() shouldShowApplyButton : boolean = true;
  @Input() shouldShowRollbackButton : boolean = true;
  @Input() shouldShowCopyButton : boolean = false;

  @Input() downloadButtonName : string = "Download";
  @Input() item: any;
  @Output() deleteItemEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() downloadItemEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() editItemEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitItemForView: EventEmitter<object> = new EventEmitter<Object>();
  @Output() emitItemForPricingSuggestionView: EventEmitter<object> = new EventEmitter<Object>();
  @Output() emitItemForApply: EventEmitter<object> = new EventEmitter<Object>();
  @Output() emitItemForRollback: EventEmitter<object> = new EventEmitter<Object>();
  @Output() emitItemForRerun: EventEmitter<object> = new EventEmitter<Object>();
  @Output() emitItemForUpdateCompetitorsEmitter: EventEmitter<object> = new EventEmitter<Object>();
  @Output() saveProduct: EventEmitter<ISearchableProduct> = new EventEmitter<ISearchableProduct>();
  @Output() separateVariantsEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitAdminButtonClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitItemForCopy: EventEmitter<object> = new EventEmitter<Object>();

  @Input() ConfirmDeleteMessage = 'Are you sure you want to delete this product? All the data will be lost.';
  @Input() ConfirmCopyMessage = 'Are you sure you want to copy this strategy?';
  @Input() isProductsActions: boolean = false;
  @Input() isPricingStrategyActions: boolean = false;
  @Input() isCompetitorsActions: boolean = false;
  @Input() isSalesChannelsActions: boolean = false;
  @Input() isPricingDiscountActions: boolean = false;
  @Input() isCompetitorUrlsManagementActions: boolean = false;
  @Input() isShowCloseDeleteModal: boolean = true;
  @Input() isReportsActions: boolean = false;

  constructor(public sessionService: SessionService,
              private matSnackBar: MatSnackBar,
              private deviceService: DeviceDetectorService,
              private matDialog: MatDialog,
  ) {
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  get isItemActive(): boolean {
    if (this.item.isActive != null) {
      return this.item.isActive;
    }

    return true;
  }

  get isProductEditable(): boolean {
    if(this.isShowEditButton != null) {
      if (this.isSalesChannelsActions)
        return this.isShowEditButton && this.isItemActive;
      return this.isShowEditButton;
    }

    if (this.item.sourceType != null) {
      return (this.item.sourceType === SearchableProductSourceTypes.Default);
    }

    return true;
  }

  get isAdmin() : boolean {
    return this.sessionService.isAdmin;
  }

  get shouldShowSeperateVariantsOption(): boolean {
    return this.shouldShowSeperateVariants && this.sessionService.currentTenant?.settings?.provisioningOptions?.variantsSetupType == VariantsSetupTypes.VariantsAsProductChild &&
      this.item?.variantsSetupType != VariantsSetupTypes.ProductPerVariant && this.item?.parentId == null;
  }

  get ProductSku(): string {
    return this.item?.sku;
  }

  get ProductUrl(): string {
    return this.item?.url;
  }

  get ProductTitle() : string{
    return this.item?.title;
  }

  get ProductBarcode(): string {
    return this.item?.barcode;
  }

  get ProductID(): string {
    return this.item?.id;
  }

  get isTrafficDataAvailable(): boolean {
    return this.isCompetitorsActions && this.item.trafficData;
  }

  get productNotes(): string {
    return this.item.note ? this.item.note : "Product Notes";
  }

  copyTitle(): void {
    this.matSnackBar.open(`Product's Title copied to clipboard`);
  }

  copySku(): void {
    this.matSnackBar.open(`Product's SKU copied to clipboard`);
  }

  copyBarcode(): void {
    this.matSnackBar.open(`Product's Barcode copied to clipboard`);
  }

  copyUrl(): void {
    this.matSnackBar.open(`Product's Url copied to clipboard`);
  }

  copyId() {
    this.matSnackBar.open(`Product's ID copied to clipboard`);
  }

  deleteItem(): void {
    this.deleteItemEmitter.emit(this.item.id);
  }

  emitItemForDetails(): void {
    this.emitItemForView.emit(this.item);
  }

  emitItemForPricingSuggestionDetails(): void {
    this.emitItemForPricingSuggestionView.emit(this.item);
  }

  separateVariants(): void {
    this.separateVariantsEmitter.emit(this.item);
  }

  emitItem(): void {
    this.editItemEmitter.emit(this.item);
  }

  emitItemAfterActivationStatusChange(activationStatus: boolean): void {
    this.item.isActive = !activationStatus;
    this.saveProduct.emit(this.item);
  }

  emitItemForDownload(): void {
    this.downloadItemEmitter.emit(this.item);
  }

  emitAdminButton(): void {
    this.emitAdminButtonClick.emit(this.item);
  }

  changeActivationProductStatus(isActive: boolean): void {
    this.item.isActive = !isActive;
  }

  showApplyModal(): void {
    if (!this.sessionService.isDemoPlatform) {
      this.matDialog.open(ConfirmModalComponent, {
        disableClose: true,
        panelClass: ['intelis-modal', 'extra-small'],
        data: {
          title: 'Apply Rule Form',
          message: 'Are you sure you want to apply rule now? Price changes will take effect immediately.',
        },
      }).afterClosed().subscribe((wasConfirmed: boolean) => {
        if (wasConfirmed) {
          this.emitItemForApply.emit(this.item);
        }
      });
    }
  }

  showRollbackModal(): void {
    if (!this.sessionService.isDemoPlatform) {
      this.matDialog.open(ConfirmModalComponent, {
        disableClose: true,
        panelClass: ['intelis-modal', 'small'],
        data: {
          title: 'Rollback Rule Form',
          message: 'Are you sure you want to rollback rule now? All price changes will be reverted immediately.',
        },
      }).afterClosed().subscribe((wasConfirmed: boolean) => {
        if (wasConfirmed) {
          this.emitItemForRollback.emit(this.item);
        }
      });
    }
  }

  showDeleteYesNoDialog(): void {
    if (!this.sessionService.isDemoPlatform) {
      this.matDialog.open(ConfirmModalComponent, {
        disableClose: true,
        panelClass: ['intelis-modal', 'extra-small'],
        data: {
          title: this.ConfirmDeleteMessage,
        },
      }).afterClosed().subscribe((wasConfirmed: boolean) => {
        if (wasConfirmed) {
          this.deleteItemEmitter.emit(this.item.id);
        }
      });
    }
  }

  showCopyModal(): void {
    if (!this.sessionService.isDemoPlatform) {
      this.matDialog.open(ConfirmModalComponent, {
        disableClose: true,
        panelClass: ['intelis-modal', 'small'],
        data: {
          title: this.ConfirmCopyMessage,
        },
      }).afterClosed().subscribe((wasConfirmed: boolean) => {
        if (wasConfirmed) {
          this.emitItemForCopy.emit(this.item);
        }
      });
    }
  }

  showMetafields() {
    const ProductMetafields = this.getProductMetafields(); // Assume this method fetches your metafields

    this.matSnackBar.open(JSON.stringify(ProductMetafields), 'Close', {
      duration: 120000, // Keep the snack bar open for 1 minute
      // Optional: Add here any additional configuration you might want
    });
  }

  getProductMetafields() {
    return this.item?.metafields;
  }

  emitItemForUpdateCompetitors(): void {
    this.emitItemForUpdateCompetitorsEmitter.emit(this.item);
  }

  get isShopifyIntegration(): boolean {
    return this.sessionService?.currentIntegration?.integrationType == IntegrationTypes.Shopify;
  }

  showRerunModal(): void {
    this.matDialog.open(ConfirmModalComponent, {
      disableClose: true,
      panelClass: ['intelis-modal', 'small'],
      data: {
        title: 'Are you sure you want to rerun competitor url search?',
      },
    }).afterClosed().subscribe((wasConfirmed: boolean) => {
      if (wasConfirmed) {
        this.emitItemForRerun.emit(this.item);
      }
    });
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import { EpApiService } from '../../common/services/api/ep-api.service';
import { EpLoadingService } from '../../common/services/ep-loading.service';
import { AuthService } from '@auth0/auth0-angular';
import { HttpErrorResponse } from '@angular/common/http';
import { IntegrationTypes } from '../enums/IntegrationTypes';
import { IIntegration } from '../interfaces/IIntegration';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ICollection } from '../interfaces/ICollection';
import { IMerchant } from '../interfaces/IMerchant';
import { AnalyticsIntegrationTypes } from '../enums/AnalyticsIntegrationTypes';
import { IAnalyticsIntegration } from '../interfaces/integrations/IAnalyticsIntegration';
import { IGoogleAnalyticsProperty } from '../interfaces/integrations/IGoogleAnalyticsProperty';
import { IGoogleAdsCustomer } from '../interfaces/integrations/IGoogleAdsCustomer';
import { ICategory } from '../interfaces/ICategory';

const integrationUrlBuilder = {
  [IntegrationTypes.Shopify.toString()]: {
    build: (baseUrl, storeUrl, authToken) => `${baseUrl}Integrations/InitShopifyIntegration/?ShopifyStoreURL=${storeUrl}&AuthToken=${authToken}`
  },
  [IntegrationTypes.Woo.toString()]: {
    build: (baseUrl, storeUrl, authToken) => `${baseUrl}WooIntegration/InitWooIntegration/?StoreURL=${storeUrl}&AuthToken=${authToken}`
  },
  [IntegrationTypes.Amazon.toString()]: {
    build: (baseUrl, storeUrl, authToken) => `${baseUrl}AmazonIntegration/InitForTenant/?AuthToken=${authToken}`
  },
  [IntegrationTypes.Google.toString()]: {
    build: (baseUrl, storeUrl, authToken) => `${baseUrl}Google/InitMerchantIntegration/?AuthToken=${authToken}`
  }
};

const googleAnalyticsIntegrationUrlBuilder = {
  [AnalyticsIntegrationTypes.GoogleAnalytics.toString()]: {
    build: (baseUrl, authToken) => `${baseUrl}Google/InitAnalyticsIntegration?AuthToken=${authToken}`
  }
};

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {
  integrations: IIntegration[];
  analyticsIntegrations: IAnalyticsIntegration[];

  integrationsLoaderEventEmitter = new EventEmitter<Object>();

  constructor(private epApiService: EpApiService,
    private epLoadingService: EpLoadingService,
    private authenticationService : AuthenticationService) {
  }

  async initIntegration(storeUrl: string, integrationType: IntegrationTypes) {
    this.epLoadingService.start();
    let accessToken = await this.authenticationService.getCurrentToken();
    let urlBuilder = integrationUrlBuilder[integrationType.toString()];

    if (urlBuilder == null)
      throw new Error(`integration ${integrationType.toString()} is not supported `);

    window.location.href = urlBuilder.build(this.epApiService.baseUrl, storeUrl, accessToken);
  }

  
  async initAnalyticsIntegration(integrationType: AnalyticsIntegrationTypes) {
    this.epLoadingService.start();
    let accessToken = await this.authenticationService.getCurrentToken();
    let urlBuilder = googleAnalyticsIntegrationUrlBuilder[integrationType.toString()];

    if (!urlBuilder) {
      this.epLoadingService.stop();
      throw new Error(`Analytics integration type ${integrationType.toString()} is not supported`);
    }

    window.location.href = urlBuilder.build(this.epApiService.baseUrl, accessToken);
  }

  syncCollection(tenantId: string, targetCollectionName, addToActiveProducts : boolean) : Observable<any>{
    this.epLoadingService.start();
    return this.epApiService.post<any>(`Integrations/SyncShopifyCollections`, {
      tenantId : tenantId,
      shouldAddToActiveProductsByCollectionList : addToActiveProducts,
      "filterOptions" : {
        "name" : targetCollectionName,
        "shouldEnrichProductIds" : true
    },
    "shouldSyncProducts" : true
    }).pipe(
      tap(data => { return; }),
      catchError(this.epApiService.handleError)
    );   
  }

  syncCategory(tenantId: string, targetCategoryName, addToActiveProducts: boolean) : Observable<any>{
    this.epLoadingService.start();
    return this.epApiService.post<any>(`Integrations/SyncWooCategories`, {
      tenantId : tenantId,
      shouldAddToActiveProductsByCollectionList : addToActiveProducts,
      "filterOptions" : {
        "name" : targetCategoryName,
        "shouldEnrichProductIds" : true
    },
    "shouldSyncProducts" : true
    }).pipe(
      tap(data => { return; }),
      catchError(this.epApiService.handleError)
    );   
  }

  getCategories(tenantId: string) : Observable<ICategory[]>{
    this.epLoadingService.start();
    return this.epApiService.post<any>(`Integrations/FilterWooCategories`, {
      tenantId : tenantId      
    }).pipe(
      tap(data => { return; }),
      catchError(this.epApiService.handleError)
    );
  }

  getCollections(tenantId: string) : Observable<ICollection[]>{
    this.epLoadingService.start();
    return this.epApiService.post<any>(`Integrations/FilterShopifyCollections`, {
      tenantId : tenantId      
    }).pipe(
      tap(data => { return; }),
      catchError(this.epApiService.handleError)
    );   
  }

  getMerchants(tenantId: string) : Observable<IMerchant[]>{
    this.epLoadingService.start();
    return this.epApiService.get<any>(`Google/GetMerchantAccounts`, {
      tenantId : tenantId      
    }).pipe(
      tap(data => { return; }),
      catchError(this.epApiService.handleError)
    );   
  }
  /**
   * Once user picks a merchant, update the merchant settings on the backend
   */
  async updateGoogleMerchantSettings(merchantId: string): Promise<void> {
    this.epLoadingService.start();
    return new Promise((resolve, reject) => {
      this.epApiService.post('Google/UpdateMerchantSettings', { merchantId })
        .subscribe({
          next: () => {
            this.epLoadingService.stop();
            resolve();
          },
          error: err => {
            this.epLoadingService.stop();
            reject(err);
          }
        });
    });
  }

  /**
   * Allows the user to disconnect the storefront integration (e.g. Google Merchant)
   */
  async disconnectStoreIntegration(integrationType: IntegrationTypes): Promise<void> {
    this.epLoadingService.start();

    // For Google Merchant, your backend endpoint is GET /api/v1/Google/DisconnectMerchantIntegration
    // For Shopify / Woo, you might do something else. 
    // Example below specifically for Google:
    if (integrationType === IntegrationTypes.Google) {
      return new Promise((resolve, reject) => {
        this.epApiService.get('Google/DisconnectMerchantIntegration')
          .subscribe({
            next: () => {
              this.epLoadingService.stop();
              resolve();
            },
            error: err => {
              this.epLoadingService.stop();
              reject(err);
            }
          });
      });
    }

    // Otherwise, add your logic for the other integrations
    this.epLoadingService.stop();
    throw new Error(`Disconnect is not implemented for ${integrationType.toString()}`);
  }

  /**
   * Disconnect the analytics integration (Google Analytics).
   */
  async disconnectAnalyticsIntegration(analyticsIntegrationType: AnalyticsIntegrationTypes): Promise<void> {
    this.epLoadingService.start();
    // For Google Analytics, your controller has GET /api/v1/Google/DisconnectAnalyticsIntegration
    return new Promise((resolve, reject) => {
      this.epApiService.get('Google/DisconnectAnalyticsIntegration')
        .subscribe({
          next: () => {
            this.epLoadingService.stop();
            resolve();
          },
          error: err => {
            this.epLoadingService.stop();
            reject(err);
          }
        });
    });
  }


  //analytics

  updateAnalyticsSettings(analyticsPropertyId: string, googleAdsCustomerId: string): Promise<void> {
    this.epLoadingService.start();
    const payload = { analyticsPropertyId, googleAdsCustomerId };
    return new Promise((resolve, reject) => {
      this.epApiService.post("Google/UpdateAnalyticsSettings", payload)
        .subscribe({
          next: () => {
            this.epLoadingService.stop();
            resolve();
          },
          error: err => {
            this.epLoadingService.stop();
            reject(err);
          }
        });
    });
  }
  
  getAnalyticsIntegrations(): Observable<IAnalyticsIntegration[]> {
    this.epLoadingService.start();
    return this.epApiService.get<IAnalyticsIntegration[]>("AnalyticsIntegrations")
      .pipe(
        tap(data => { this.epLoadingService.stop(); }),
        catchError(this.epApiService.handleError)
      );
  }

  getAnalyticsProperties(): Observable<IGoogleAnalyticsProperty[]> {
    this.epLoadingService.start();
    return this.epApiService.get<IGoogleAnalyticsProperty[]>(`Google/GetAnalyticsProperties`)
      .pipe(
        tap(_ => this.epLoadingService.stop()),
        catchError(this.epApiService.handleError)
      );
  }

  getGoogleAdsCustomers(): Observable<IGoogleAdsCustomer[]> {
    this.epLoadingService.start();
    return this.epApiService.get<IGoogleAdsCustomer[]>(`Google/GetGoogleAdsCustomers`)
      .pipe(
        tap(_ => this.epLoadingService.stop()),
        catchError(this.epApiService.handleError)
      );
  }

  async loadData() {
    this.epApiService.get<IIntegration[]>("Integrations").subscribe((integrations: IIntegration[]) => {
      this.integrations = integrations;
      this.integrationsLoaderEventEmitter.emit();
    });
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error has occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status},error message is : ${err.message}`;
    }
    console.error(errorMessage);
  }
}

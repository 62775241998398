import { Injectable } from '@angular/core';
import { Country } from '@angular-material-extensions/select-country';
import { ICurrency } from '../interfaces/ICurrency';


@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  currencies: ICurrency[] = [
    {
      code: "USD",
      symbol: "$",
      displayName: "$ - US Dollar"
    },
    {
      code: "EUR",
      symbol: "€",
      displayName: "€ - Euro"
    },
    {
      code: "GBP",
      symbol: "£",
      displayName: "£ - Pound"
    },
    {
      code: "AUD",
      symbol: "$",
      displayName: "$ - Australian Dollar"
    },
    {
      code: "CAD",
      symbol: "$",
      displayName: "$ - Canadian dollar"
    },
    {
      code: "BRL",
      symbol: "R$",
      displayName: "R$ - Brazilian Real"
    },
    {
      code: "ILS",
      symbol: "₪",
      displayName: "₪ - Israeli Shekel - שקל"
    },
    {
      code: "NZD",
      symbol: "NZ$",
      displayName: "NZ$ - New Zealand Dollar"
    },
    {
      code: "RON",
      symbol: "RON",
      displayName: "RON - Romanian Leu",
    },
    {
      code: "SEK",
      symbol: "kr",
      displayName: "kr - Swedish Krona",
    },
    {
      code: "NOK",
      symbol: "kr",
      displayName: "nok - Norwegian Krone",
    },
    {
      code: "INR",
      symbol: "Rs",
      displayName: "Rs - Indian Rupee",
    },
    {
      code: "MXN",
      symbol: "MX$",
      displayName: "MX$ - Mexican Peso",
    },  
    {
      code: "PLN",
      symbol: "zł",
      displayName: "zł - Polish Złoty"
    },
    {
      code: "CHF",
      symbol: "CHF",
      displayName: "CHF - Swiss Franc"
    },
    {
      code: "CZK",
      symbol: "Kč",
      displayName: "Kč - Czech Koruna"
    },
    {
      code: "DKK",
      symbol: "kr",
      displayName: "kr - Danish Krone"
    },
    {
      code: "HUF",
      symbol: "Ft",
      displayName: "Ft - Hungarian Forint"
    },
    {
      "code": "AED",
      "symbol": "dh",
      "displayName": "DH - UAE Dirham"
    },
    {
      code: "ISK",
      symbol: "kr",
      displayName: "kr - Icelandic Króna"
    },
    {
      code: "HRK",
      symbol: "kn",
      displayName: "kn - Croatian Kuna"
    },
    {
      code: "RUB",
      symbol: "₽",
      displayName: "₽ - Russian Ruble"
    },
    {
      code: "TRY",
      symbol: "₺",
      displayName: "₺ - Turkish Lira"
    },
    {
      code: "UAH",
      symbol: "₴",
      displayName: "₴ - Ukrainian Hryvnia"
    },
    {
      code: "BGN",
      symbol: "лв",
      displayName: "лв - Bulgarian Lev"
    },
    {
      code: "ALL",
      symbol: "L",
      displayName: "L - Albanian Lek"
    },
    {
      code: "BAM",
      symbol: "KM",
      displayName: "KM - Bosnia and Herzegovina Convertible Mark"
    },
    {
      code: "BYN",
      symbol: "Br",
      displayName: "Br - Belarusian Ruble"
    },
    {
      code: "GEL",
      symbol: "₾",
      displayName: "₾ - Georgian Lari"
    },
    {
      code: "MDL",
      symbol: "L",
      displayName: "L - Moldovan Leu"
    },
    {
      code: "MKD",
      symbol: "ден",
      displayName: "ден - Macedonian Denar"
    },
    {
      code: "RSD",
      symbol: "дин",
      displayName: "дин - Serbian Dinar"
    },
    {
      code: "JMD",
      symbol: "J$",
      displayName: "J$ - Jamaican Dollar"
    },
    {
      code: "TTD",
      symbol: "TT$",
      displayName: "TT$ - Trinidad and Tobago Dollar"
    },
    {
      code: "BBD",
      symbol: "$",
      displayName: "$ - Barbadian Dollar"
    },
    {
      code: "BSD",
      symbol: "$",
      displayName: "$ - Bahamian Dollar"
    },
    {
      code: "DOP",
      symbol: "RD$",
      displayName: "RD$ - Dominican Peso"
    },
    {
      code: "GTQ",
      symbol: "Q",
      displayName: "Q - Guatemalan Quetzal"
    },
    {
      code: "HNL",
      symbol: "L",
      displayName: "L - Honduran Lempira"
    },
    {
      code: "HTG",
      symbol: "G",
      displayName: "G - Haitian Gourde"
    },
    {
      code: "NIO",
      symbol: "C$",
      displayName: "C$ - Nicaraguan Córdoba"
    },
    {
      code: "PAB",
      symbol: "B/.",
      displayName: "B/. - Panamanian Balboa"
    },
    {
      code: "CRC",
      symbol: "₡",
      displayName: "₡ - Costa Rican Colón"
    },
    {
      code: "CUP",
      symbol: "$",
      displayName: "$ - Cuban Peso"
    },
    {
      code: "KYD",
      symbol: "$",
      displayName: "$ - Cayman Islands Dollar"
    },
    {
      code: "BMD",
      symbol: "$",
      displayName: "$ - Bermudian Dollar"
    },
    {
      code: "ANG",
      symbol: "ƒ",
      displayName: "ƒ - Netherlands Antillean Guilder"
    },
    {
      code: "MDL",
      symbol: "L",
      displayName: "L - Moldovan Leu"
    },
    {
      code: "ZAR",
      symbol: "R",
      displayName: "R - South African Rand"
    }    
  ];

  supportedCountries: Country[] = [
    {
      name: 'United States of America',
      alpha2Code: 'US',
      alpha3Code: 'USA',
      numericCode: '840',
      callingCode: '+1'
    },
    {
      "name": "United Arab Emirates",
      "alpha2Code": "AE",
      "alpha3Code": "ARE",
      "numericCode": "784",
      "callingCode": "+971"
    },
    {
      name: 'United Kingdom',
      alpha2Code: 'GB',
      alpha3Code: 'GBR',
      numericCode: '826',
      callingCode: '+44'
    },
    {
      name: 'Australia',
      alpha2Code: 'AU',
      alpha3Code: 'AUS',
      numericCode: '036',
      callingCode: '+61'
    },
    {
      name: 'Canada',
      alpha2Code: 'CA',
      alpha3Code: 'CAN',
      numericCode: '124',
      callingCode: '+1'
    },
    {
      name: 'Germany',
      alpha2Code: 'DE',
      alpha3Code: 'DEU',
      numericCode: '276',
      callingCode: '+49'
    },
    {
      name: 'Hungary',
      alpha2Code: 'HU',
      alpha3Code: 'HUN',
      numericCode: '348',
      callingCode: '+36'
    },
    {
      name: 'Ireland',
      alpha2Code: 'IE',
      alpha3Code: 'IRL',
      numericCode: '372',
      callingCode: '+353'
    },
    {
      name: 'Italy',
      alpha2Code: 'IT',
      alpha3Code: 'ITA',
      numericCode: '380',
      callingCode: '+39'
    },
    {
      name: 'France',
      alpha2Code: 'FR',
      alpha3Code: 'FRA',
      numericCode: '250',
      callingCode: '+33'
    },
    {
      name: 'Mexico',
      alpha2Code: 'MX',
      alpha3Code: 'MEX',
      numericCode: '484',
      callingCode: '+52'
    },
    {
      name: 'Netherlands',
      alpha2Code: 'NL',
      alpha3Code: 'NLD',
      numericCode: '528',
      callingCode: '+31'
    },
    {
      name: 'New Zealand',
      alpha2Code: 'NZ',
      alpha3Code: 'NZL',
      numericCode: '554',
      callingCode: '+64'
    },
    {
      name: 'Norway',
      alpha2Code: 'NO',
      alpha3Code: 'NOR',
      numericCode: '578',
      callingCode: '+47'
    },
    {
      name: 'Poland',
      alpha2Code: 'PL',
      alpha3Code: 'POL',
      numericCode: '616',
      callingCode: '+48'
    },
    {
      name: 'Portugal',
      alpha2Code: 'PT',
      alpha3Code: 'PRT',
      numericCode: '620',
      callingCode: '+351'
    },
    {
      name: 'Puerto Rico',
      alpha2Code: 'PR',
      alpha3Code: 'PRI',
      numericCode: '630',
      callingCode: '+1'
    },
    {
      name: 'Russian Federation',
      alpha2Code: 'RU',
      alpha3Code: 'RUS',
      numericCode: '643',
      callingCode: '+7'
    },
    {
      name: 'Israel',
      alpha2Code: 'IL',
      alpha3Code: 'ISR',
      numericCode: '376',
      callingCode: '+972'
    },
    {
      name: 'Sweden',
      alpha2Code: 'SE',
      alpha3Code: 'SWE',
      numericCode: '752',
      callingCode: '+46'
    },
    {
      name: 'Switzerland',
      alpha2Code: 'CH',
      alpha3Code: 'CHE',
      numericCode: '756',
      callingCode: '+41'
    },
    {
      name: 'Turkey',
      alpha2Code: 'TR',
      alpha3Code: 'TUR',
      numericCode: '792',
      callingCode: '+90'
    },
    {
      name: 'Ukraine',
      alpha2Code: 'UA',
      alpha3Code: 'UKR',
      numericCode: '804',
      callingCode: '+380'
    },
    {
      name: 'United Kingdom of Great Britain',
      alpha2Code: 'GB',
      alpha3Code: 'GBR',
      numericCode: '826',
      callingCode: '+44'
    }, {
      name: 'Uruguay',
      alpha2Code: 'UY',
      alpha3Code: 'URY',
      numericCode: '858',
      callingCode: '+598'
    },
    {
      name: 'Brazil',
      alpha2Code: 'BR',
      alpha3Code: 'BRA',
      numericCode: '076',
      callingCode: '+55'
    },
    {
      name: 'Argentina',
      alpha2Code: 'AR',
      alpha3Code: 'ARG',
      numericCode: '032',
      callingCode: '+54'
    },
    {
      name: 'Armenia',
      alpha2Code: 'AM',
      alpha3Code: 'ARM',
      numericCode: '051',
      callingCode: '+374'
    },
    {
      name: 'Romania',
      alpha2Code: 'RO',
      alpha3Code: 'ROU',
      numericCode: '642',
      callingCode: '+40'
    },
    {
      name: 'India',
      alpha2Code: 'IN',
      alpha3Code: 'IND',
      numericCode: '356',
      callingCode: '+91'
    },
    {
      name: 'Spain',
      alpha2Code: 'ES',
      alpha3Code: 'ESP',
      numericCode: '724',
      callingCode: '+34'
    },
    {
      name: 'Greece',
      alpha2Code: 'GR',
      alpha3Code: 'GRC',
      numericCode: '300',
      callingCode: '+30'
    },
    {
      name: 'Belgium',
      alpha2Code: 'BE',
      alpha3Code: 'BEL',
      numericCode: '056',
      callingCode: '+32'
    },
    {
      name: 'Czech Republic',
      alpha2Code: 'CZ',
      alpha3Code: 'CZE',
      numericCode: '203',
      callingCode: '+420'
    },
    {
      name: 'Denmark',
      alpha2Code: 'DK',
      alpha3Code: 'DNK',
      numericCode: '208',
      callingCode: '+45'
    },
    {
      name: 'Finland',
      alpha2Code: 'FI',
      alpha3Code: 'FIN',
      numericCode: '246',
      callingCode: '+358'
    },
    {
      name: 'Iceland',
      alpha2Code: 'IS',
      alpha3Code: 'ISL',
      numericCode: '352',
      callingCode: '+354'
    },
    {
      name: 'Luxembourg',
      alpha2Code: 'LU',
      alpha3Code: 'LUX',
      numericCode: '442',
      callingCode: '+352'
    },
    {
      name: 'Austria',
      alpha2Code: 'AT',
      alpha3Code: 'AUT',
      numericCode: '040',
      callingCode: '+43'
    },
    {
      name: 'United States Minor Outlying Islands',
      alpha2Code: 'UM',
      alpha3Code: 'UMI',
      numericCode: '581',
      callingCode: '+1'
    },
    {
      name: 'Greenland',
      alpha2Code: 'GL',
      alpha3Code: 'GRL',
      numericCode: '304',
      callingCode: '+299'
    },
    {
      name: 'Cuba',
      alpha2Code: 'CU',
      alpha3Code: 'CUB',
      numericCode: '192',
      callingCode: '+53'
    },
    {
      name: 'Dominican Republic',
      alpha2Code: 'DO',
      alpha3Code: 'DOM',
      numericCode: '214',
      callingCode: '+1-809, 1-829, 1-849'
    },
    {
      name: 'Haiti',
      alpha2Code: 'HT',
      alpha3Code: 'HTI',
      numericCode: '332',
      callingCode: '+509'
    },
    {
      name: 'Jamaica',
      alpha2Code: 'JM',
      alpha3Code: 'JAM',
      numericCode: '388',
      callingCode: '+1-876'
    },
    {
      name: 'Trinidad and Tobago',
      alpha2Code: 'TT',
      alpha3Code: 'TTO',
      numericCode: '780',
      callingCode: '+1-868'
    },
    {
      name: 'Barbados',
      alpha2Code: 'BB',
      alpha3Code: 'BRB',
      numericCode: '052',
      callingCode: '+1-246'
    },
    {
      name: 'Tunisia',
      alpha2Code: 'TN',
      alpha3Code: 'TUN',
      numericCode: '788',
      callingCode: '+216'
    },
    {
      name: 'Moldova',
      alpha2Code: 'MD',
      alpha3Code: 'MDA',
      numericCode: '498',
      callingCode: '+373'
    },
    {
      name: 'South Africa',
      alpha2Code: 'ZA',
      alpha3Code: 'ZAF',
      numericCode: '710',
      callingCode: '+27'
    }    
  ];


  constructor() { }

  getCurrencies(): ICurrency[] {
    return this.currencies;
  }

  getCountries(): Country[] {
    return this.supportedCountries;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PricingPlanTypes } from 'app/common/enums/billing/PricingPlanTypes.enum';
import { IPricingPlan } from 'app/common/interfaces/billing/pricingPlan';
import { ProductsService } from 'app/common/services/product.service';
import { SessionService } from 'app/common/services/session.service';
import { pricingPlans } from 'static-data/pricing-plans';

@Component({
  selector: 'products-limit-navbar',
  templateUrl: './products-limit-navbar.component.html',
  styleUrls: ['./products-limit-navbar.component.scss'],
})
export class ProductsLimitNavbarComponent implements OnInit {
  currentPricingPlan: IPricingPlan;
  syncedProductsCount: number;
  allProductsCount: number;

  constructor(
    private productsService: ProductsService,
    private sessionService: SessionService,
    private router: Router) { }

  ngOnInit(): void {
    this.setProductsData();
    this.setCurrentPricingPlan();
    this.subscribeUserDataChange();
  }

  isShowProductsLimit(): boolean {
    this.sessionService.isProductsSyncNavbarShown$.next(this.sessionService.currentTenant?.pricingPlan === PricingPlanTypes.Basic && this.syncedProductsCount < this.allProductsCount);

    return this.sessionService.currentTenant?.pricingPlan === PricingPlanTypes.Basic && this.syncedProductsCount < this.allProductsCount;
  }

  navigateToBilling(): void {
    this.router.navigateByUrl('/pages/billing');
  }

  get progress(): number {
    if (!isNaN(this.syncedProductsCount) && !isNaN(this.allProductsCount)) {
      return Math.round((this.syncedProductsCount * 100) / this.allProductsCount);
    }
    return 0;
  }

  private async setProductsData() {
    const response = await this.productsService.getActiveProductsCount().toPromise();

    if (response?.count > 0) {
      this.syncedProductsCount = response.count;
      this.allProductsCount = this.sessionService.currentIntegration?.productsCount;
    }
  }

  private setCurrentPricingPlan(): void {
    const currentPricingPlan: PricingPlanTypes = this.sessionService.currentTenant?.pricingPlan;

    if (currentPricingPlan == null)
      return;

    this.currentPricingPlan = pricingPlans.find((pricingPlan: IPricingPlan) => {
      return currentPricingPlan === pricingPlan.pricingPlanType;
    });
  }

  private subscribeUserDataChange() {
    this.sessionService.userDataLoaderEventEmitter.subscribe(() => {
      this.setCurrentPricingPlan();
      this.allProductsCount = this.sessionService.currentIntegration?.productsCount;
    });
  }
}

import {NgModule} from '@angular/core';
import {ProductsLimitNavbarComponent} from './products-limit-navbar.component';
import {NbProgressBarModule} from '@nebular/theme';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    ProductsLimitNavbarComponent,
  ],
  exports: [
    ProductsLimitNavbarComponent,
  ],
  imports: [
    NbProgressBarModule,
    CommonModule,
  ],
})
export class ProductsSyncNavbarModule { }

import { Injectable, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { EpApiService } from './api/ep-api.service';
import { EpLoadingService } from './ep-loading.service';
import { saveAs as importedSaveAs } from 'file-saver';
import * as moment from 'moment';
import { AbnormalProducts } from '../enums/abnormal-products.enum';
import { CompetitorsService } from './competitors.service';
import { PricingEntityTypes } from '../enums/pricing-entity-type.enum';
import { PricePositioningLabel, PricePositioningType } from '../enums/price-positioning.enum';
import { ISearchableProductActivationRequest } from '../interfaces/searchableProducts/ISearchableProductActivationRequest';
import { IVendorSearchMetadata } from '../interfaces/searchableProducts/IVendorSearchMetadata';
import { ICategory } from '../interfaces/ICategory';
import { ICollection } from '../interfaces/ICollection';
import {IAbnormalProductsResponse, ISearchableProduct} from '../interfaces/ISearchableProduct';
import { IComparableProductData } from '../interfaces/IComparableProductData';
import { IProductPriceData } from '../interfaces/IProductPriceData';
import { IProvisionResult } from '../interfaces/IProvision-result';
import { IProduct } from '../interfaces/IProduct';
import { searchableProductsRepository, SearchableProductsRepository } from './state/searchableProducts.repository';
import { ILastSearchMetadata } from '../interfaces/searchableProducts/ILastSearchMetadata';
import { ToastrService } from './toastr.service';
import { NbGlobalPhysicalPosition } from '@nebular/theme';
import { ISearchableProductsRequest } from '../interfaces/requests/ISearchableProductsRequest';
import { ISearchableProductsResponse } from '../interfaces/responses/ISearchableProductsResponse';
import { GwLocalStorageService, StorageDict } from './gw-local-storage.service';
import { ReportsService } from './reports.service';
import { IProvisioningStatus } from '../interfaces/IProvisioningStatus';

const SortTypeASC: string = 'asc';
const SortTypeDESC: string = 'desc';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {

  constructor(private epApiService: EpApiService,
    private epLoadingService: EpLoadingService,
    private competitorsService: CompetitorsService,
    private reportsService: ReportsService,
    private toastrService: ToastrService,
    private localStorageService: GwLocalStorageService) {
  }

  categories: ICategory[] = [];
  brands: string[] = [];
  collections: ICollection[] = [];
  categoriesFilters: ICategory[] = [];
  brandsFilters: string[] = [];
  collectionFilters: string[] = [];
  competitorsFilters: string[] = [];

  categoriesListChangedEmitter = new EventEmitter<Object>();
  brandsListChangedEmitter = new EventEmitter<Object>();
  collectionsChangedEmitter = new EventEmitter<Object>();

  // products: ISearchableProduct[];
  productsRepository: SearchableProductsRepository = searchableProductsRepository;
  currentProduct: ISearchableProduct;

  isLoadingSearchableProducts: boolean;
  isProvisioningStatusLoopRunning: boolean = false;
  provisioningStatus: IProvisioningStatus;

  filteredProducts: ISearchableProduct[];

  get isProductsPriceView(): boolean {
    return this.localStorageService.get(StorageDict.percentagePriceConverterValue) === 'price';
  }

  get products(): ISearchableProduct[] {
    return this.productsRepository.getAllProducts();
  }

  set products(searchableProducts: ISearchableProduct[]) {
    this.productsRepository.setProducts(searchableProducts);
  }

  get productsListChangedEmitter(): EventEmitter<Object> {
    return this.productsRepository.productsListChangedEmitter;
  }

  get CategoriesFilters(): ICategory[] {
    return this.categoriesFilters;
  }

  get BrandsFilters(): string[] {
    return this.brandsFilters;
  }

  get CollectionFilters(): string[] {
    return this.collectionFilters;
  }

  get CompetitorsFilters(): string[] {
    return this.competitorsFilters;
  }

  addProductsFilters(pricingEntityType: PricingEntityTypes, entityIdOrName: string): void {
    switch (pricingEntityType) {
      case PricingEntityTypes.Brands:
        this.addBrandsFilters(entityIdOrName);
        break;
      case PricingEntityTypes.Categories:
        this.addCategoriesFilters(entityIdOrName);
        break;
      case PricingEntityTypes.Collections:
        this.addCollectionFilters(entityIdOrName);
        break;
      case PricingEntityTypes.Competitors:
        this.addCompetitorsFilters(entityIdOrName);
    }
  }

  getProvisioningStatus(): Observable<any> {
    return this.epApiService.post('Tenants/ProvisioningStatus', {}).pipe(
      catchError(this.handleError),
    );
  }

  addCategoriesFilters(categoryIdOrName: ICategory | string): void {
    ((typeof categoryIdOrName) === 'string') ?
      this.categoriesFilters.push(this.findCategoryByName(<string>categoryIdOrName)) : this.categoriesFilters.push(<ICategory>categoryIdOrName);
  }

  addBrandsFilters(brandId: string): void {
    this.brandsFilters.push(brandId);
  }

  addCollectionFilters(collectionId: string): void {
    this.collectionFilters.push(collectionId);
  }

  addCompetitorsFilters(competitorName: string): void {
    this.competitorsFilters.push(this.competitorsService.findCompetitorByName(competitorName)?.name);
  }

  getEntityIdName(entityType: PricingEntityTypes, entityId: string): string {
    switch (entityType) {
      case PricingEntityTypes.Products:
        return this.getProductTitle(entityId);
      case PricingEntityTypes.Categories:
        return this.categories?.find((category: ICategory) => category.id === entityId)?.name;
      case PricingEntityTypes.Brands:
        return this.brands?.find((brand: string) => brand === entityId);
      case PricingEntityTypes.Collections:
        return this.collections?.find((collection: ICollection) => collection.id === entityId)?.name;
    }
  }

  getProductImageUrl(productId: string): string {
    const product = this.productsRepository.getById(productId);
    return product?.imageUrl;
  }

  getProductTitle(productId: string): string {
    const product = this.productsRepository.getById(productId);
    return product?.title;
  }

  getProductBrand(productId: string): string {
    const product = this.productsRepository.getById(productId);
    return product?.brand;
  }

  getComparableProducts(data: IComparableProductData): Observable<IProduct[]> {
    return this.epApiService.post<IProduct[]>('ComparableProducts/GetAllLastSearch', data).pipe(
      catchError(this.handleError),
    );
  }

  getSearchableProducts(request: ISearchableProductsRequest): Observable<ISearchableProductsResponse> {
    this.isLoadingSearchableProducts = true;
    return this.epApiService.post<ISearchableProductsResponse>('SearchableProducts/GetAll', request).pipe(
      tap((data) => {
        this.isLoadingSearchableProducts = false;
        return;
      }),
      catchError(err => {
        this.isLoadingSearchableProducts = false;
        return this.handleError(err);
      }),
    );
  }

  getAbnormalProducts(showOnlyVerified: boolean = false): Observable<IAbnormalProductsResponse> {
    return this.epApiService.get<IAbnormalProductsResponse>(`Overview/PriceGaps?showOnlyVerified=${showOnlyVerified}`).pipe(
      catchError(err => {
        return this.handleError(err);
      }),
    );
  }

  getActiveProductsCount(): Observable<any> {
    return this.epApiService.get<any>('SearchableProducts/Count').pipe(
      catchError(err => {
        return this.handleError(err);
      }),
    );
  }

  getSearchableProduct(searchableProductId: string): Observable<ISearchableProduct> {
    this.isLoadingSearchableProducts = true;
    return this.epApiService.get<ISearchableProduct>(`SearchableProducts/${searchableProductId}`).pipe(
      tap((data) => {
        this.isLoadingSearchableProducts = false;
        return;
      }),
      catchError(err => {
        this.isLoadingSearchableProducts = false;
        return this.handleError(err);
      }),
    );
  }

  triggerSearchableProductUpdate(searchableProductId: string): Observable<any> {
    this.epLoadingService.start();
    return this.epApiService.post<ISearchableProduct>(`SearchableProducts/TriggerSearchableProductUpdateDirect`, {
      searchableProductId: searchableProductId,
    }).pipe(
      tap(data => {
        this.epLoadingService.stop('Product\'s competitors update trigged successfully and will be done in the next few minutes');
        return;
      }),
      catchError(error => {
        this.epLoadingService.stop('An error occurred while triggering the product update.');
        return this.handleError(error);
      }),
      finalize(() => {
        this.epLoadingService.stop(); // Ensure loading is stopped if not already stopped
      }),
    );
  }


  triggerSearchableProductUpdateBulkAsync(searchableProductIds: string[]): Observable<any> {
    this.epLoadingService.start();
    return this.epApiService.post<ISearchableProduct>(`SearchableProducts/TriggerSearchableProductUpdate`, {
      searchableProductIds,
      shouldCleanCache: true,
    }).pipe(
      tap(data => {
        this.epLoadingService.stop('Product\'s competitors update trigged successfully and will be done in the next few minutes');
        return;
      }),
      catchError(this.handleError));
  }

  createSearchableProduct(productToCreate: ISearchableProduct): Observable<ISearchableProduct> {
    const productToCreateDto = {
      ...productToCreate,
    };
    delete productToCreateDto['id'];
    this.epLoadingService.start();
    return this.epApiService.post<ISearchableProduct>('SearchableProducts', productToCreateDto).pipe(tap(() => {
      this.epLoadingService.stop('Created Successfully');
    }));
  }


  addOrUpdateSearchableProduct(productToCreate: ISearchableProduct): Observable<ISearchableProduct> {
    const productToCreateDto = {
      ...productToCreate,
    };
    this.epLoadingService.start();
    return this.epApiService.post<ISearchableProduct>('SearchableProducts/AddOrUpdate', productToCreateDto).pipe(tap(() => {
      this.epLoadingService.stop('Updated Successfully');
    }));
  }

  updateSearchableProduct(productToUpdate: ISearchableProduct): Observable<ISearchableProduct> {
    this.epLoadingService.start();
    return this.epApiService.patch<ISearchableProduct>(`SearchableProducts/${productToUpdate.id}`, productToUpdate).pipe(
      tap(() => {
        this.epLoadingService.stop('Updated Successfully');
      },
        catchError(this.epApiService.handleError),
      ));
  }

  updateSearchableProductNotes(id: string, notes: string): Observable<ISearchableProduct> {
    this.epLoadingService.start();
    return this.epApiService.patch<ISearchableProduct>(`SearchableProducts/${id}`, { note: notes }).pipe(
      tap(() => {
        this.epLoadingService.stop('Notes Saved');
      },
        catchError(this.epApiService.handleError),
      ));
  }

  updateProductPrice(data: IProductPriceData): Observable<any> {
    this.epLoadingService.start();
    return this.epApiService.post<IProductPriceData>('Rules/UpdatePrice', data).pipe(
      tap(() => {
        this.products.find((product: ISearchableProduct) => product.id === data.searchableProductId).price = data.targetPrice;
        this.toastrService.showToast('Product price was updated!', 'Success', 'success', NbGlobalPhysicalPosition.TOP_RIGHT);
      }),
      catchError(this.handleError),
    );
  }

  deleteSearchableProduct(idToDelete: string): Observable<any> {
    this.epLoadingService.start();
    return this.epApiService.delete<ISearchableProduct>(`SearchableProducts/${idToDelete}`).pipe(tap(() => {
      this.epLoadingService.stop('Deleted Successfully');
    }));
  }

  separateVariants(productId: string): Observable<any> {
    this.epLoadingService.start();
    return this.epApiService.post(`SearchableProducts/SeparateVariants/${productId}`, {
    }).pipe(
      tap(() => {
        this.epLoadingService.stop('Successfully Seperated product to variants, product list will be updated after refresh');
      }),
      catchError(this.handleError),
    );
  }

  separateSingleVariant(searchableProductId: string, variantExternalId: string): Observable<any> {
    this.epLoadingService.start();
    return this.epApiService.post(`SearchableProducts/SeparateSingleVariant/${searchableProductId}`, {
      variantExternalId,
    }).pipe(
      tap(() => {
        this.epLoadingService.stop('Successfully Separated a Single Variant variants, product list will be updated after refresh');
      }),
      catchError(this.handleError),
    );
  }

  deleteBulkSearchableProducts(productIds: string[]): Observable<any> {
    this.epLoadingService.start();
    return this.epApiService.post(`SearchableProducts/DeleteBulk`, { 'productIds': productIds }).pipe(
      tap(() => {
        this.epLoadingService.stop('Deleted Successfully');
      }),
      catchError(this.handleError),
    );
  }

  downloadComparableProducts(productData: IComparableProductData): void {
    this.epLoadingService.start();
    this.epApiService.downloadFileWithPost(`ComparableProducts/Download`, productData).subscribe(blob => {
      importedSaveAs(blob, `${productData.title}-${moment().format('YYYY-MM-DD_HH-mm')}.csv`);
      this.epLoadingService.stop('Downloaded Successfully');
    });
  }

  uploadSearchableProductsCSV(file: File): Observable<any> {
    this.epLoadingService.start();
    return this.epApiService.uploadFile(`SearchableProductsProvision/SyncCsv`, file).pipe(
      tap((results: IProvisionResult[]) => {
        this.epLoadingService.stop();
        this.toastrService.showToast(
          'The upload completed successfully, ' + results?.length ?? 0 + ' products have been updated.',
          'Success',
          'success',
          NbGlobalPhysicalPosition.TOP_RIGHT,
        );
      }),
      catchError(err => {
        this.epLoadingService.stop();
        this.toastrService.showToast(
          'Something went wrong in uploading file operation! Please try again later.',
          'Error',
          'danger',
          NbGlobalPhysicalPosition.TOP_RIGHT,
          true,
        );
        return this.handleError(err);
      }),
    );
  }

  getCategories(): void {
    this.epApiService.get<ICategory[]>('Categories').subscribe((categories: ICategory[]) => {
      this.categories = categories;
      this.categoriesListChangedEmitter.emit();
    });
  }

  getBrands(): void {
    this.epApiService.get<string[]>('Brands').subscribe((brands: string[]) => {
      this.brands = brands;
      this.brandsListChangedEmitter.emit();
    });
  }

  getCollections(): void {
    this.epApiService.get<ICollection[]>('Collections').subscribe((collections: ICollection[]) => {
      this.collections = collections;
      this.collectionsChangedEmitter.emit();
    });
  }

  updateLastSearchForProduct(searchableProductId: string, lastSearchMetadata: ILastSearchMetadata) {
    const searchableProduct = this.productsRepository.getById(searchableProductId);
    if (searchableProduct == null) {
      return;
    }

    searchableProduct.lastSearch = lastSearchMetadata;
    this.addOrUpdateProductOnRepo(searchableProduct);
  }

  async saveProduct(product: ISearchableProduct): Promise<ISearchableProduct> {
    return await this.addOrUpdateSearchableProduct(product).toPromise();
  }

  addOrUpdateProductOnRepo(product: ISearchableProduct) {
    this.calculateAverageDifference(product);
    this.calculateGoogleShoppingRanks(product);
    this.productsRepository.addOrUpdateProduct(product);
    this.productsListChangedEmitter.emit();
  }

  findIndexOfProduct(productId: string): number {
    for (let index = 0; index < this.products.length; index++) {
      if (this.products[index].id === productId) {
        return index;
      }
    }
    return -1;
  }

  // loadProducts(): void {
  //   this.isLoadingSearchableProducts = true;
  //   this.getSearchableProducts().subscribe({
  //     next: products => {
  //       products.forEach((product: ISearchableProduct) => {
  //         this.calculateAverageDifference(product);
  //       })
  //       this.products = products.sort(this.compareAverageDifference);
  //       this.productsListChangedEmitter.emit();
  //     },
  //     error: err => {
  //       console.log(err)
  //       this.isLoadingSearchableProducts = false;
  //     },
  //     complete: () => {
  //       this.isLoadingSearchableProducts = false;
  //     }
  //   });
  // }
  calculateGoogleShoppingRanks(product: ISearchableProduct): void {
    if (product?.lastSearch?.googleShopping) {
      const currentEntries = product.lastSearch.googleShopping.current ?? [];
      const historyEntries = product.lastSearch.googleShopping.history ?? [];

      // Initialize ranks
      let currentPageRank: number | undefined;
      let currentSearchRank: number | undefined;
      let currentPaidRank: number | undefined;

      // Set current ranks
      for (const entry of currentEntries) {
        if (entry.pageRank !== null && currentPageRank === undefined) {
          currentPageRank = entry.pageRank;
        }
        if (entry.searchRank !== null && currentSearchRank === undefined) {
          currentSearchRank = entry.searchRank;
        }
        if (entry.paidRank !== null && currentPaidRank === undefined) {
          currentPaidRank = entry.paidRank;
        }
      }

      product.currentPageRank = currentPageRank;
      product.currentSearchRank = currentSearchRank;
      product.currentPaidRank = currentPaidRank;

      // Initialize previous ranks
      let previousPageRank: number | undefined;
      let previousSearchRank: number | undefined;
      let previousPaidRank: number | undefined;

      // Handle history
      if (historyEntries.length === 1) {
        // If there's only one entry in history, treat it as current
        product.previousPageRank = undefined;
        product.previousSearchRank = undefined;
        product.previousPaidRank = undefined;
      } else if (historyEntries.length > 1) {
        // If the current ID matches the first history ID, set previous to null
        if (currentEntries.length > 0 && historyEntries[0].some(entry => currentEntries.some(curr => curr.id === entry.id))) {
          product.previousPageRank = null;
          product.previousSearchRank = null;
          product.previousPaidRank = null;
        } else {
          // Otherwise, set the previous ranks
          for (const entry of historyEntries[0]) {
            if (entry.pageRank !== null && previousPageRank === undefined) {
              previousPageRank = entry.pageRank;
            }
            if (entry.searchRank !== null && previousSearchRank === undefined) {
              previousSearchRank = entry.searchRank;
            }
            if (entry.paidRank !== null && previousPaidRank === undefined) {
              previousPaidRank = entry.paidRank;
            }
          }
          product.previousPageRank = previousPageRank;
          product.previousSearchRank = previousSearchRank;
          product.previousPaidRank = previousPaidRank;
        }
      } else {
        product.previousPageRank = undefined;
        product.previousSearchRank = undefined;
        product.previousPaidRank = undefined;
      }
    } else {
      product.currentPageRank = undefined;
      product.currentSearchRank = undefined;
      product.currentPaidRank = undefined;
      product.previousPageRank = undefined;
      product.previousSearchRank = undefined;
      product.previousPaidRank = undefined;
    }
  }

  loadProducts(): void {
    this.isLoadingSearchableProducts = true;
    this.getSearchableProducts({}).subscribe((response) => {
      if (response) {
        if (response.products) {
          response.products.forEach((product: ISearchableProduct) => {
            this.calculateAverageDifference(product);
            this.calculateGoogleShoppingRanks(product);
          });
          this.products = (this.isProductsPriceView ?
            response.products.sort(this.compareAveragePriceDifference) :
            response.products.sort(this.compareAveragePercentageDifference)
          );
          this.productsListChangedEmitter.emit();
        }
      }
      this.isLoadingSearchableProducts = false;
    },
      (err) => {
        console.log(err);
        this.isLoadingSearchableProducts = false;
      },
      () => {
        this.isLoadingSearchableProducts = false;
      });
  }

  async filterProductsFromApi(searchableProductsRequest?: ISearchableProductsRequest): Promise<ISearchableProductsResponse> {
    try {
      const response = await this.getSearchableProducts(searchableProductsRequest).toPromise();

      if (response) {
        if (response.products) {
          response.products.forEach((product: ISearchableProduct) => {
            if (product.imageUrl?.indexOf('cdn.shopify.com') > -1) {
              product.imageUrl = product.imageUrl + '&width=150&height=150';
            }
            this.calculateAverageDifference(product);
            this.calculateGoogleShoppingRanks(product);
          });
          this.filteredProducts = (this.isProductsPriceView ?
            response.products.sort(this.compareAveragePriceDifference) :
            response.products.sort(this.compareAveragePercentageDifference)
          );
        }
      }
      this.isLoadingSearchableProducts = false;
      return {
        products: this.filteredProducts,
        pagination: response.pagination,
      };
    } catch (err) {
      console.log(err);
      this.isLoadingSearchableProducts = false;
    } finally {
      this.isLoadingSearchableProducts = false;
    }
  }

  deleteProduct(id: string): void {
    this.deleteSearchableProduct(id).subscribe();
    const productToDeleteId: string = this.products.find(product => product.id === id).id;
    this.products = this.products.filter(product => product.id !== productToDeleteId);
    this.productsListChangedEmitter.emit();
  }

  deleteBulkProducts(productIds: string[]): void {
    this.deleteBulkSearchableProducts(productIds).subscribe();
    this.products = this.products.filter(product => !productIds.includes(product.id));
    this.productsListChangedEmitter.emit();
  }

  refreshData() {
    // this.loadProducts();
    this.competitorsService.loadCompetitors();
    this.reportsService.loadReports();
    this.getCategories();
    this.getBrands();
    this.getCollections();
  }

  resetProductsPageFilters(): void {
    this.categoriesFilters = [];
    this.brandsFilters = [];
    this.collectionFilters = [];
    this.competitorsFilters = [];
  }

  async changeProductActivation(searchableProductId: string, searchableProductName: string, isActive: boolean) {
    const request: ISearchableProductActivationRequest = {
      id: null,
      isActive,
    };

    try {
      this.epLoadingService.start();
      await this.epApiService.patch(`SearchableProducts/${searchableProductId}`, request).toPromise();
      this.epLoadingService.stop(`Product: ${searchableProductName} ${isActive ? 'activated' : 'disabled'}`);
    } catch (ex) {
      this.epLoadingService.stop(`Failed to update product: ${searchableProductName}`);
      throw ex;
    }
  }

  // todo: change name to "prepareProduct"
  calculateAverageDifference(product: ISearchableProduct): void {
    // Calculate unitPrice if not already set
    if (product.unitPrice == 0 && product.packageQuantity > 0) {
      product.unitPrice = product.price / product.packageQuantity;
    } else {
      product.packageQuantity = 1;
    }

    // Check if last search data is available
    if (product.lastSearch == null || product.lastSearch.total == null || !product.lastSearch.isCompetitionFound) {
      product.averagePercentageDifference = 0;
      product.averagePriceDifference = 0;
      product.averageDifferenceByVendors = [];
      return;
    }

    // Determine the target property to use for calculations
    const targetProperty = product.lastSearch.targetProperty == 'unitPrice' ? 'unitPrice' : 'price';
    const productPrice = product[targetProperty];

    // Calculate average percentage and price differences using the target property
    product.averagePercentageDifference = this.calcPercentageDifference(productPrice, product.lastSearch.total.averagePrice);
    product.averagePriceDifference = this.calcPriceDifference(productPrice, product.lastSearch.total.averagePrice);

    // Check if vendor data is available
    if (product.lastSearch?.vendors == null || product.lastSearch?.vendors.length == 0) {
      return;
    }

    // Calculate average differences by vendors
    const averageDifferenceByVendors: IAverageDifferenceByVendor[] = [];
    product.lastSearch.vendors.forEach((vendorData: IVendorSearchMetadata) => {
      averageDifferenceByVendors.push({
        vendor: vendorData.vendor,
        price: vendorData.lowestPrice, // This price is already based on the target property from the backend
        averagePercentageDifference: this.calcPercentageDifference(productPrice, vendorData.averagePrice),
        averagePriceDifference: this.calcPriceDifference(productPrice, vendorData.averagePrice),
      });
    });

    // Sort the average differences by price difference or percentage difference
    product.averageDifferenceByVendors = this.isProductsPriceView
      ? averageDifferenceByVendors.sort((a, b) => b.averagePriceDifference - a.averagePriceDifference)
      : averageDifferenceByVendors.sort((a, b) => b.averagePercentageDifference - a.averagePercentageDifference);
  }

  private findCategoryByName(categoryName: string): ICategory {
    return this.categories.find((category: ICategory) => category.name === categoryName);
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error has occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status},error message is : ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  getSelectedAbnormalProduct(pricePositioning: string): AbnormalProducts {
    const positioning = PricePositioningLabel.get(pricePositioning);

    if (positioning === null) { return null; }

    if (positioning === PricePositioningType.Higher) return AbnormalProducts.HigherThanAverage;
    if (positioning === PricePositioningType.Cheaper) return AbnormalProducts.CheaperThanAverage;
    if (positioning === PricePositioningType.Equal) return AbnormalProducts.EqualTo;
  }

  private calcPercentageDifference(baseNumber: number, differentNumber: number): number {
    return (baseNumber - differentNumber) / baseNumber * 100;
  }

  private calcPriceDifference(baseNumber: number, differentNumber: number): number {
    return baseNumber - differentNumber;
  }

  private compareAveragePercentageDifference(productA: ISearchableProduct, productB: ISearchableProduct): number {
    if (productA.averagePercentageDifference == null || productB.averagePercentageDifference == null)
      return 0;
    return Math.abs(productB.averagePercentageDifference) - Math.abs(productA.averagePercentageDifference);
  }

  private compareAveragePriceDifference(productA: ISearchableProduct, productB: ISearchableProduct): number {
    if (productA.averagePriceDifference == null || productB.averagePriceDifference == null)
      return 0;
    return Math.abs(productB.averagePriceDifference) - Math.abs(productA.averagePriceDifference);
  }

  sort(sortOrder: string, sortBy: any, sortByDataType: string, data: Array<any>) {
    if (data && data.length > 0) {
      if (sortOrder.toLowerCase() == SortTypeASC) {
        data.sort(function (a: any, b: any) {
          if (sortBy.toLowerCase() == 'self')
            return (sortByDataType.toLowerCase() == 'string' ? (a?.toLowerCase() > b?.toLowerCase() ? 1 : (a?.toLowerCase() === b?.toLowerCase() ? 0 : -1)) : (a > b ? 1 : (a === b ? 0 : -1)));
          return (sortByDataType.toLowerCase() == 'string' ? (a[sortBy]?.toLowerCase() > b[sortBy]?.toLowerCase() ? 1 : (a[sortBy]?.toLowerCase() === b[sortBy].toLowerCase() ? 0 : -1)) : (a[sortBy] > b[sortBy] ? 1 : (a[sortBy] === b[sortBy] ? 0 : -1)));
        });
      } else if (sortOrder.toLowerCase() == SortTypeDESC) {
        data.sort(function (a: any, b: any) {
          if (sortBy.toLowerCase() == 'self')
            return (sortByDataType.toLowerCase() == 'string' ? (a?.toLowerCase() > b?.toLowerCase() ? -1 : (a?.toLowerCase() === b?.toLowerCase() ? 0 : 1)) : (a > b ? -1 : (a === b ? 0 : 1)));
          return (sortByDataType.toLowerCase() == 'string' ? (a[sortBy]?.toLowerCase() > b[sortBy]?.toLowerCase() ? -1 : (a[sortBy]?.toLowerCase() === b[sortBy]?.toLowerCase() ? 0 : 1)) : (a[sortBy] > b[sortBy] ? -1 : (a[sortBy] === b[sortBy] ? 0 : 1)));
        });
      }
    }
    return data;
  }
}

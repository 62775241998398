import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IConfirmModalData} from '../../common/interfaces/IConfirmModalData';

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public modalData: IConfirmModalData,
              public matDialogRef: MatDialogRef<ConfirmModalComponent>,
  ) { }

}

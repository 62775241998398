import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ProductsService } from 'app/common/services/product.service';
import { SessionService } from 'app/common/services/session.service';
import { SignalRService } from 'app/common/services/signalRProduct.service';
import { Swiper } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

const WeAreSettingUpYourPlatform = "We Are Setting Up Your Platform";
const WeAreSettingUpYourPlatformIndex = 0;

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
// Now you can use Swiper
const swiper = new Swiper('.swiper-container', {
  speed: 500,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  // ...
});

@Component({
  selector: 'onboarding-dialog',
  templateUrl: './onboarding-dialog.component.html',
  styleUrls: ['./onboarding-dialog.component.scss']
})
export class OnBoardingDialogComponent implements OnInit {

  constructor(
    private sessionService: SessionService,
    private productsService: ProductsService,
    private signalRProductService : SignalRService) { }

  @ViewChild(SwiperComponent, { static: false }) compRef?:SwiperComponent;
  dialogMaxWidth = 700;
  shouldShow = false;
  title = null;
  isBackDropStatic: boolean = false;

  ngOnInit(): void {
    this.initOnboardingDialog();
    this.sessionService.userDataLoaderEventEmitter.subscribe((): void => this.initOnboardingDialog());
  }

  onSlideChange(swiper: Swiper): void {
    switch (swiper.activeIndex) {
      case WeAreSettingUpYourPlatformIndex:
        this.title = WeAreSettingUpYourPlatform;
        break;
      default:
        this.title = null;
    }
  }

  onSwiper(swiper: Swiper): void {

  }

  swipeNext() {
    this.compRef.swiperRef.slideNext();
  }

  startTour() {
    this.shouldShow = false;
    setTimeout(() => {
      this.launchOnboardingGuide();
    }, 500);
    this.loadData();
  }

  private async loadData() {
    this.productsService.refreshData();
    await this.signalRProductService.connect();
  }

  private launchOnboardingGuide() {
    // let onboardingGuide: any = pendo?.findGuideByName('OnBoarding');
    // if (onboardingGuide && this.isOnBoardingGuidSeen(onboardingGuide)) {
    //   onboardingGuide?.launch();
    // }
  }

  private isOnBoardingGuidSeen(onBoardingGuide: any): boolean {
    return onBoardingGuide?.steps?.find(x => x.hasBeenSeen()) == null;
  }

  private initOnboardingDialog() {
    if(this.sessionService.isFinishedOnBoarding){
      this.shouldShow = true;
      return;
    }

    // if (this.sessionService.currentTenant?.settings?.onBoardingOptions?.isDone) {
    //   if (this.sessionService.currentTenant?.settings?.onBoardingOptions?.isProvisioning) {
    //     this.shouldShow = false;
    //   }
    //   else {
    //     setTimeout(() => {
    //       this.launchOnboardingGuide();
    //     }, 500);
    //   }
    // }
    this.title = WeAreSettingUpYourPlatform;
  }
}

import {
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { IProduct } from "app/common/interfaces/IProduct";
import { IProductVariant } from "app/common/interfaces/IProductVariant";
import { ISearchableProduct } from "app/common/interfaces/ISearchableProduct";
import { TableColumn } from "app/common/interfaces/table-column.interface";
import { ProductsService } from "app/common/services/product.service";
import { SessionService } from "app/common/services/session.service";

@Component({
  selector: "product-variants",
  templateUrl: "./product-variants.component.html",
  styleUrls: ["./product-variants.component.scss"],
})
export class ProductVariantsComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() targetProduct: IProduct;
  @Input() key: string;

  _dataSource = new MatTableDataSource<IProductVariant>([]);

  columns: TableColumn[] = [
    { label: 'Image', property: 'imageUrl', type: 'image', visible: true },
    { label: "Title", property: "title", type: "text", visible: true },
    { label: "SKU", property: "sku", type: "text", visible: true }, 
    { label: "Price", property: "price", type: "text", visible: true },
    { label: "Cost", property: "cost", type: "text", visible: true },
    { label: "Available Quantity", property: "availableQuantity", type: "text", visible: true },
    { label: '', property: 'actions', type: 'text', visible: true }
  ];
  isLoading: boolean;

  constructor(private productsService: ProductsService, private sessionService : SessionService) {}

  ngOnInit(): void {
    this.loadData();
  }

  get displayedColumns(): string[] {    
    let columnsToShow = this.columns.filter((column) => column.visible);

    const shouldHideCost = this?.sessionService?.currentTenant?.settings?.viewOptions?.shouldHideCost;
    if(shouldHideCost){
      columnsToShow = columnsToShow.filter(x=> x.property != "cost");
    }    

    return columnsToShow.map(x => x.property);                
  }

  set dataSource(productVariants: IProductVariant[]) {
    this._dataSource = new MatTableDataSource<IProductVariant>(productVariants);
    if (this.sort && this.paginator) {
      this._dataSource.sort = this.sort;
      this._dataSource.paginator = this.paginator;
    }
  }

  get isAdmin(){
    return this.sessionService.isAdmin;
  }

  loadData(): void {
    if (!this.targetProduct) {
      return;
    }
    this.isLoading = true;
    this.productsService.getSearchableProduct(this.targetProduct.id).subscribe((searchableProduct: ISearchableProduct) => {
      this.dataSource = searchableProduct?.variants;
      this.isLoading = false;
    });
  }

  async separateSingleVariant(variant: IProductVariant) : Promise<void> {
    if(variant == null) return;

    await this.productsService.separateSingleVariant(this.targetProduct.id, variant.externalId).toPromise();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pm-ep-form-dialog',
  templateUrl: './ep-form-dialog.component.html',
  styleUrls: ['./ep-form-dialog.component.scss']
})
export class EpFormDialogComponent implements OnInit {

  private _shouldShow = false;
  @Input() set shouldShow(value: boolean) {
    this._shouldShow = value;
    this.shouldShowChange.emit(this._shouldShow);
  }
  get shouldShow(): boolean {
    return this._shouldShow;
  }
  @Input() title: string;
  @Input() dialogMaxWidth: number = null;
  @Input() cancelBtnTitle: string;
  @Input() submitBtnTitle: string;
  @Input() sendButtonDisabled: boolean;
  @Input() sendButtonHidden: boolean;
  @Input() isPricingStrategy: boolean = false;
  @Input() isPricingDiscount: boolean = false;
  @Input() isBackDropStatic: boolean = false;
  @Output() shouldShowChange: EventEmitter<boolean>;
  @Output() dialogClose: EventEmitter<any>;
  @Output() dialogCancel: EventEmitter<any>;

  constructor() {
    this.title = null;
    this.cancelBtnTitle = null;
    this.submitBtnTitle = null;
    this.sendButtonDisabled = false;
    this.shouldShowChange = new EventEmitter<boolean>();
    this.dialogClose = new EventEmitter();
    this.dialogCancel = new EventEmitter();
  }

  ngOnInit(): void {
    if (this.cancelBtnTitle === null) {
      this.cancelBtnTitle = 'Cancel';
    }
    if (this.submitBtnTitle === null) {
      this.submitBtnTitle = 'Submit';
    }
  }
  onDialogClose()
    : void {
    this.shouldShow = false;
    this.dialogClose.emit();
  }

  onDialogCancel()
    : void {
    this.dialogCancel.emit();
    this.onDialogClose();
  }

}

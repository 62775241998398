import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { GreenColorScheme } from 'app/common/constants';
import { ISeriesItem } from 'app/common/interfaces/ISeriesItem';
import { ProductsService } from 'app/common/services/product.service';
import {AnimationOptions} from 'ngx-lottie';
import {AbnormalProducts} from '../../common/enums/abnormal-products.enum';

@Component({
  selector: 'advanced-pie-chart',
  templateUrl: './advanced-pie-chart.component.html',
  styleUrls: ['./advanced-pie-chart.component.scss'],
})
export class AdvancedPieChartComponent implements AfterViewInit {
  @ViewChild('containerRef') containerRef: ElementRef;

  @Input() height: number = 400;
  @Input() data: ISeriesItem[] = [];
  @Input() itemsData = {};
  @Input() isLoading: boolean = false;
  @Input() colorScheme = {...GreenColorScheme};
  @Input() shouldShowTotalLegend: boolean = true;

  lottieOptions: AnimationOptions = {
    path: '/assets/files/lottie-star-loader.json',
    loop: true,
    autoplay: true,
  };
  multi: any[];
  view: any[] = null;

  constructor(private productService: ProductsService,
    private router: Router,
  ) { }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  onSelect(item): void {
    const value = this.itemsData[item.name.replace(/\s/g, '')];

    if (value !== null && value !== AbnormalProducts.EqualTo) {
      this.router.navigate(['/pages/products'], {queryParams: {abnormal: value}});
    }
  }

  onResize(event): void {
    if (this.elementRefWidth) {
      this.view = [this.elementRefWidth, this.height];
    }
  }

  get elementRefWidth(): number {
    return this.containerRef?.nativeElement?.offsetWidth;
  }
}

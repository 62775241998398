import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { IntegrationTypes } from 'app/common/enums/IntegrationTypes';
import { googleAndWooSyncEntitiesArray, syncEntitiesArray, SyncEntityType } from 'app/common/enums/SyncEntityType.enum';
import { ICategory } from 'app/common/interfaces/ICategory';
import { ICollection } from 'app/common/interfaces/ICollection';
import { IMerchant } from 'app/common/interfaces/IMerchant';
import { IntegrationsService } from 'app/common/services/integrations.service';
import { SessionService } from 'app/common/services/session.service';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pm-sync-form',
  templateUrl: './sync-form.component.html',
  styleUrls: ['./sync-form.component.scss']
})
export class SyncFormComponent implements OnInit, OnChanges {

  @Input() syncFormGroup: FormGroup;
  @Input() isFullWidth: boolean = false;

  @Output() formValidityChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  syncEntities: any[];
  allCollectionsSubscription: any;
  isChanged: boolean;
  syncEntitiesSelect: FormControl;
  merchantAccounts: IMerchant[] = [];
  selectedMerchantAccount: IMerchant;

  constructor(private integrationsService : IntegrationsService, private sessionService : SessionService) {
  }

  @Input()
  hideSyncButton : boolean;

  @Input()
  private _tenantId: string;

  @Input() set tenantId(value: string) {
    if(this._tenantId != value) {
      this._tenantId = value;
      if (this.isWooIntegrationType)
        this.reloadCategories();
      else
        this.reloadCollections();
    }
  }

  get tenantId(): string {
      return this._tenantId;
  }

  @Output()
  syncCollectionEmit: EventEmitter<object> = new EventEmitter<Object>();

  @Output() merchantAccountCountryEmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() merchantAccountCurrencyEmit: EventEmitter<string> = new EventEmitter<string>();

  isSubmitLoading = false;
  selectedSyncEntity: any;
  selectedCollection: ICollection;
  collections: ICollection[];
  merchants: IMerchant[];
  filteredCollections :  ReplaySubject<ICollection[]> = new ReplaySubject<ICollection[]>(1);

  selectedCategory: ICategory;
  categories: ICategory[];
  filteredCategories: ReplaySubject<ICategory[]> = new ReplaySubject<ICategory[]>(1);

  @Output()
  syncCategoryEmit: EventEmitter<object> = new EventEmitter<Object>();


  protected _onDestroy = new Subject<void>();

  ngOnInit() {
    this.initializeForms();
    this.initializeFormsData();
    this.collectionsSelectFilter.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCollections();
      });
    this.checkAndEmitValidity();

    this.initMerchantAccountsList();
    this.sessionService.userDataLoaderEventEmitter.subscribe(() => {
      this.initializeFormsData();
      // Load merchant accounts if needed
      this.reloadCollections();
      this.reloadCategories();
      this.initMerchantAccountsList();
    })
  }

  private initMerchantAccountsList() {
    if (this.shouldShowMerchantList) {
      this.reloadMerchantAccounts();
    }
  }

  async reloadCollections(){
    if(this.sessionService?.currentIntegration?.integrationType == IntegrationTypes.Shopify){
      const collections = await this.integrationsService.getCollections(this.tenantId).toPromise();
      this.loadCollections(collections);
    }
  }

  async reloadCategories() {
    if(this.sessionService?.currentIntegration?.integrationType == IntegrationTypes.Woo) {
      const categories = await this.integrationsService.getCategories(this.tenantId).toPromise();
      this.loadCategories(categories);
    }
  }

  async reloadMerchantAccounts() {
    if (this.sessionService?.currentIntegration?.integrationType === IntegrationTypes.Google) {
      try {
        const merchantAccounts = await this.integrationsService.getMerchants(this.tenantId).toPromise();
        this.loadMerchantAccounts(merchantAccounts);
      } catch (error) {
        console.error('Failed to load merchant accounts:', error);
      }
    }
  }

  private loadMerchantAccounts(merchants: IMerchant[]) {
    this.merchantAccounts = merchants;
    this.selectedMerchantAccount = merchants?.length > 0 ? merchants[0] : null;
    if (this.selectedMerchantAccount?.country)
      this.merchantAccountCountryEmit.emit(this.selectedMerchantAccount.country);
    if (this.selectedMerchantAccount?.currency)
      this.merchantAccountCurrencyEmit.emit(this.selectedMerchantAccount.currency);
  }


  ngOnChanges(changes: SimpleChanges) {
    // Check if selectedSyncEntity has changed
    if (changes.selectedSyncEntity && !changes.selectedSyncEntity.firstChange) {
      this.checkAndEmitValidity();
    }
  }

  // Function to handle selectedSyncEntity change
  onSelectedSyncEntityChange() {
    this.checkAndEmitValidity();
  }

  onSelectedMerchantAccountChange(selectedValue: IMerchant) {
    this.selectedMerchantAccount = selectedValue;
    // Additional logic if needed
  }

  checkAndEmitValidity() {
    const isFormValid = this.syncFormGroup.valid;

    if (this.shouldShowCollectionList) {
      const isCollectionValid = !!this.selectedCollection;
      const combinedValidity = isFormValid && isCollectionValid;
      this.formValidityChanged.emit(combinedValidity);
    } else if (this.shouldShowCategoryList) {
      const isCategoryValid = !!this.selectedCategory;
      const combinedValidity = isFormValid && isCategoryValid;
      this.formValidityChanged.emit(combinedValidity);
    } else {
      // If shouldShowCollectionList is false, emit form validity directly
      this.formValidityChanged.emit(isFormValid);
    }
  }

  protected filterCollections() {
    if (!this.collections) {
      return;
    }
    // get the search keyword
    let search = this.collectionsSelectFilter.value;
    if (!search) {
      this.filteredCollections.next(this.collections.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the competitors
    this.filteredCollections.next(
      this.collections.filter(collections => collections.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private loadCollections(collections: ICollection[]) {
    this.collections = collections;
    // load the initial tenants list
    this.filteredCollections.next(this.collections?.slice());
  }

  get collectionsSelectFilter() {
    return this.syncFormGroup.get('collectionsSelectFilterCtrl');
  }

  onSelectedCollectionChange(event: MatSelectChange) {
    this.isChanged = true;
    this.selectedCollection = event.value as ICollection;
    this.checkAndEmitValidity();
  }

  protected filterCategories() {
    if (!this.categories) {
      return;
    }
    // get the search keyword
    let search = this.categoriesSelectFilter.value;
    if (!search) {
      this.filteredCategories.next(this.collections.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the competitors
    this.filteredCategories.next(
      this.categories.filter(categories => categories.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private loadCategories(categories: ICategory[]) {
    this.categories = categories;
    // load the initial tenants list
    this.filteredCategories.next(this.categories?.slice());
  }

  get categoriesSelectFilter() {
    return this.syncFormGroup.get('categoriesSelectFilterCtrl');
  }

  onSelectedCategoryChange(event: MatSelectChange) {
    this.isChanged = true;
    this.selectedCategory = event.value as ICategory;
    this.checkAndEmitValidity();
  }

  submit() {
    this.sendOnBoardingData();
  }

  private sendOnBoardingData() {
    this.isSubmitLoading = true;
    // this.sessionService.finishOnBoarding(onBoardingData).subscribe(async () => {
    //   this.sessionService.loadUserData();
    //   this.sessionService.isFinishedOnBoarding = true;
    //   this.changeDetectChanges.detectChanges();

    //   //load calendly after view change
    // }, complete => {
    //   this.isSubmitLoading = false;
    // });
  }

  // get selectedTargetSyncEntityName(): string {
  //   return this.syncFormGroup.controls?.targetSyncEntityName?.value ?? null;
  // }

  private initializeForms(): void {
    this.syncFormGroup = new FormGroup({
      syncEntitiesSelect: new FormControl(['', Validators.required]),
      targetSyncEntityName: new FormControl(),
      collectionsSelectFilterCtrl : new FormControl(),
      categoriesSelectFilterCtrl: new FormControl(),
      addToActiveProducts: new FormControl(false),
      merchantAccountSelect: new FormControl(),
    });

  }
      //targetSyncEntityName: new FormControl(),

  isCollectionEmpty(): boolean {
    return !this.selectedCollection;
  }

  isCategoryEmpty(): boolean {
    return !this.selectedCategory;
  }

  get isAdmin() : boolean {
    return this.sessionService.isAdmin;
  }
  get shouldShowEntityNameInput() {
    return this.selectedSyncEntity != null && this.selectedSyncEntity.value != SyncEntityType.First100 && this.selectedSyncEntity.value != SyncEntityType.BestSellers && this.selectedSyncEntity.value != SyncEntityType.Category && this.selectedSyncEntity.value != SyncEntityType.Collection;
  }

  get shouldShowCategoryList(): boolean {
    return !this.shouldShowMerchantList && this.selectedSyncEntity != null && this.selectedSyncEntity.value == SyncEntityType.Category;
  }

  get shouldShowCollectionList() : boolean {
    return !this.shouldShowMerchantList && this.selectedSyncEntity != null && this.selectedSyncEntity.value == SyncEntityType.Collection;
  }

  get shouldShowMerchantList() : boolean {
    return this.sessionService?.currentIntegration?.integrationType == IntegrationTypes.Google;
  }

  get currentIntegrationType() : IntegrationTypes {
    return this.sessionService?.currentIntegration?.integrationType;
  }  

  get selectedTargetSyncEntityName() : string {
    return this.syncFormGroup.controls.targetSyncEntityName?.value;
  }

  get syncEntityLabel() {
    if (!this.selectedSyncEntity)
      return 'Select Entity Type';

    return this.selectedSyncEntity?.title + ' name';
  }

  private initializeFormsData() {
    this.syncEntities = [...syncEntitiesArray];
    if(this.currentIntegrationType == IntegrationTypes.Google || this.currentIntegrationType == IntegrationTypes.Woo){
      this.syncEntities = [...googleAndWooSyncEntitiesArray];
      this.selectedSyncEntity = this.syncEntities.find(x => x.value == SyncEntityType.First100);
    }    
    else{
      this.selectedSyncEntity = this.syncEntities.find(x => x.value == SyncEntityType.BestSellers);
    }
  }

  get syncTargetInfo() : any {
    return {
      syncEntityType : this.selectedSyncEntity?.value,
      targetSyncEntityName: this.selectedCollection?.name ?? (this.selectedCategory?.name ?? this.selectedTargetSyncEntityName),
      merchantId : this.selectedMerchantAccount?.merchantId
    };
  }

  syncCollectionSubmit(){
    const addToActive = this.syncFormGroup.value.addToActiveProducts ?? false;

    this.syncCollectionEmit.emit({
      selectedSyncEntity : this.selectedSyncEntity,
      selectedTargetSyncEntityName: this.selectedCollection?.name,
      addToActiveProducts : addToActive
    });
  }

  syncCategorySubmit(){
    const addToActive = this.syncFormGroup.value.addToActiveProducts ?? false;

    this.syncCategoryEmit.emit({
      selectedSyncEntity : this.selectedSyncEntity,
      selectedTargetSyncEntityName: this.selectedCategory?.name,
      addToActiveProducts : addToActive
    });
  }

  clearSearch() {
    $("#entity-search-input").children(".mat-select-search-inner").children(".mat-select-search-input").val("");
    $("#entity-search-input").children(".mat-select-search-inner").children(".mat-select-search-clear").hide();
  }

  get isWooIntegrationType(): boolean {
    return this.sessionService?.currentIntegration?.integrationType == IntegrationTypes.Woo;
  }
}

